.Accept-terms-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  width: 520px;
}

.Accept-terms-modal-title {
  color: var(--charcoal-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  letter-spacing: -0.12px;
  line-height: 28px;
}

.Accept-terms-modal-content {
  color: var(--pc-color-black);
  font-family: "Gotham-Regular";
  font-size: 16px;
  line-height: normal;
  margin: 42px auto 16px;
  text-align: center;
  width: 336px;
}

.Accept-terms-modal-content-green {
  color: var(--pc-color-blue-400);
  font-family: "Gotham-Medium";
  text-decoration: none;
}

.Accept-terms-modal-close-button {
  margin: 24px auto 0;
}
