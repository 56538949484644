.Upload-text-button,
.Upload-icon-button {
  border: none;
  bottom: 16px;
  max-height: 41px;
  position: absolute;
  right: 16px;
  transition: all 0.2s ease;
}

.Upload-button-profile-picture {
  bottom: 0;
  right: 0;
}

.Upload-button-family-picture-placeholder {
  bottom: 495px;
  right: 16px;
}

.Upload-button-family-picture {
  bottom: 16px;
  right: 16px;
}

.Upload-text-button {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  height: 33px;
  justify-content: flex-start;
  padding: 0 8px;
  width: 223px;
}

.Upload-icon-button {
  background-color: transparent;
  background-image: url('../../assets/myPets/camera.svg');
  height: 41px;
  width: 41px;
}

.Upload-text-button:hover,
.Upload-icon-button:hover {
  opacity: 0.7;
}

.Upload-text-button span {
  color: #3d484d;
  font-size: 18px;
  font-family: 'Inter-Semibold';
  margin-left: 8px;
}

.Upload-text-button img {
  display: block;
  height: 21px;
  width: 20px;
}
