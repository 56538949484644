
.Download-modal-main-container {
  max-width: 520px;
  padding: 24px;
}

.Download-modal-title {
  color: var(--charcoal-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 6px;
  width: 100%;
}

.Download-modal-description {
  color: var(--charcoal-300);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.Download-modal-qr-container {
  background-color: var(--pc-color-blue-300);
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 7px;
}

.Download-modal-qr-container > img {
  height: 106px;
  width: 106px;
}

.Download-modal-qr-container > span {
  color: var(--base-pc-color-white);
  font-family: 'Gotham-Regular';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0 4px 0;
  text-align: center;
}

.Download-modal-or {
  color: var(--charcoal-500);
  flex: 1;
  font-family: 'Gotham-Rounded';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 32px;
  text-align: center;
}

.Download-modal-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
}

.Download-modal-col {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  justify-content: space-between;
}
