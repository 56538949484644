.Stepper-multibrand-progress-bar {
  background-color: var(--pc-color-cotton-300);
  height: 4px;
  margin-top: 24px;
  overflow: hidden;
  width: 100%;
}

.Stepper-multibrand-progress-bar-completed {
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -webkit-transition: 0.5s all;
  background-color: var(--pc-color-blue-300);
  height: 100%;
  transition: 0.5s all;
}

/* Tablet */
@media (max-width: 1200px) {
}
