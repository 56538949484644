.Action-modal-container {
  border-radius: 8px;
  justify-content: space-between;
  min-height: 290px;
  padding: 32px;
  position: relative;
  width: 381px;
}

.Action-modal-close-modal {
  background: none;
  border-radius: 50%;
  border: none;
  height: 24px;
  padding: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
}

.Action-modal-close-modal img {
  height: 100%;
  width: 100%;
}

.Action-modal-title {
  text-align: center;
  font-size: 20px;
  font-family: "Inter-SemiBold";
  color: #22435e;
}

.Action-modal-buttons {
  justify-content: space-between;
}

.Action-modal-buttons-one {
  justify-content: center;
}

.Action-modal-confirm-button {
  background-color: #2fb3b4;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-family: 'Inter-Semibold';
  line-height: 1.25;
  padding: 10px 30px;
}

.Action-modal-secondary-button {
  background-color: #eceef0;
  border-radius: 5px;
  border: none;
  color: #3d484d;
  font-family: 'Inter-Semibold';
  line-height: 1.25;
  margin-right: 15px;
  padding: 10px 30px;
}

.Action-modal-button {
  height: 60px;
  padding: 0;
  width: 151px;
}

.Action-modal-button-disabled {
  pointer-events: none;
}

.Action-modal-button-blue, 
.Action-modal-button-blue:disabled {
  background-color: var(--pc-color-blue-300);
}

.Action-modal-button-blue:disabled{
  opacity: 1;
} 
