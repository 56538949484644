.Figo-common-header-container {
  align-items: flex-start;
  background-color: #fcf2e8;
  display: flex;
  height: 113px;
  justify-content: space-between;
  padding: 32px;
  width: 100%;
}
.Figo-common-header-logo {
  height: 58px;
  width: 171.58px;
}

.Figo-common-header-logo-mobile {
  display: none;
}

.Figo-common-header-title {
  color: #000;
  font-family: "Inter-Regular";
  font-size: 16px;
}

.Figo-common-header-sign-in {
  color: #000;
  font-family: "Inter-Medium";
  margin-left: 5px;
  text-decoration: underline;
  transition: all 0.3s ease;
}

.Figo-common-header-sign-in:hover {
  color: #000;
  opacity: 0.5;
}

.Figo-common-header-text-container {
  align-self: center;
}

/* Mobile */
@media (orientation: portrait) and (max-width: 568px) {
  .Figo-common-header-logo {
    height: 34.81px;
    width: 91px;
  }

  .Main-header-mobile {
    align-self: flex-start;
    background-color: var(--figo-primary-deep-blue-100);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 88px;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 3;
  }

  .Figo-common-header-logo-mobile {
    display: flex;
    height: 51px;
    margin: 20px 0 17px 24px;
    width: 128px;
  }

  .Figo-common-header-logo-mobile-cc {
    height: 35px;
    width: 90px;
  }

  .Figo-common-header-container {
    align-items: center;
    padding: 24px 24px 12px 24px;
    height: auto;
  }

  .Figo-common-header-title {
    font-size: 14px;
    line-height: 19.94px;
  }
  
  .Figo-common-header-sign-in {
    font-size: 14px;
  }
  .Figo-common-header-text-container {
    display: flex;    
  }
}
