.Modifier-modal-wellness-button-container {
  align-items: flex-start;
  background-color: var(--pc-color-blue-300);
  border-radius: 100px;
  border: 1px solid var(--pc-color-blue-300);
  display: flex;
  gap: 1px;
  height: 40px;
  margin: 24px 0;
  width: 472px;
}

.Modifier-modal-wellness-basic-button,
.Modifier-modal-wellness-plus-button,
.Modifier-modal-wellness-basic-button-not-selected,
.Modifier-modal-wellness-plus-button-not-selected {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  gap: 8px;
  height: 100%;
  justify-content: center;
  padding: 12px 16px;
  width: 50%;
}

.Modifier-modal-wellness-basic-button,
.Modifier-modal-wellness-basic-button-not-selected {
  border-radius: 100px 0 0 100px;
}

.Modifier-modal-wellness-plus-button,
.Modifier-modal-wellness-plus-button-not-selected {
  border-radius: 0 100px 100px 0;
}

.Modifier-modal-wellness-text,
.Modifier-modal-wellness-text-not-selected {
  color: var(--base-pc-color-white);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}

.Modifier-modal-wellness-text-not-selected {
  color: var(--pc-color-onyx-500);
}

.Modifier-modal-wellness-basic-button-not-selected,
.Modifier-modal-wellness-plus-button-not-selected {
  background-color: var(--base-pc-color-white);
}

.Modifier-modal-wellness-img {
  height: 16px;
  width: 16px;
}

/* Mobile */
@media (max-width: 568px) {
  .Modifier-modal-wellness-button-container {
    max-width: 100%;
  }
}
