.FigoInput-input::placeholder {
  color: #b0bbbf;
  font-size: 14px;
}

.Input-disabled {
  background-color: #f8f8f8 !important;
}

.FigoInput-counter {
  color: #3d484d;
  float: right;
  font-size: 10px;
  letter-spacing: -0.19px;
  margin-top: 4px;
}

.FigoInput-description {
  color: #7e888c;
  font-family: 'Inter-Regular';
  font-size: 13px;
}

.FigoInput-hit {
  color: #7e888c;
  font-family: "Inter-Regular";
  line-height: -0.25;
  size: 13px;
}
