.Figo-lost-pet-found-pet-picture {
  border-radius: 100%;
  height: 96px;
  margin-top: 32px;
  min-height: 96px;
  min-width: 96px;
  width: 96px;
}

.Figo-lost-pet-found-pet-name {
  color: #000;
  font-family: "Inter-SemiBold";
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-top: 8px;
}

.Figo-lost-pet-found-pet-age,
.Figo-lost-pet-found-pet-address {
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

.Figo-lost-pet-found-pet-message-information {
  color: #000;
  font-size: 14px;
  line-height: 19.6px;
  margin-top: 16px;
  max-width: 468px;
  text-align: center;
}

.Figo-lost-pet-found-pet-no-margin {
  margin: 0;
}

.Figo-lost-pet-found-pet-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 29px;
}

.Figo-lost-pet-found-pet-col {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}


/* Mobile */
@media (max-width: 568px) {
  .Figo-lost-pet-found-pet-picture {
    margin-top: 16px;
  }

  .Figo-lost-pet-found-pet-message-information {
    margin: 0 32px;
  }
}
