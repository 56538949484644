.Breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.Breadcrumbs img {
  margin: 0 12px;
}
