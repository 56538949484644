.Review-changes-modal-main-container {
  display: flex;
  flex-direction: column;
  padding: 40px 31px 35px 34px;
  width: 480px;
}

.Review-changes-modal-main-top {
  display: flex;
}

.Review-changes-modal-main-money {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.Review-changes-modal-main-payment-summary {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Review-changes-modal-main-payment-text-top {
  color: #2d4c66;
  font-family: 'Inter-SemiBold';
  font-size: 14px;
}

.Review-changes-modal-main-payment-text-bottom {
  color: #2d4c66;
  font-family: 'Inter-Regular';
  font-size: 14px;
}

.Review-changes-modal-main-pet-icon {
  height: 23px;
  width: 24px;
}

.Review-changes-modal-main-pet-name {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 18px;
  margin-left: 8px;
}

.Review-changes-modal-main-line-divisor {
  background-color: #2fb3b4;
  height: 1px;
  margin-top: 18px;
}

.Review-changes-modal-main-powerups-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.Review-changes-modal-main-powerups-section {
  width: 264px;
}

.Review-changes-modal-main-powerup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.Review-changes-modal-main-powerup-total {
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
}

.Review-changes-modal-main-payment {
  margin-top: 6px;
}

.Review-changes-modal-main-powerup-title {
  color: #3d484d;
  font-family: 'Inter-Regular';
  font-size: 14px;
}

.Review-changes-modal-main-powerup-choice {
  color: #3d484d;
  font-family: 'Inter-Medium';
  font-size: 14px;
}

.Review-changes-modal-main-total-pet {
  color: #3d484d;
  font-family: 'Inter-SemiBold';
  font-size: 18px;
  margin-top: 19px;
}

.Review-changes-modal-main-total-gap {
  margin-right: 36px;
}

.Review-changes-modal-main-info-section {
  background-color: #b8ede826;
  border-radius: 5px;
  border: 1px solid #2fb3b4;
  display: flex;
  margin-top: 30px;
  padding: 18px 10px 18px 17px;
}

.Review-changes-modal-main-info-icon {
  display: block;
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.Review-changes-modal-main-disclaimer {
  color: #3d484d;
  font-family: 'Inter-Regular';
  font-size: 16px;
}

.Review-changes-modal-main-buttons {
  display: flex;
  justify-content: center;
  margin-top: 39px;
}

.Review-changes-modal-main-cancel-button {
  background-color: #eceef0;
  border-radius: 8px;
  cursor: pointer;
  height: 60px;
  margin-right: 16px;
  padding: 20px 48px;
  width: 150px;
}

.Review-changes-modal-main-cancel-button-text {
  color: #7e888c;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  pointer-events: none;
}

.Review-changes-modal-main-submit-button {
  align-items: center;
  background-color: #2fb3b4;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 20px 48px;
  width: 150px;
}

.Review-changes-modal-main-submit-button-text {
  color:#fff;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
}

/* Mobile */
@media (max-width: 568px) {
  .Review-changes-modal-main-container {
    padding: 25px 19px 33px 20px;
    width: 282px;
  }

  .Review-changes-modal-main-pet-icon {
    height: 21px;
    width: 21px;
  }

  .Review-changes-modal-main-money {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -8px;
    padding: 0;
  }

  .Review-changes-modal-main-payment-summary {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .Review-changes-modal-main-payment-text-top {
    margin-right: 4px;
  }

  .Review-changes-modal-main-line-divisor {
    margin-top: 8px;
  }

  .Review-changes-modal-main-info-section {
    padding: 19px 15px 16px 16px;
  }

  .Review-changes-modal-main-disclaimer {
    font-size: 13px;
    text-align: start;
  }

  .Review-changes-modal-main-buttons {
    margin-top: 34px;
  }

  .Review-changes-modal-main-cancel-button {
    align-items: center;
    display: flex;
    height: 44px;
    justify-content: center;
    padding: 14px 25px;
    width: 112px;
  }
  
  .Review-changes-modal-main-cancel-button-text {
    font-size: 13px;
  }
  
  .Review-changes-modal-main-submit-button {
    height: 44px;
    padding: 14px 33px;
    width: 112px;
  }
  
  .Review-changes-modal-main-submit-button-text {
    font-size: 13px;
  }
}
