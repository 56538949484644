.Contentful-page,
.Contentful-page-shimmer {
  margin: auto;
  max-width: 876px;
  padding: 24px;
  width: 100%;
}

.Contentful-page-shimmer {
  height: 250px;
  margin-top: 24px;
}

.Contentful-page-title {
  font-family: "Gotham-Rounded";
  font-size: 36px;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin-bottom: 24px;
}

.Contentful-page-content {
  background-color: var(--base-pc-color-white);
  padding: 24px;
  font-family: "Gotham-Regular";
  font-size: 16px;
}

.Contentful-page-content h3,
.Contentful-page-content h4, 
.Contentful-page-content h5, 
.Contentful-page-content h6,
.Contentful-page-content strong {
  font-family: "Gotham-Rounded";
  font-weight: normal;
}

.Contentful-page-content h2 {
  font-family: "Gotham-Rounded";
  font-size: 20px;
  font-weight: normal;
}

.Contentful-page-content ul > li > p {
  margin: 0;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Contentful-page-shimmer {
    width: 95%;
  }

  .Contentful-page {
    padding: 24px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Contentful-page-shimmer {
    width: 95%;
  }

  .Contentful-page {
    padding: 16px;
  }
}