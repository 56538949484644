.Change-coverage-insurance-screen-container {
  margin: 43.5px 48px 69px 48px;
}

.Change-coverage-insurance-screen-container > h1 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.54px;
}

.Change-coverage-insurance-header-container {
  background-color: var(--deep-blue-main);
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 88px;
  max-width: 1084px;
  width: 100%;
}

.Change-coverage-insurance-header-logo {
  height: 51px;
  margin: 20px 0 17px 24px;
  width: 128px;
}

.Change-coverage-insurance-card-container {
  background-color: var(--base-pc-color-white);
  max-width: 1084px;
  width: 100%;
}

.Change-coverage-insurance-card-container-shimmer {
  background-color: var(--base-pc-color-white);
  max-width: 1084px;
  padding: 32px 48px 48px 48px;
  width: 100%;
}

/* Desktop */
@media (min-width: 1200px) {
  .Change-coverage-insurance-container {
    background-color: var(--base-pc-color-white);
    border-radius: 5px;
    border: 1px solid #eceef0;
  }
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Change-coverage-insurance-screen-container {
    margin: 43.5px 24px 39px 24px;
  }

  .Change-coverage-insurance-header-container {
    width: 100%;
  }
}
