.Claimbot-stepper {
  background-color: var(--base-pc-color-white);
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Claimbot-stepper-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 32px;
}

.Claimbot-stepper-row > h2 {
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
}

.Claimbot-stepper-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.Claimbot-stepper-back-button,
.Claimbot-stepper-back-button-disabled {
  background-color: var(--base-pc-color-white);
  border-radius: 20px;
  border: 1px solid var(--pc-border-radius-xl);
  color: var(--charcoal-500);
  cursor: pointer;
  font-family: 'Gotham-medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 12px 16px;
  transition: all ease 0.5s;
  width: 180px;
}

.Claimbot-stepper-back-button-disabled:disabled {
  opacity: 0.5;
}

.Claimbot-stepper-back-button:hover {
  background-color: var(--pc-color-blue-100);
}

.Claimbot-stepper-next-button-container,
.Claimbot-stepper-next-button-container-disabled {
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 1px solid var(--pc-color-blue-300);
  transition: all ease 0.5s;
  max-height: 42px;
  width: 180px;
}

.Claimbot-stepper-next-button-container:hover,
.Claimbot-stepper-next-button-container:active,
.Claimbot-stepper-next-button-container:focus {
  background-color: var(--pc-color-blue-300) !important;
  border: 1px solid var(--pc-color-blue-300) !important;
  opacity: 0.8;
}

.Claimbot-stepper-back-button-disabled,
.Claimbot-stepper-next-button-container-disabled,
.Claimbot-stepper-next-button-container-disabled:disabled {
  background-color: var(--pc-color-cotton-200);
  border-color: var(--pc-color-cotton-200);
}

.Claimbot-stepper-next-button-loading,
.Claimbot-stepper-next-button-loading:active,
.Claimbot-stepper-next-button-loading:hover,
.Claimbot-stepper-next-button-loading:focus {
  background-color: var(--pc-border-radius-xl) !important;
  border-color: var(--pc-color-blue-300) !important;
}

.Claimbot-stepper-back-button-disabled > span,
.Claimbot-stepper-next-button-container-disabled > span{
  color: var(--pc-color-onyx-200);
}

.Claimbot-stepper-next-button-container:focus {
  opacity: 1;
}

.Claimbot-stepper-next-button {
  color: var(--base-pc-color-white);
  font-family: 'Gotham-medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
}

.Claimbot-stepper-next-button:hover, .Claimbot-stepper-next-button:active {
  opacity: 0.8;
}

.Claimbot-stepper-next-button[aria-disabled=true] {
  background-color: #b0bbbf !important;
}
