/* FIGO */
.Policy-header-logo-1 {
  height: 51px;
  margin: 20px 0 17px 24px;
  width: 128px;
}

/* PPI */
.Policy-header-logo-2 {
  height: 40px;
  margin: 24px;
  width: 191px;
}

/* AKC */
.Policy-header-logo-3 {
  height: 58px;
  margin: 24px;
  width: 121.333px;
}

/* PPI OnePack */
.Policy-header-logo-4 {
  height: 40;
  margin: 0 24px;
}

.Policy-header-container {
  background-color: var(--primary-background);
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  min-height: 88px;
  width: 100%;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Policy-header-container {
    width: 100%;
  }
}
