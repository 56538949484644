.Loading-overlay-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 164px;
  overflow: auto;
  padding: 41px;
  width: 164px;
}

.Loading-overlay-message {
  text-align: center;
  word-break: keep-all;
}

/* Mobile */
@media (max-width: 568px) {
  .Loading-overlay-container {
    padding: 20px;
  }

  .Loading-overlay-main-container {
    width: auto;
  }
}
