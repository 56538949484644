.My-pets-input-image-container {
  align-items: center;
  background-color: #fff;
  display: flex;
  min-height: 300px;
  justify-content: center;
  margin: 0 18px;
  border-radius: 2%;
  overflow: hidden;
  position: relative;
  min-width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.My-pets-input-image-container-hover {
  background-color: #b8ede8;
  border-color: #2c6085;
}

.My-pets-input-image-round {
  border-radius: 50%;
}

.My-pets-input-image-container input {
  height: 100% !important;
  left: 0;
  opacity: 0;
  padding: 0 !important;
  position: absolute;
  width: 100%;
}

.My-pets-input-image-round input {
  border-radius: 50%;
}

.My-pets-input-image-success {
  background-color: #fff;
}

.My-pets-input-image-error {
  background-color: rgba(248, 95, 37, 0.15);
}

.My-pets-input-image-content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.My-pets-input-image-icon {
  margin-bottom: 8px;
  position: relative;
  text-align: center;
}

.My-pets-input-image-instruction-1,
.My-pets-input-image-instruction-2 {
  line-height: 32px;
}

.My-pets-input-image-instruction-1 {
  color: #000;
  font-family: "Gotham-Regular";
  font-size: 14px;
  font-weight: 350;
}

.My-pets-input-image-instruction-highlight {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-Regular";
  font-weight: 400;
  text-decoration: underline;
}

.My-pets-input-image-instruction-2 {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 12px;
  font-weight: 400;
}

.My-pets-input-image-message {
  color: #182f43;
  font-size: 18px;
  line-height: 24px;
  margin: 16px auto 24px;
  max-width: 204px;
  text-align: center;
}

.My-pets-input-image-message-error {
  margin: 24px auto 8.9px;
}

.My-pets-input-image-file-name {
  color: #182f43;
  font-size: 16px;
  max-width: 204px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.My-pets-input-image-delete {
  background: none;
  border: none;
  color: #2fb3b4;
  cursor: pointer;
  font-size: 16px;
  margin-top: 6px;
}

.My-pets-input-image-extensions {
  font-size: 18px;
}
