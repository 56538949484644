.File-claimbot-row-name-container {
  display: flex;
  max-width: 60%;
}

.File-claimbot-row-name {
  color: var(--base-pc-color-black);
  display: inline-block;
  font-family: "Gotham-Medium";
  font-size: 12px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.File-claimbot-row-extension {
  margin-right: 8px;
  width: fit-content;
}

.File-claimbot-row {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 8px 0px;
  width: 100%;
}

.File-claimbot-row-data {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.File-claimbot-row-progress-bar {
  border-radius: 80px;
  border: 0.5px solid var(--pc-color-cotton-500);
}

.File-claimbot-row-status {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 12px;
}

.File-claimbot-row-left {
  display: flex;
  width: 100%;
}

.File-claimbot-row-right {
  display: flex;
  align-items: center;
}

.File-claimbot-row-size {
  color: var(--pc-color-onyx-200);
  display: inline-block;
  font-family: "Gotham-Regular";
  font-size: 12px;
  min-width: 60px;
  white-space: nowrap;
}

.File-claimbot-row-delete {
  display: flex;
  margin: 0;
}

.File-claimbot-row-delete button {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
}

.File-claimbot-row-error {
  color: var(--pc-color-hydrant-400);
  font-family: 'Gotham-Regular';
  font-size: 10px;
}

.Margin-top-8 {
  margin-top: 8px;
}

.File-claimbot-file-progress {
  background-color: #1ccfca;
  border-radius: 2px;
  height: 4px;
  transition: all 0.3s;
  width: 100%;
}
