.Update-payment-method-no-policies-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.Update-payment-method-no-policies-container img {
  height: 150px;
  width: 150px;
}

.Update-payment-method-no-policies-container span {
  color: #aab5bc;
  font-family: "Inter-SemiBold";
  font-size: 22px;
  margin-top: 16px;
}
