.Powerup-card-container, .Powerup-card-container-selected {
  align-items: center;
  background-color: #ffffffbf;
  border-radius: 16px;
  border: 2px solid var(--deep-blue-shade-3);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 22px;
  width: 482px;
}

.Powerup-card-container-selected {
  background-color: var(--blue-shade-3);
  border-color: var(--deep-blue-main);
  border-style: solid;
  border-width: 2px;
}

.Powerup-card-body-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Powerup-card-left-content {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 478px;
}

.Powerup-card-title {
  align-items: center;
  color: #22435e;
  display: flex;
  flex: 1;
  font-family: 'Inter-SemiBold';
  font-size: 18px;
  height: 100%;
  justify-content: flex-start;
  line-height: 1.33;
  width: 100%;
}

.Powerup-card-title > h3 {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 18px;
  line-height: 1.33;
  margin: 0;
}

.Powerup-card-plan-info-image {
  cursor: pointer;
  height: 16px;
  margin: 0 8px 0 0;
  width: 16px;
}

.Powerup-card-content {
  align-items: flex-start;
  color: var(--deep-blue-shade-2);
  display: flex;
  flex-direction: column;
  font-family: 'IvyEpic';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 100%;
  justify-content: space-between;
  line-height: 18px;
}

.Powerup-card-information {
  font-size: 14px;
  line-height: 21px;
}

.Powerup-card-content > p {
  margin: 0;
  text-align: left;
  width: 80%;
}

.Powerup-whats-covered {
  align-items: center;
  align-self: flex-start;
  display: flex;
}

.Powerup-card-actions-radios {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Powerup-card-actions-end {
  justify-content: flex-end;
}

.Powerup-wellness-title {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Bold';
  font-size: 12px;
}

.Powerup-wellness-checkbox-container > p,
.Powerup-wellness-checkbox-container-first > p {
  margin: 0;
}

.Powerup-wellness-checkbox-container-first {
  margin-top: 18.98px;
}

.Powerup-wellness-checkbox-row-container {
  height: 20px;
}

.Powerup-wellness-checkbox-row-container:first-of-type {
  margin-bottom: 15px;
}

.Powerup-wellness-link-content {
  display: none;
}

.Powerup-card-actions-radios {
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
}

.Powerup-card-option-wrapper,
.Powerup-card-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Powerup-card-option-wrapper {
  flex-direction: column;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Powerup-card-container, .Powerup-card-container-selected {
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Powerup-card-content {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }

  .Powerup-card-content > p {
    width: 100%;
  }

  .Powerup-card-container, .Powerup-card-container-selected {
    padding: 24px;
    width: 100%;
  }

  .Powerup-card-body-content {
    padding: 0;
  }
}
