.Docs-table-header-row {
  cursor: pointer;
  width: 100%;
}

.Docs-select-icon-header > img {
  pointer-events: none;
}

.Docs-dots-menu-header {
  width: 51px;
}

@media (max-width: 1140px) {
  .Docs-file-size-header,
  .Docs-file-size,
  .Docs-file-size-trash {
    display: none;
  }

  .Docs-file-size-header-trash {
    min-width: 150px;
    width: 150px;
  }

  .Docs-assigned-header,
  .Docs-assigned {
    max-width: 160px;
    width: 160px;
  }

  .Docs-table-header-row {
    height: 44px;
  }
}

.Docs-table-container {
  background-color: var(--base-pc-color-white);
  border-color: var(--base-pc-color-white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
}

.Docs-table-container-trash tbody {
  display: flex;
  flex-direction: column;
}

.Docs-table-main-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 1025px;
}

.Docs-select-icon-header-container,
.Docs-select-icon {
  min-width: 73px;
  padding-left: 32px;
  width: 73px;
}

.Docs-name-header {
  min-width: 311px;
  overflow: hidden;
  padding-left: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 311px;
}

.Docs-date-added-header {
  min-width: 193px;
  width: 193px;
}

.Docs-assigned-header {
  min-width: 164px;
  width: 164px;
}

.Docs-file-size-trash {
  min-width: 150px;
  width: 150px;
}

.Docs-file-size-header {
  width: 170px;
}

.Docs-table-container-trash {
  background-color: var(--base-pc-color-white);
}

/* Tablet - Big */
@media (max-width: 1140px) {
  .Docs-table-container-trash {
    width: 704px;
  }

  .Docs-table-main-container {
    margin-top: 22px;
    max-width: 1025px;
    width: 100%;
  }

  .Docs-select-icon-header-container,
  .Docs-select-icon {
    padding-left: 24px;
  }

  .Docs-select-icon-header-container img,
    .Docs-select-icon img {
    height: 16px;
    width: 16px;
  }

  .Docs-name-header {
    min-width: 240px;
    padding-left: 0;
    width: 240px;
  }
}
