.Modal-overlay {
  align-items: center;
  background-color: rgba(30, 34, 38, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 6;

  .Modal-container {
    border-radius: 12px !important;
    flex-direction: row !important;
    padding: 32px;
    width: 520px;
  }

  .Modal-image-container {
    margin-right: 32px;
  }

  .Modal-image {
    width: 64px;
  }

  .Modal-title {
    color: rgb(34, 67, 94);
    font-family: "Inter-SemiBold";
    font-size: 22px;
    line-height: 30px;
  }

  .Modal-body-container {
    margin: 16px 0 32px;
  }

  .Modal-body {
    color: rgb(126, 136, 140);
    font-size: 16px;
    line-height: 24px;
  }

  .Modal-buttons-container {
    display: flex;
    justify-content: flex-end;
  }

  .Modal-button {
    border-radius: 8px !important;
    border-width: 0 !important;
    display: flex !important;
    flex-direction: column;
    font-family: "Inter-SemiBold";
    font-size: 16px;
    height: 60px;
    justify-content: center;
    padding: 20px 32px !important;
  }

  .Modal-button-leave {
    background-color: rgb(236, 238, 240) !important;
    color: rgb(126, 136, 140) !important;
    margin-right: 16px;
  }

  .Modal-button-stay {
    background-color: rgb(47, 179, 180) !important;
  }
}

.Modal-overlay-backdrop {
  align-items: center;
  background-color: rgba(30, 34, 38, 0.6);
  backdrop-filter: blur(3px);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;

  .Modal-container {
    border-radius: 8px;
    flex-direction: row;
    padding: 2rem;

    .Modal-image-container {
      margin-right: 32px;
    }

    .Modal-image {
      width: 64px;
    }

    .Modal-title {
      color: rgb(34, 67, 94);
      font-family: "Gotham-Rounded";
      font-size: 20px;
      line-height: 24px;
      margin: 0;
    }

    .Modal-body {
      font-size: 16px;
      line-height: 24px;
    }

    .Modal-buttons-container {
      display: flex;
      gap: 1rem;
      width: 100%;

      .Modal-button {
        border-radius: 20px;
        font-family: "Gotham-Medium";
        font-size: 16px;
        height: 40px;
        justify-content: center;
        width: 100%;
      }

      .Modal-button-leave {
        background: var(--base-pc-color-white);
        color: var(--base-pc-color-black);
        border: 1px solid var(--pc-border-radius-xl);
      }

      .Modal-button-stay {
        background: var(--pc-color-hydrant-400);
        border: 1px solid var(--pc-color-hydrant-400);
      }
    }
  }
}
