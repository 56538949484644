.Pet-cloud-account-row-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.Pet-cloud-account-top-row-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.Pet-cloud-account-top-row-container > h4 {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
}

.Pet-cloud-account-picture-button {
  align-items: center;
  background-color: var(--pc-color-onyx-100);
  border-radius: 100px;
  border: none;
  display: flex;
  flex-shrink: 0;
  height: 80px;
  justify-content: center;
  padding: 26px 25px 26px 24px;
  width: 80px;
}

.Pet-cloud-account-picture {
  border-radius: 100px;
  border: none;
  display: flex;
  flex-shrink: 0;
  height: 80px;
  justify-content: center;
  width: 80px;
}

.Pet-cloud-edit,
.Pet-cloud-edit-gray {
  background-color: transparent;
  border-color: var(--pc-color-blue-300);
  border-style: none none solid none;
  border-width: 1px;
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 4px 0;
  text-align: center;
}

.Pet-cloud-edit-gray {
  border: none;
  color: var(--pc-color-onyx-200);
}

.Pet-cloud-account-value {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Pet-cloud-account-initials {
  color: var(--base-pc-color-white);
  font-family: 'Gotham-Medium';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -1px;
  line-height: 28px;
  text-align: center;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Pet-cloud-account-row-container {
    width: 79%;
  }
}
