.Generic-error-container {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 92px;
  width: 100%;
  z-index: 10;
}

.Generic-error-inner-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 92px);
  justify-content: center;
  max-width: 718px;
}

.Generic-error-image {
  height: 195.52px;
  margin-bottom: 40px;
  width: 289.18px;
}

.Generic-error-title {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.015em;
  line-height: 32px;
  margin-bottom: 24px;
  text-align: center;
}

.Generic-error-message {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-weight: 325;
  line-height: 19.2px;
  margin-bottom: 24px;
  text-align: center;
}

.Generic-error-button {
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border-width: 0;
  color: var(--base-pc-color-white);
  cursor: pointer;
  font-family: "Gotham-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 40px;
  letter-spacing: 0.32px;
  line-height: 16px;
  margin-bottom: 12px;
  width: 124px;
}

.Generic-error-back {
  background: transparent;
  border: none;
  color: var(--base-pc-color-black);
  cursor: pointer;
  font-family: "Gotham-regular";
  font-size: 14px;
  font-weight: 350;
  height: 32px;
  line-height: 14px;
  text-decoration: underline;
}

/* Tablet */
@media (max-width: 1025px) {
  .Generic-error-inner-container {
    max-width: 516px;
  }
}

