.File-claimbot-two-options-selector-container {
  display: flex;
  margin-left: 75px;
  max-width: 454px;
  width: 100%;
}

.File-claimbot-two-options-selector-container > button {
  background-color: var(--base-pc-color-white);
  border: 1px solid var(--pc-color-blue-500);
  color: var(--pc-color-onyx-500);
  display: flex;
  justify-content: center;
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 12px;
  text-align: center;
  width: 100%;
}

.File-claimbot-two-options-selector-container > button > img {
  height: 16px;
  width: 16px;
}

.File-claimbot-two-options-left {
  border-radius: 100px 0 0 100px;
}

.File-claimbot-two-options-right {
  border-radius: 0 100px 100px 0;
}

.File-claimbot-two-options-option-selected {
  background-color: var(--pc-color-blue-300) !important;
  color: var(--base-pc-color-white) !important;
}
