.Reset-password-pet-cloud-logo-div {
  align-items: center;
  background-color: var(--yankees-blue);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Reset-password-pet-cloud-logo-div > button {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  font-family: "Inter-regular";
  font-size: 18px;
  line-height: 21.78px;
  margin-right: 55px;
}

.Reset-password-pet-cloud-logo {
  height: 36px;
  margin: 40px 0 48px 40px;
  width: 244px;
}

/* Mobile */
@media (max-width: 568px) {
  .Reset-password-pet-cloud-logo-div {
    flex-wrap: wrap;
  }

  .Reset-password-pet-cloud-logo {
    height: 36px;
    margin: 16px 0 48px 16px;
  }

  .Reset-password-pet-cloud-logo {
    width: 186px;
  }
}
