.Reimbursement-edit-modal-container {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 16px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 520px;
}

.Reimbursement-edit-modal-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
  width: 100%;
}

.Reimbursement-edit-modal-title-container > h3 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin: 0;
}

.Reimbursement-edit-modal-close-icon-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  height: 24px;
  justify-content: center;
  padding: 8px;
  width: 24px;
}

.Reimbursement-edit-modal-close-icon-container > img {
  height: 16px;
  width: 16px;
}

.Reimbursement-edit-modal-description {
  color: var(--charcoal-300, var(--onyx-black-pc-color-onyx-300, #43474B));
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 32px;
}

.Reimbursement-edit-modal-method-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  width: 100%;
}

.Reimbursement-edit-modal-method-left,
.Reimbursement-edit-modal-method-right,
.Reimbursement-edit-modal-method-left-selected,
.Reimbursement-edit-modal-method-right-selected {
  align-items: center;
  align-self: stretch;
  background-color: var(--base-pc-color-white);
  border: 1px solid var(--pc-color-blue-300);
  display: flex;
  flex: 1 0 0;
  gap: 8px;
  height: 40px;
  justify-content: center;
  padding: 12px 16px;
}

.Reimbursement-edit-modal-method-left > span,
.Reimbursement-edit-modal-method-right > span,
.Reimbursement-edit-modal-method-left-selected > span,
.Reimbursement-edit-modal-method-right-selected > span {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}

.Reimbursement-edit-modal-method-left,
.Reimbursement-edit-modal-method-left-selected {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.Reimbursement-edit-modal-method-right,
.Reimbursement-edit-modal-method-right-selected {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.Reimbursement-edit-modal-method-left-selected,
.Reimbursement-edit-modal-method-right-selected {
  background-color: var(--pc-color-blue-300);
}

.Reimbursement-edit-modal-method-left-selected > span,
.Reimbursement-edit-modal-method-right-selected > span {
  color: var(--base-pc-color-white);
}

.Reimbursement-edit-modal-footer-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
