.Folders-section-container {
  margin-top: 40px;
  max-width: 816px;
}

.Folders-section-deleted-files-redesign {
  align-self: center;
  border-bottom: 1px solid var(--pc-color-blue-300);
  cursor: pointer;
  margin: 40px 0;
}

.Folders-section-deleted-files-redesign span {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Rounded';
  font-size: 16px;
  margin-left: 12px;
}

.Folders-items {
  gap: 20px;
  width: 792px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Folders-section-container {
    max-width: 100%;
    width: 100%;
  }

  .Folders-items {
    justify-content: center;
    width: 100%;
  }

  .Folders-items:after {
    content: "";
    flex-basis: 26%;
    min-width: 220px;
  }
}

.Folder-title-text {
  font-family: 'Gotham-Rounded', Arial, sans-serif;
  font-weight: bold;
  font-size: 36px;
}

.Folder-title-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.Buttons-container {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-left: auto;
}

.Round-button {
  align-items: center;
  background-color: var(--pc-color-blue-300) !important;
  border-radius: 20px;
  border: none;
  color: var(--base-pc-color-white);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  outline: none;
  width: 175px;
}

.Round-button .Button-icon {
  height: 24px;
  margin-right: 8px;
  width: 24px;
}

.Round-button img {
  margin-right: 8px;
}
