.Add-a-vet-confirm-card {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--base-pc-color-white);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 12px;
  opacity: 1;
  padding: 24px;
}

.Add-a-vet-confirm-title-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Add-a-vet-confirm-title-container > h4 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin: 0;
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}

.Add-a-vet-confirm-edit-button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--pc-color-blue-300);
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 4px 0px;
}

.Add-a-vet-confirm-address,
.Add-a-vet-confirm-row-container > span {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Book";
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.Add-a-vet-confirm-row-container > span {
  color: var(--pc-color-onyx-200);
}

.Add-a-vet-confirm-edit-button > span {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
}

.Add-a-vet-confirm-row-container {
  display: flex;
  flex-direction: row;
  gap: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.Add-a-vet-confirm-card-email {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-Book";
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
