.Payment-history-screen-container {
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 18px 48px;
  width: calc(100% - 96px);
}

.Payment-history-screen-main-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.54px;
  width: 100%;
}

.Payment-history-screen-description {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  width: 100%;
}

.Payment-history-screen-policies-container {
  align-items: flex-start;
  background: var(--base-pc-color-white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--pc-space-6, 32px);
  max-width: 1084px;
  min-width: 720px;
  padding: 32px 48px;
  width: 1084px;
}

.Payment-history-screen-policies-container > h2 {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
  margin: 0;
}

.Payment-history-screen-policies-card-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.Payment-history-screen-policy-card {
  align-items: flex-start;
  background: var(--base-pc-color-white);
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
}

.Payment-history-screen-policy-card-logo {
  height: 56px;
  margin-right: 48px;
  width: 146px;
}

.Payment-history-screen-policy-info-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.Payment-history-screen-policy-info-details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.Payment-history-screen-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin-bottom: 8px;
}

.Payment-history-screen-value {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Payment-history-screen-container {
    margin: 18px 24px;
  }

  .Payment-history-screen-policies-container {
    width: 720px;
  }

  .Payment-history-screen-policy-info-container {
    flex-direction: column;
  }
}
