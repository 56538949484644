.Add-vet-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 32px 40px;
  width: 440px;
}

.Add-vet-modal-margin {
  margin-bottom: 24px !important;
}

.Add-vet-modal-close {
  background-color: transparent;
  border-radius: 50%;
  border: none;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
}

.Add-vet-modal > h2 {
  color: #22435e;
  font-family: 'Inter-Semibold';
  font-size: 22px;
  margin: 0;
}

.Add-vet-modal-search {
  color: #3d484d;
  display: inline-block;
  margin: 24px 4px 16px 0;
}

.Add-vet-modal-zipcode {
  color: #1ccfca;
  font-family: 'Inter-Semibold';
  margin-right: 4px;
}

.Add-vet-modal-change {
  color: #22435e;
  margin-left: 4px;
  text-decoration: underline;
}

.Add-vet-modal-not-listed {
  color: #3d484d;
  font-size: 15px;
}

.Add-vet-modal-add-manually {
  color: var(--pc-color-blue-300);
  font-family: 'Inter-Medium';
  font-size: 15px;
}

.Add-vet-modal-selected-vet {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
}

.Add-vet-modal-vet-name {
  color: #1e2226;
  font-size: 15px;
}

.Add-vet-modal-vet-address {
  color: #6d7579;
  font-family: 'Inter-Medium';
  font-size: 12px;
}

.Add-vet-modal-actions {
  margin: 32px 0 0 0;
}

.Add-vet-modal-cancel {
  background-color: #00000033;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-family: 'Inter-Semibold';
  height: 40px;
  margin-right: 16px;
  width: 114px;
}

.Add-vet-modal-cancel:hover {
  background-color: #00000050;
}

.Add-vet-modal-save {
  background-color: #22435e;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-family: 'Inter-Semibold';
  height: 40px;
  vertical-align: baseline;
  width: 98px;
}

.Add-vet-modal-save:hover {
  background-color: #22435ef0;
}

.Add-vet-zip-code-input,
.Add-vet-zip-code-input-error {
  border-radius: 5px;
  border: 2px solid #b8ede8;
  color: #22435e;
  font-family: 'Inter-Medium';
  font-size: 14px;
  height: 41px;
  outline: 1px solid #1ccfca;
  width: 80px;
}

.Add-vet-zip-code-input-error {
  background-color: rgba(204, 0, 0, 0.05);
  border: 1px solid #cc0000;
  margin-bottom: 0;
  outline: 1px solid #cc0000;
}

.Add-vet-zip-code-input-error:focus {
  background-color: rgba(204, 0, 0, 0.05) !important;
  border: 1px solid #cc0000 !important;
  outline: 1px solid #cc0000 !important;
}

.Add-vet-zip-code-error {
  color: #cc0000;
  font-family: 'Inter-Regular';
  font-size: 14px;
  margin-bottom: 11px;
}
