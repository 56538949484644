.Add-pet-screen-loading-container {
  background-color: #8d939a20;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding-top: 300px;
}

.Add-pet-screen-main-container {
  display: flex;
  flex-direction: column;
  padding: 32px 48px 52px 48px;
  width: 100%;
}

.Add-pet-screen-title-page-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;
}

.Add-pet-screen-title-page-container > h1 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin: 0;
}

.Add-pet-screen-title-page-container > p {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Regular";
  letter-spacing: 0.32px;
  margin: 0;
}

.Add-pet-screen-pets-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.Add-pet-screen-add-pet-card {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-color: var(--base-pc-color-white);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  gap: 8px;
  height: 206px;
  justify-content: center;
  letter-spacing: -0.12px;
  line-height: 28px;
  transition: all 0.2s ease;
  width: 348px;
}

.Add-pet-screen-add-pet-card:hover {
  opacity: 0.5;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Add-pet-screen-main-container {
    padding: 24px 24px 52px 24px;
  }
}
