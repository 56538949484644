.Dragzone-overlayer {
  align-items: center;
  background-color: rgba(30, 34, 38, 0.6);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 6;
}

.Dragzone-container {
  align-items: center;
  background-color: var(--pc-color-blue-100);
  border: dashed 4px var(--pc-color-blue-300);
  display: flex;
  flex-direction: column;
  height: 676px;
  justify-content: center;
  width: 1086px;
}

.Dragzone-container > div {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Dragzone-icons-container {
  margin-bottom: 8px;
  position: relative;
  text-align: center;
}

.Dragzone-title {
  color: var(--pc-color-onyx-500);
  display: block;
  font-family: 'Gotham-regular';
  font-size: 20px;
  letter-spacing: -1px;
  margin-top: 8px;
}

.Dragzone-subtitle {
  color: var(--pc-color-onyx-200);
  display: block;
  font-family: 'Gotham-regular';
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}
