.Add-a-vet-form-input-row {
  width: 318px;
}

.Add-a-vet-form-input-city-name {
  width: 256.5px;
}

.Add-a-vet-form-input-zip-code {
  width: 91px;
}

.Add-a-vet-form-input-email {
  width: 100%;
}

.Add-a-vet-form-input-name:focus,
.Add-a-vet-form-input-row:focus {
  border-radius: 8px;
  border: 2px solid var(--pc-color-blue-300);
  outline: 2px solid var(--pc-color-blue-300);
}

div .Add-a-vet-form-input-name:focus,
.Add-a-vet-form-input-row:focus {
  border: none !important;
  box-shadow: none !important;
}

.Add-a-vet-form-inputs-row-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 14px;
}

.Add-a-vet-form-input-name::placeholder,
.Add-a-vet-form-input-city-name::placeholder,
.Add-a-vet-form-select-state-placeholder {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Book";
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
}

.Add-a-vet-form-select-state,
.Add-a-vet-form-select-state-placeholder {
  appearance: none;
  background: #fff url("../../../assets/myVets/chevron.svg") no-repeat right .75rem center/18px 18px;
  border-radius: 8px;
  border: 2px solid var(--pc-color-onyx-100);
  height: 41px;
  padding: 8px;
  width: 257px;
}

.Add-a-vet-form-select-state,
.Add-a-vet-form-select-state-placeholder {
  color: var(--pc-color-onyx-300);
}

div .Add-a-vet-form-select-state:focus,
.Add-a-vet-form-select-state-placeholder:focus {
  border: none !important;
  box-shadow: none !important;
}

.Add-a-vet-form-select-state:focus,
.Add-a-vet-form-select-state-placeholder:focus {
  border-radius: 8px;
  border: 2px solid var(--pc-color-blue-300);
  outline: 2px solid var(--pc-color-blue-300);
}

.Add-a-vet-form-select-state > option,
.Add-a-vet-form-select-state-placeholder > option {
  color: var(--charcoal-500);
  font-family: "Gotham-Regular";
  font-weight: 400;
  line-height: 19.2px;
  margin: 4px;
}
