.Pet-cloud-breadcrumbs-container {
  margin: 0 0 16px 0;
}

.Pet-cloud-breadcrumbs-inner-container {
  align-items: center;
  display: flex;
  height: 100%;
  list-style: none;
  margin-bottom: 0;
  padding-inline-start: 0;
}

.Pet-cloud-breadcrumbs-inner-container img {
  height: 16px;
  width: 16px;
}

.Pet-cloud-breadcrumbs-item button,
.Pet-cloud-breadcrumbs-item-active {
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.408px;
  line-height: normal;
  padding: 0;
}

.Pet-cloud-breadcrumbs-item-active {
  color: var(--pc-color-onyx-200);
  min-width: 35px;
}

.Pet-cloud-breadcrumbs-item button {
  border: none;
}

.Pet-cloud-breadcrumbs-divider {
  margin: 0 12px 3px;
}
