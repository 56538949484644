.Billing-details-webview-container {
  margin-top: 16px;
  width: 1084px;
}

.Billing-details-webview {
  background-color: var(--pc-color-cotton-300);
  border: 0;
  min-height: calc(80vh - 16px);
  width: 1084px;
}

.Billing-details-webview-header {
  width: 1084px;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Billing-details-webview-container,
  .Billing-details-webview,
  .Billing-details-webview-header {
    width: 720px;
  }
}
