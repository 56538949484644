.Recent-file-container {
  border-bottom: 1px solid #eceef0;
  display: flex;
  height: 53.27px;
  margin-top: 15px;
  min-width: 257px;
}

.Recent-file-extension-icon {
  height: 32px;
  width: 27px;
}

.Recent-file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Recent-file-name-container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 192px;
}

.Recent-file-name-redesign {
  color: #000000;
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}

.Recent-file-date {
  color: #7e888c;
  font-family: 'Inter-Medium';
  font-size: 10px;
}

.Recent-file-dots {
  height: 5px;
  margin-top: 10px;
  pointer-events: none;
  width: 23px;
}

.Recent-file-dots-container {
  position: relative;
}

/* Tablet */
@media (min-width: 568px) and (max-width: 1200px) {
  .Recent-file-container {
    align-items: center;
    border-bottom: 1px solid #eceef0;
    display: flex;
    height: 57px;
    margin-top: 0;
  }

  .Recent-file-extension-icon {
    height: 36px;
    margin-left: 2px;
    width: 30px;
  }

  .Recent-file-name-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 12px;
    width: 620px;
  }

  .Recent-file-added-on {
    color: #3d484d;
    font-family: 'Inter-Medium';
    font-size: 12px;
    margin-right: 24px;
  }

  .Recent-file-date-formated {
    color: #7e888c;
    font-family: 'Inter-Medium';
    font-size: 12px;
  }

  .Recent-file-date-container {
    display: flex;
    width: 203px;
  }

  .Recent-file-dots {
    margin-left: 8px;
    margin-top: 0px;
  }
}
