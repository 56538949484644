.My-pets-manual-add-input-container {
  display: flex;
  flex-direction: column;
}

.My-pets-manual-add-input-container > input {
  padding-left: 8px !important;
}

.My-pets-manual-add-input-container > input {
  border-radius: 8px;
  border: 2px solid var(--pc-color-onyx-100);
  display: flex;
  flex-direction: column;
}

.My-pets-manual-add-input-titles {
  color: var(--pc-color-onyx-500);
  display: flex;
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-weight: 350;
  justify-content: space-between;
  line-height: 16px;
  margin-bottom: 8px;
}

.My-pets-manual-add-input-legend {
  color: rgba(29, 36, 51, 0.80);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: 16px;
  text-align: center;
}

.My-pets-manual-add-error-message {
  color: #cc0000;
  font-family: 'Inter-Regular';
  font-size: 13px;
  margin-top: 8px;
}
