.Coverage-overview-policy-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0;
  width: 70%;
}

.Change-coverate-policy-row {
  width: auto;
}

.Coverage-overview-info-title,
.Coverage-overview-figo-fee-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Coverage-overview-info-title {
  margin-bottom: 8px;
}

.Coverage-overview-info-value {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 385px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Coverage-overview-figo-fee-title {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.Coverage-overview-figo-info {
  display: flex;
  flex-direction: column;
  height: 103px;
  width: 100%;
}

.Coverage-overview-figo-info-row {  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
}

.Coverage-overview-tooltip-arrow {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--pc-color-blue-300);
  height: 0;
  width: 0;
}

.Coverage-overview-tooltip-description {
  align-items: flex-start;
  background-color: var(--pc-color-blue-300);
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  gap: 8px;
  height: 50px;
  padding: 4px 8px;
  width: 200px;
}

.Coverage-overview-tooltip-icon {
  height: 14px;
  width: 14px;
}

.Coverage-overview-tooltip-main-class {
  top: -24px;
}

.Coverage-overview-tooltip-text {
  color: var(--base-pc-color-white) !important;
  font-family: "Gotham-Regular" !important;
  font-size: 12px !important;
  line-height: normal !important;
}

.Coverage-overview-figo-fee-value {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}

.Coverage-overview-info-shimmer {
  height: 46px;
  margin-bottom: 24px;
  width: 333px;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Coverage-overview-policy-info-container {
    width: 100%;
  }

  .Change-coverate-policy-row {
    max-width: 55%;
  }

  .Coverage-overview-figo-info {
    max-width:  55%;
  }

  .Coverage-overview-info-value {
    max-width: 215px;
  }
}
