.Insurance-request-change-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Insurance-request-change-inner-container {
  height: 240px;
  text-align: center;
  align-items: center;
}

.Insurance-request-change-title {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.015em;
  line-height: 32px;
  margin: 32px 0 16px;
}

.Insurance-request-change-description {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-weight: 350;
  line-height: 16.8px;
  max-width: 386px;
  margin-bottom: 32px;
}

.Insurance-request-change-description span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}
.Insurance-request-change-button {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 1px solid var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 350;
  gap: 8px;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 16px;
  padding: 12px 16px;
  text-align: center;
  transition: 0.3s;
  width: 386px;
}
