.Input-files-button-container {
  overflow: hidden;
  position: relative;
}

.Input-files-button {
  bottom: 0;
  height: auto !important;
  left: 0;
  opacity: 0;
  padding-left: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}
