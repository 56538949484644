.Renewal-floating-bar-top-container {
  background-color: var(--figo-primary-deep-blue-80);
  border-radius: 8px;
  color: #fff;
  display: flex;
  height: 44px;
  justify-content: space-between;
  padding: 14px 12px 13px 14px;
  width: 100%;
}

.Renewal-floating-bar-top-background {
  background-color: #182f43;
  padding: 0 24px 16px 24px;
  width: 100%;
}

.Renewal-floating-bar-top-premium {
  font-family: 'Inter-Regular';
  font-size: 14px;
}

.Renewal-floating-bar-top-amount {
  font-family: 'Inter-Bold';
  font-size: 14px;
}
