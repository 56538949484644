
.Policy-list-item-container {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
}

.Policy-list-item-container > a,
.Policy-list-item-container > button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline-color: var(--pc-color-blue-300);
  padding: 24px;
  text-align: left;
  width: 100%;
}

.Policy-list-item-container > button[data-selected=true] {
  border: 1px solid var(--pc-color-blue-300);
}

.Policy-list-item-data {
  display: flex;
  gap: 25px;
}

.Policy-list-item-logo {
  margin-right: 23px;
  max-height: 69px;
}

/* Figo */
.Policy-list-item-logo[data-marketingchannel=1] {
  height: 56px;
}

/* PPI */
.Policy-list-item-logo[data-marketingchannel=2] {
  height: 30px;
}

/* AKC */
.Policy-list-item-logo[data-marketingchannel=3] {
  height: 69px;
}

/* Spot */
.Policy-list-item-logo[data-marketingchannel=5] {
  height: 65px;
}

.Policy-list-item-content {
  display: flex;
  gap: 25px;
}

.Policy-list-item-container h3 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Medium";
  font-size: 16px;
}

.Policy-list-item-container p {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
}

.Policy-list-item-container + .Policy-list-item-container {
  margin-top: 16px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Policy-list-item-content {
    flex-direction: column;
  }

  .Policy-list-item-logo {
    align-self: flex-start;
  }
}
