.My-pets-profile-progress-container {
  background-color: #fff;
  border-radius: 8px;
  border-style: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  margin-top: 16px;
  padding: 32px;
  width: 100% ;
}

.My-pets-profile-progress-header {
  color: var(--navy);
  font-family: "Gotham-Regular";
  font-size: 20px;
  justify-content: space-between;
}

.My-pets-profile-progress-title {
  color: #000;
  font-family: "Gotham-Regular";
  font-size: 24px;
}

.My-pets-profile-progress-actions {
  flex-wrap: wrap;
}

.My-pets-profile-progress-bar-container {
  align-items: center;
  margin-top: 24px;
  width: 100%;
}

.My-pets-profile-progress-bar,
.My-pets-profile-progress-bar-completion {
  background-color: #eceef0;
  border-radius: 4px;
  flex-grow: 1;
  height: 7px;
  margin: 8px 0;
}

.My-pets-profile-progress-bar-completion {
  margin: 0;
  background: var(--pc-color-blue-300);
  transition: 1s ease;
  transition-delay: 0.5s;
}

.Percentage-complete{
  margin: auto;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1200px) {
  .My-pets-profile-progress-container {
    margin-top: 0px;
  }
}
