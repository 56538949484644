.Pet-cloud-edit-container {
  width: 100%;
}

.Pet-cloud-edit-container > span {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Pet-cloud-edit-input-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 19px;
  width: 100%;
}

.Pet-cloud-name-edit-input {
  width: 246px;
}

.Pet-cloud-full-width-input {
  margin-top: 19px;
  width: 100%;
}

.Pet-cloud-email-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  margin-top: 19px;
  width: 100%;
}

.Pet-cloud-email-container > span {
  align-items: center;
  color: var(--pc-color-onyx-200);
  display: flex;
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 51px;
  line-height: normal;
  margin-right: 18px;
}

.Pet-cloud-email-input {
  width: 335px; 
}

.Pet-cloud-password-input {
  width: 100%;
}

.Pet-cloud-password-input-label {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Pet-cloud-current-password-container {
  margin: 16px 0 14px 0;
}

.Pet-cloud-current-password-forgot {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 12px;
  font-style: normal;
  font-weight: 350;
  line-height: 100%;
  margin-top: 13px;
  text-decoration-line: underline;
}

.Pet-cloud-confirm-password-container {
  margin: 37px 0;
}

.Pet-cloud-edit-save-button {
  margin-top: 19px;
}

.Pet-cloud-edit-save-button button {
  width: 160px;
}

.Pet-cloud-confirm-email-modal-container {
  width: 520px;
}

.Pet-cloud-confirm-email-container {
  margin-top: 24px;
  width: 100%;
}

.Pet-cloud-password-modal-label {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: -0.07px;
}

.Pet-cloud-forgot-label {
  bottom: 30px;
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration-line: underline;
}

.Pet-cloud-forgot-input {
  width: 472px;
}

.Pet-cloud-forgot-tooltip-container {
  background-color: var(--pc-color-blue-300);
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  padding: 4px 9px;
  top: 34px !important;
}

.Pet-cloud-forgot-tooltip-extra-space {
  bottom: 37px;
}

.Pet-cloud-forgot-tooltip {
  color: var(--base-pc-color-white) !important;
  font-family: 'Gotham-Medium' !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.Pet-cloud-forgot-tooltip-arrow {
  border-bottom: 20px solid var(--pc-color-blue-300);
}

.Pet-cloud-forgot-modal-error {
  align-items: center;
  background-color: #fef1f2;
  border-radius: 4px;
  display: flex;
  height: 24px;
  width: 100%;
}

.Pet-cloud-forgot-modal-error > img {
  height: 16px;
  margin: 0 4px;
  width: 16px;
}

.Pet-cloud-forgot-modal-error > span {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
