:root {
  --teal: #237893;
  --error: #dd3030;
  --success-background: #17dfa4;
  --gray-icon-text: #222;
  --yankees-blue: #192149;
  --gray-subtext: #727272;
  --navy: #22435e;

  --title: var(--navy);

  --dark-mint: #2fb3b4;

  --blue-main: #95e6e2;
  --blue-shade-1: #a9e5e2;
  --blue-shade-2: #bdece9;
  --blue-shade-3: #d3f2f1;
  --blue-shade-dark: #3ecbc4;
  --deep-blue-main: #01313d;
  --deep-blue-shade-1: #293742;
  --deep-blue-shade-2: #4a545d;
  --deep-blue-shade-3: #737a83;
  --ivory-main: #faf2e9;
  --ivory-shade-1: #f9f1e8;
  --ivory-shade-2: #faf4ed;
  --ivory-shade-3: #fbf6f1;
  --citron-main: #e6f972;
  --citron-shade-1: #dcf34b;
  --citron-shade-2: #ebf0a1;
  --citron-shade-3: #f0f4bd;
  --poppy-main: #e06855;
  --poppy-shade-1: #df7159;
  --poppy-shade-2: #e68e76;
  --poppy-shade-3: #eeb09a;

  --primary-700: #172B91;

  --header-background: var(--deep-blue-main);
  --hinted-text: #6b7078;

  /* New color palette :P */
  --akc-primary-catalina-blue-100: #002d72;
  --akc-primary-catalina-blue-15: #d9e5ef;
  --base-pc-color-black: #000;
  --base-pc-color-white: #fff;
  --charcoal-300: #43474b;
  --charcoal-500: #040506;
  --figo-primary-deep-blue-15: #d9e0e2;
  --figo-primary-deep-blue-80: #345a64;
  --figo-primary-deep-blue-100: #01313d;
  --pc-border-radius-xl: #687184;
  --pc-color-blue-100: #f0f2ff;
  --pc-color-blue-300: #1429b8;
  --pc-color-blue-400: #001184;
  --pc-color-blue-500: #000167;
  --pc-color-cotton-100: #f9fafa;
  --pc-color-cotton-200: #f6f8f8;
  --pc-color-cotton-300: #eceeef;
  --pc-color-cotton-500: #b5bac0;
  --pc-color-grey-150: #8d939a47;
  --pc-color-hydrant-100: #fff1f0;
  --pc-color-hydrant-400: #c12010;
  --pc-color-hydrant-500: #730d02;
  --pc-color-leaf-200: #77ce88;
  --pc-color-leaf-300: #039855;
  --pc-color-onyx-100: #8d939a;
  --pc-color-onyx-200: #60666c;
  --pc-color-onyx-300: #43474b;
  --pc-color-onyx-400: #202327;
  --pc-color-onyx-500: #040506;
  --pc-color-leaf-100: #ecfdf3;
  --pc-color-leaf-400: #05603a;
  --pc-color-yellow-100: #fef8e1;
  --pc-color-yellow-200: #fad980;
  --pc-color-yellow-500: #8c3b08;
  --pet-partners-primary-dark-cerulean-100: #005091;
  --spot-red: #cd462b;
  --spot-green: #367A81;
  --pc-color-light-blue-400: #f0f5ff;
  --figo-mint-primary: #bff0Ed;
  --pc-color-yellow-300: #ffc505;
}

/* Figo */
.Colors-1 {
  --primary-background: var(--figo-primary-deep-blue-100);
  --primary-text: var(--figo-primary-deep-blue-100);
}

/* PPI OnePack */
.Colors-4,
/* PPI */
.Colors-2 {
  --primary-background: var(--pet-partners-primary-dark-cerulean-100);
  --primary-text: var(--pet-partners-primary-dark-cerulean-100);
}

/* AKC */
.Colors-3 {
  --primary-background: var(--akc-primary-catalina-blue-100);
  --primary-text: var(--akc-primary-catalina-blue-100);
}

/* Spot */
.Colors-5 {
  --primary-background: var(--spot-red);
  --primary-text: var(--spot-green);
}
