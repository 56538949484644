.Dropzone-cover::before {
  backdrop-filter: blur(5px) !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  bottom: 0;
  content: "";
  height: 100vh;
  height: calc(100% + 92px);
  left: 0;
  position: fixed;
  right: 0;
  top: 92px;
  width: 100vw;
}

.Dropzone-container {
  align-items: center;
  background-color: var(--pc-color-blue-100);
  border-radius: 12px;
  border: dashed 2px var(--pc-color-blue-300);
  display: flex;
  flex-direction: column;
  height: 81%;
  justify-content: center;
  left: 57.2%;
  max-height: 90vh;
  max-width: 90vw;
  overflow: auto;
  position: fixed;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 76.5%;
}

.Dropzone-container-resize {
  height: 100%;
  top: 50%;
}

.Dropzone-cover-resize::before {
  height: 100vh;
  top: 0;
}

.Main-header {
  z-index: 1;
}

.Dropzone-file-icon {
  margin-bottom: 8px;
  position: relative;
  text-align: center;
}

.Dropzone-file-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Dropzone-formats {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-weight: 325;
  line-height: 16.8px;
  margin: 8px;
}

.Dropzone-paragraph {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
}

@media (max-width: 1200px) {
  .Dropzone-container {
    left: 50%;
    top: 53%;
  }
}

@media (max-width: 880px) {
  .Dropzone-container {
    left: 50%;
    top: 53%;
  }

  .Dropzone-cover::before {
    height: 100%;
    width: 100%;
  }
}
