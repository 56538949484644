.Policy-summary-main-container {
  display: flex;
  flex-direction: column;
  padding: 24px 48px 52px 48px;
  width: 100%;
}

.Policy-summary-title-page-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
}

.Policy-summary-title-page-container > h1 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin: 0;
}

.Policy-summary-file-claim {
  align-items: center;
  background-color: var(--primary-text);
  border-radius: 20px;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 40px;
  justify-content: center;
  padding: 12px 16px;
  width: 180px;
}

.Policy-summary-file-claim > span {
  color: var(--base-pc-color-white);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}

.Policy-summary-info-container {
  background: var(--base-pc-color-white);
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  padding: 32px 48px 48px 48px;
  width: 100%;
}

.Policy-summary-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Policy-summary-title > h2 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.42px;
}

.Policy-summary-file-claim > img,
.Policy-summary-title > img {
  height: 16px;
  width: 16px;
}

.Policy-summary-shimmer-content {
  align-items: center;
  display: flex;
  height: 40vh;
  width: 100%;
}

.Policy-summary-header-actions {
  align-items: flex-start;
  display: flex;
}

.Policy-summary-add-pet,
.Policy-summary-add-pet-icon {
  margin-right: 8px;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Policy-summary-main-container {
    padding: 24px 24px 52px 24px;
  }

  .Policy-summary-info-container {
    width: 100%;
  }
}
