.Add-pet-iframe-title-page-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
}
.Add-pet-iframe-title-page-container > h1 {
  color: var(--akc-primary-catalina-blue-100);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin: 0;
}

.Add-pet-iframe-title-page-container > p {
  color: var(--akc-primary-catalina-blue-100);
  font-family: "Gotham-Regular";
  letter-spacing: 0.32px;
  margin: 8px 0 0 0;
}

.Add-pet-iframe-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  padding: 32px 48px 48px 48px;
  width: 100%;
}

.Add-pet-iframe-container > iframe {
  border-color: var(--base-pc-color-white);
  border-radius: 8px;
  border-style: solid;
  height: 100%;
}
