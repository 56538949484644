.View-more-button:hover,
.View-more-button {
  align-items: center;
  background-color: transparent;
  border-color: var(--primary-text);
  border-style: none none solid none;
  border-width: 1px;
  color: var(--primary-text);
  display: flex;
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  gap: 8px;
  height: 24px;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 4px 0px;
  text-align: center;
}
