.My-pets-search-input-my-pets-add-vet-modal-input:focus {
  border-radius: 8px;
  border: 2px solid var(--pc-color-blue-100) !important;
}

.My-pets-search-input-container.focused {
  border-radius: 8px;
  border: 2px solid var(--pc-color-blue-300);
}

div .My-pets-search-input-container-input:focus {
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.My-pets-search-input-container {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid var(--pc-color-onyx-100);
  display: flex;
  line-height: 16.8px;
  width: 100%;
}

.My-pets-search-input-container-input {
  border-style: none;
  color: var(--charcoal-500);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-weight: 400;
  margin-left: 0;
  margin-top: 3px;
  padding-left: 8px !important;
  pointer-events: all;
  position: relative;
  width: 90%;
  z-index: 1;
}

.My-pets-search-input-container-input :focus {
  border-style: none !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.My-pets-search-input-container > img {
  margin: 0 0 0 16px;
}

.My-pets-search-input-dropdown-container {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .08);
  display: flex;
  flex-direction: column;
  height: 100%;
  height: auto;
  max-height: 266px;
  overflow-y: auto;
  padding-bottom: 0px;
  position: absolute;
  width: 90%;
}

.My-pets-search-input-dropdown {
  z-index: 0;
}

.My-pets-pets-shade {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

.My-pets-search-input-empty-message {
  text-align: center;
  z-index: 0;
}

.My-pets-search-input-list-item {
  background-color: transparent;
  border-color: transparent;
  border-style: solid none solid none;
  border-width: 1px;
  display: flex;
  margin-top: 8px;
  text-align: left;
  width: 100%;
  z-index: 4;
}

.My-pets-search-input-list-item:hover {
  background-color: var(--pc-color-blue-100);
}

.My-pets-search-input-container-input::placeholder {
  color: var(--pc-color-onyx-100);
  font-size: 17px;
}

@media (max-width: 586px) {
  .My-pets-search-input-container {
    width: 98%;
  }
}
