:root {
  --landscape-width: 260px;
  --portrait-width: 260px;
  --header-height: 92px;
  --secs: 0.4s;
}

.Content {
  background-color: #f8f8f8;
  flex: 1;
}

.Header-link {
  color: #fff;
  font-size: 16px;
}

.Layout {
  height: 100%;
  min-height: calc(100vh - var(--header-height));
}

.Main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.My-account-icon {
  border: 1px solid #fff;
  height: 32px;
  margin-right: 12px;
  width: 32px;
}

.Notifications-container {
  margin-right: 32px;
}

.Notification-icon {
  height: 32px;
  margin-right: 12px;
  widows: 25px;
}

.Sidebar-overlay {
  background-color: rgba(30, 34, 38, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

#layout {
  display: flex;
  margin: 0;
}

#layout .content {
  display: flex;
  box-sizing: border-box;
  height: 100%;
}

#main {
  background: var(--bg-main);
  flex-grow: 1;
}

#left,
#right {
  transition: width var(--secs);
  width: 0;
}

#left .icon,
#right .icon {
  cursor: pointer;
  font-size: var(--header-height);
  height: 42px;
  left: 16px;
  line-height: var(--header-height);
  position: fixed;
  text-align: center;
  top: 16px;
  user-select: none;
  width: 42px;
  z-index: 10;
}

#left .sidebar,
#right .sidebar {
  transition: transform var(--secs);
}

#left {
  z-index: 5;
}

#left .sidebar {
  background: #f8f8f8;
  height: 100%;
}

#left .sidebar.closed {
  overflow: hidden;
  transform: translateX(-100%);
}

@media (orientation: landscape) {
  #left.open,
  #right.open {
    width: calc(1% * var(--landscape-width));
  }
}

@media (orientation: portrait) {
  #left .sidebar,
  #right .sidebar {
    overflow: hidden;
    position: fixed;
    width: var(--portrait-width);
  }
}

@media (orientation: landscape) {
  #left .sidebar,
  #right .sidebar {
    width: var(--landscape-width);
  }
}

@media (orientation: landscape) and (max-width: 1200px) {
  #left .sidebar,
  #right .sidebar {
    overflow: hidden;
    position: fixed;
  }
}

@media (orientation: landscape) and (min-width: 1200px) {
  #left, #right {
    width: initial;
  }

  #left .sidebar.closed {
    transform: translateX(0%);
  }
}

.Main-header {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  display: flex;
  justify-content: flex-start;
  min-height: var(--header-height);
}

.Main-header-logo {
  padding: 0 24px;
}

.Main-header-centered {
  margin: auto;
  width: 876px;
}

.Main-header-paw {
  margin-right: 12px;
}

#left .Side-menu-burger-icon,
#right .Side-menu-burger-icon {
  cursor: pointer;
  left: 24px;
  line-height: var(--header-height);
  position: fixed;
  text-align: center;
  top: 22px;
  transition: all 0.5s;
  user-select: none;
  z-index: 10;
}

.Side-menu-burger-icon {
  display: none;
}

.Side-menu-burger-open {
  transform: rotate(180deg);
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Main-header-container {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .Main-header-logo {
    padding-left: 96px;
  }

  .Main-header-centered {
    padding: 0 24px;
    width: 100%;
  }

  .Sidebar {
    height: calc(100% - var(--header-height)) !important;
  }

  .Side-menu-burger-icon {
    display: block;
  }
}
/* End new styles */

/* Mobile */
@media (max-width: 568px) {
  .Main-header-centered {
    padding: 0 16px;
    width: 100%;
  }
}
