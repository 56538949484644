.Change-coverage-footer-container {
  align-items: center;
  border-radius: 0 0 8px 8px;
  border-style: solid none none none;
  border-top: 4px solid var(--pc-color-cotton-300);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Change-coverage-footer-price-shimmer {
  height: 100%;
  margin-left: 48px;
  width: 10%;
}

.Change-coverage-footer-price-container {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 48px;
}

.Change-Coverage-footer-buttons-container {
  justify-content: flex-end;
  margin: 48px 48px 48px 0;
}

.Change-coverage-footer-price {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
  margin: 0;
}

.Change-coverage-footer-text {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Change-Coverage-footer-buttons-container {
    gap: 9px;
  }

  .Change-Coverage-footer-buttons-container > button {
    width: 216.88542px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Change-coverage-footer-container {
    border: none;
  }

  .Change-coverage-footer-price-container {
    display: none;
  }

  .Change-Coverage-footer-buttons-container {
    margin: 0 24px 32px;
  }
}
