.Contact-us-card-provider-card {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  border: 1px solid var(--pc-color-cotton-300);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  min-height: 104px;
  min-width: 472px;
  padding: 24px;
  width: 100%;
}

.Contact-us-card-provider-back-arrow {
  height: 24px;
  object-fit: contain;
  transform: rotate(180deg);
}
