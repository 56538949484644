.Pet-copay-coverage-section-card,
.Pet-copay-coverage-section-card-selected {
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 16px;
  border: 2px solid var(--deep-blue-shade-3);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  padding: 24px;
}

.Pet-copay-coverage-section-card-selected {
  background-color: var(--blue-shade-3);
  border: 2px solid var(--deep-blue-main);
}

.Pet-copay-coverage-section-title {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.Pet-copay-coverage-section-description {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.Pet-copay-coverage-section-bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Pet-copay-coverage-section-info-img {
  height: 16px;
  margin-right: 6px;
  width: 16px;
}

.Pet-copay-coverage-section-info-text {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-copay-coverage-section-description {
    text-align: left;
  }
}
