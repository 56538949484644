.Content-scrollable {
  overflow-x: hidden; 
}

/* check usage */
.Header-line-separator {
  background-color: #2c6085;
  height: 0.4px;
  margin: 0px 32px 12px;
}

/* check usage */
.Header-navigation {
  display: flex;
  flex-direction: column;
}

/* check usage */
.Header-navigation-button {
  margin-bottom: 12px;
  margin-left: 16px;
}

.Header-navigation-button:hover {
  background-color: #112230;
  border-radius: 8px;
  color: #fff;
}

/* check usage */
.Header-navigation-link {
  align-items: center;
  color: #fff;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 12px 32px 12px 32px;
}

/* check usage */
.Link-active {
  background-image: linear-gradient(#2c6085, #182f43);
  border-radius: 8px;
}

/* check usage */
.Navigation-row {
  flex: 1;
}

/* check usage */
.Screen-header {
  background-color: rgb(24,47,67);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 117px;
}

.Screen-header-transparent {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin: 34px 0 0 52px;
  max-width: 1025px;
}

/* check usage */
.Screen-title {
  color: #fff;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 12px;
  margin-left: 32px;
}

.Screen-title-black {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin-top: 28px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Screen-header {
    padding: 0 48px;
  }

  .Screen-title {
    margin-left: 0;
  }

  /* check usage */
  .Header-line-separator { 
    margin: 0 0 12px;
  }

  .Header-navigation-button:first-child { 
    margin-left: 0;
  }

  .Screen-header-transparent {
    margin: 28px 28px 24px 28px;
  }
}
