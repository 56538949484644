.Check-content-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.07px;
  line-height: 16px;
  margin-bottom: 16px;
}

.Check-content-address {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 32px;
  white-space: pre-line;
}

.Check-content-footer-text {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
