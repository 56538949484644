.Add-a-pet-modal-main-container {
  align-items: center;
  background: var(--base-pc-color-white);
  border-radius: 16px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  min-height: 721px;
  padding: 24px;
  width: 520px;
}

.Add-a-pet-modal-content-container {
  width: 100%;
}

.Add-a-pet-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Add-a-pet-modal-close-icon-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 32px;
  justify-content: center;
  padding: 8px;
  width: 32px;
}

.Add-a-pet-modal-close-icon {
  height: 16px;
  pointer-events: none;
  width: 16px;
}

.Add-a-pet-modal-main-container h3 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin: 0;
}

.Add-a-pet-modal-description {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.Add-a-pet-row-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 57px;
  margin: 32px 0;
}

.Add-a-pet-modal-pet-breed-container > p {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.07px;
  line-height: 16px;
}

.Add-a-pet-modal-pet-name {
  max-height: 150px;
  resize: vertical;
}

.Add-a-pet-modal-pet-breed-container::placeholder,
.Add-a-pet-modal-input-placeholder,
.Figo-select-search-input::placeholder {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Figo-select-search-input {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Add-a-pet-modal-pet-breed-wrapper {
  display: flex;
  height: 57px;
}

.Add-a-pet-modal-pet-breed-container {
  min-height: fit-content !important;
  padding-top: 0;
  width: 100% !important;
}

.Add-a-pet-modal-pet-weight::-webkit-outer-spin-button,
.Add-a-pet-modal-pet-weight::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Add-a-pet-modal-pet-weight {
  -moz-appearance: textfield;
  width: 224px;
}

.Add-a-pet-modal-pet-breed-icon {
  height: 16px;
  transform: rotate(270deg);
  width: 16px;
}

.Add-a-pet-modal-footer-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.Add-a-pet-modal-pet-bio {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  border: 1px solid var(--pc-color-onyx-300);
  display: flex;
  gap: 8px;
  min-height: 120px;
  padding: 8px 9px 0px 9px;
  width: 100%;
}

.Add-a-pet-modal-save {
  background-color: #2fb3b4;
  border-color: #2fb3b4;
  height: 40px;
  margin: 16px auto 0;
  padding: 0;
  width: 98px;
}

.Add-a-pet-modal-error {
  color: var(--pc-color-hydrant-400);
  font-size: 12px;
  line-height: 11px;
  margin: 4px 16px;
  text-align: center;
}

.Add-a-pet-modal-delete-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.Add-a-pet-modal-delete {
  background-color: transparent;
  border-color: var(--pc-color-hydrant-400);
  border-style: none none solid none;
  border-width: 0 0 1px 0;
  color: var(--pc-color-hydrant-400);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}

.Add-a-pet-modal-edit-message {
  background-color: var(--pc-color-light-blue-400);
  border-radius: 4px;
  display: flex;
  margin: 28px 0;
  padding: 4px;
  width: 100%;
}

.Add-a-pet-modal-edit-message-icon {
  height: 16px;
  margin-right: 4px;
  width: 16px;
}

.Add-a-pet-modal-edit-message-title {
  font-family: 'Gotham-Regular';
  font-size: 12px;
  font-weight: 350;
  line-height: 14.4px;
  margin-bottom: -5px;
}

.Add-a-pet-modal-edit-message-button {
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  cursor: pointer;
  font-family: 'Gotham-medium';
  font-size: 12px;
  font-weight: 325;
  line-height: 14.4px;
  padding: 0;
  text-decoration: underline;
}
