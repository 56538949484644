.Pets-health-main-container {
  background: linear-gradient(0deg, rgba(57, 83, 255, 0.50) 0%, rgba(57, 83, 255, 0.50) 100%),
    url('../../../assets/home/newDesign/background-pets-black-and-white.jpg') no-repeat;
  background-size: cover;
  border-radius: 8px;
  height: fit-content;
  margin-top: 32px;
  max-width: 1084px;
  padding: 24px;
  width: 100%;
}

.Pets-health-card {
  background-color: #fff;
  background: rgba(255, 255, 255, 0.90);
  border-radius: 8px;
  padding: 24px;
  width: 406px;
}

.Pets-health-card-title {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-Rounded";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 32px;
  width: 300px;
}

.Pets-health-card-description {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: normal;
  width: 300px;
}
