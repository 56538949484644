.My-pets-profile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 48px;
  width: 100%;
}

.Right-column-cards {
  width: 448px;
}

.My-pets-cards-container {
  margin: 0 50px 0 50px;
}

.My-pets-cards-container-box {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
}

.My-pets-profile-grid-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
  width: 100%;
}

.My-pets-profile-card-container {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--pc-color-cotton-300);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  gap: 20px;
  padding: 20px 5px 5px 20px;
}

.My-pets-profile-card-text-content {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.My-pets-profile-card-title {
  color: #040506;
  font-family: "Gotham-Rounded";
  font-size: 20px;
  font-weight: 350;
}

.My-pets-profile-card-paragraph {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 14px;
  font-weight: 400;
}

.My-pets-profile-card-icon {
  height: 40px;
  margin-top: 5px;
  width: 40px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Right-column-cards {
    width: 345px;
  }

  .My-pet-cards-container {
    margin: 0 !important;
  }

  .My-pet-cards-container-box {
    margin-top: 16px;
  }
}
