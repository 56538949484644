.Docs-header-container {
  display: flex;
  justify-content: flex-end;
  width: 1120px;
}

@media (max-width: 1140px) {
  .Docs-header-container {
    justify-content: center;
    width: 100%;
  }

  .Docs-header-container-trash {
    display: flex;
    justify-content: flex-end;
    width: 704px;
  }
}

/* re-design */
.Docs-header-justify-content-start {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1025px;
  width: 100%;
}

.Docs-header-button-container {
  align-items: center;
  align-self: center;
  background-color: transparent;
  border-color: var(--pc-color-blue-300);
  border-style: none none solid none;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: fit-content;
  justify-content: center;
  margin-right: 24px;
  padding: 4px 0;
  transition: all 0.25s ease;
  width: fit-content;
}

.Docs-header-button-container:last-of-type {
  margin-right: 0;
}

.Docs-header-button-container > img {
  height: 16px;
  width: 16px;
}

.Docs-header-button-container:hover {
  opacity: 0.7;
}

.Docs-header-button-container > span {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  pointer-events: none;
}

.Docs-header-button-container > img {
  height: 20px;
  pointer-events: none;
  width: 20px;
}

.Docs-header-button-disabled {
  pointer-events: none;
  border-color: #b0bbbf;
}

.Docs-header-button-disabled img {
  opacity: 0.4;
}

.Docs-header-button-disabled span {
  color: #b0bbbf;
}
