.View-file-content-overlay {
  align-items: center;
  background-color: rgba(30, 34, 38, 0.6);
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding-top: 5%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.View-file-content-wrapper-hide {
  z-index: -1;
}

.View-file-content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.View-file-content-header {
  align-items: center;
  background: var(--base-pc-color-white);
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(112, 144, 176, 0.05);
  display: flex;
  flex-direction: row;
  height: 64px;
  margin: 0 0 24px 0;
  width: 816px;
}

.View-file-content-name-container {
  align-items: center;
  display: flex;
  margin-right: 16px;
}

.View-file-content-name-container > img {
  height: 40px;
  margin: 12px 8px 12px 16px;
  width: 40px;
}

.View-file-content-name {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.408px;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 538px;
}

.View-file-content-button-container {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.View-file-content-download-button {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.View-file-content-download-button-content {
  align-items: center;
  background-color: var(--base-pc-color-white);
  display: flex;
  flex-direction: row;
  height: 16px;
  margin-bottom: 4px;
  width: 108px;
}

.View-file-content-download-button-content > img {
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 8px;
  padding: 1.333px 2px 1.81px 2px;
  width: 16px;
}

.View-file-content-download-button-content > span {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}

.View-file-content-download-bottom-line {
  background-color: var(--pc-color-blue-300);
  height: 1px;
  width: 108px;
}

.View-file-content-close-button {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border: none;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-left: 40px;
  transition: all 0.7s ease;
  width: 16px;
}

.View-file-content-close-button:hover {
  opacity: .8;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1200px) {
  .View-file-content-header  {
    max-width: 720px;
  }

  .View-file-content-name-container {
    max-width: 70%;
  }
}

