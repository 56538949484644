.My-pets-image-editor-container {
  height: 308px;
  padding-top: 32px;
  width: 100%;
}

.My-pets-image-editor-modal {
  height: 553px;
  width: 520px;
}

.My-pets-image-editor-close-icon-container {
  background-color: transparent;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 18px;
  top: 12px;
}

.My-pets-image-editor-close-icon-container:focus {
  outline: none;
}

.My-pets-image-editor-title {
  color: var(--charcoal-500);
  font-family: "Gotham-Regular";
  font-size: 24px;
  font-weight: 350;
  line-height: 0.91;
  margin: 0px 24px;
  text-align: left;
}

.My-pets-image-editor-subtitle {
  color: var(--charcoal-300);
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 350;
  line-height: 0.91;
  margin: 12px 24px;
  text-align: left;
}

.My-pets-modal-close-icon {
  height: 16px;
  width: 16px;
}

.My-pets-image-editor-actions-container {
  display: flex;
  flex-direction: column-reverse;
  padding: 0 18px;
}

.My-pets-image-editor-actions-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.My-pets-image-editor-error {
  left: 64px;
  position: absolute;
  right: 64px;
  text-align: center;
}

.My-pets-image-editor-editing-save,
.My-pets-image-editor-replace {
  background: #22435e;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-family: 'Inter-Semibold';
  letter-spacing: 0.4px;
  line-height: 1.25;
  padding: 10px 31.2px;
}

.My-pets-image-editor-editing-save {
  margin-left: 16px;
  padding: 10px 43.9px;
}

.My-pets-image-editor-remove {
  background: none;
  border: none;
  color: #c12010;
  font-family: "Gotham-Regular";
  font-size: 14px;
  letter-spacing: 0;
  padding: 0;
  text-decoration: underline;
}

.My-pets-image-editor-editing-actions {
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  padding: 56px 40px 32px;
}

.My-pets-image-editor-remove-title {
  color: #22435e;
  font-family: 'Inter-Semibold';
  font-size: 20px;
  margin: auto;
  text-align: center;
}

.My-pets-image-editor-remove-confirmation {
  color: #3d484d;
  line-height: 1.25;
  margin: auto;
  text-align: center;
  width: 254px;
}

.My-pets-image-editor-keep {
  background: #eceef0;
  border-radius: 5px;
  border: none;
  color: #3d484d;
  font-family: 'Inter-Semibold';
  letter-spacing: 0.13px;
  line-height: 1.25;
  padding: 10px 30px;
}

.My-pets-image-editor-remove-actions {
  margin: 24px 61px 46px 62px;
  text-align: center;
}

.My-pets-image-editor-remove-content {
  margin-top: 15px;
}

.My-pets-image-editor-remove-container {
  align-items: flex-start;
  padding: 24px;
}

.My-pets-image-editor-remove-title-modal {
  text-align: left;
  color: var(--charcoal-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-weight: 350;
}

.My-pets-image-editor-remove-subtitle-modal {
  text-align: left;
  color: var(--charcoal-300);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-weight: 400;
}

.My-pets-image-editor-remove-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.My-pets-image-editor-remove-button {
  align-items: center;
  background-color: var(--pc-color-hydrant-400);
  border-radius: 20px;
  border: 1px solid var(--pc-border-radius-xl);
  color: var(--base-pc-color-white);
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  margin-top: 24px;
  min-width: 140px;
  padding: 12px 16px;
  text-align: center;
}

.My-pets-image-editor-remove-button:disabled {
  background: var(--pc-color-cotton-200);
  border: none;
  color: var(--pc-color-onyx-200);
}

.My-pets-image-editor-remove-button:hover,
.My-pets-image-editor-remove-button:active {
  background-color: var(--pc-color-hydrant-500) !important;
  border: none;
  color: var(--base-pc-color-white);
}
