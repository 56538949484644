.Edit-payment-modal {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  min-width: 288px;
  padding: 0 16px 12px 16px;
}

.Edit-payment-pet-list-item {
  display: flex;
  flex-direction: row;
  width: 391px;
}

.Edit-payment-pet-list-item-margin {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  width: 391px;
}

.Edit-payment-pet-list-petinfo-container {
  display: flex;
  flex-direction: column;
  width: 249px;
}

.Edit-payment-pet-list-name {
  color: #455257;
  font-family: "Inter-Medium";
  font-size: 13px;
  line-height: 20px;
}

.Edit-payment-pet-list-policy {
  color: #455257;
  font-family: "Inter-Regular";
  font-size: 13px;
  line-height: 20px;
}

.Edit-payment-pet-list-method {
  color: #4e5d63;
  font-family: "Inter-Medium";
  font-size: 13px;
  line-height: 20px;
  margin: 0 66px 0 0;
  text-align: center;
  width: 56px;
}

.Edit-payment-pet-list-update {
  align-items: center;
  display: flex;
  height: 20px !important;
  justify-content: center;
  width: 20px !important;
}

.Edit-payment-pet-list-update-div {
  align-items: center;
  display: flex;
  height: 20px !important;
  justify-content: center;
}

.Edit-payment-pet-list-checkbox-label {
  width: 0px !important;
}

.Edit-payment-pet-list-checkbox-image {
  margin-right: 0px !important;
}

.Edit-payment-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 312px;
}

.Edit-payment-button-cancel {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border: none;
  height: 48px;
  justify-content: center;
  transition: 0.3s;
  width: 100%;
}

.Edit-payment-button-cancel:hover {
  background-color: #e2e6ea;
  border-color: #dae0e5;
  color: #212529;
}

.Edit-payment-button-cancel-text {
  color: #7e888c;
  font-family: "Inter-SemiBold";
  font-size: 16px;
}

.Edit-payment-policy-withoutlist-container {
  background-color: var(--base-pc-color-white);
  display: flex;
  max-width: 490x;
  padding: 26px 12px 8px;
  width: 100%;
}

.Edit-payment-withoutlist-title-text {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
}

.Edit-payment-withoutlist-description-container {
  max-width: 490px;
  padding: 0 12px;
}

.Edit-payment-withoutlist-description-text {
  color: var(--charcoal-300);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.2px;
  margin-bottom: 24px;
}

.Edit-payment-withoutlist-buttons-container {
  display: flex;
  padding: 0 12px 12px;
  width: 100%;
}

.Edit-payment-withoutlist-buttons-container .Edit-payment-button-update,
.Edit-payment-withoutlist-buttons-container .Edit-payment-button-cancel {
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  padding: 12px 16px;
  width: 140px;
}

.Edit-payment-withoutlist-buttons-container .Edit-payment-button-update,
.Edit-payment-withoutlist-buttons-container .Edit-payment-button-update:hover {
  background-color: var(--pc-color-blue-300);
  border: 1px solid var(--pc-color-blue-300);
  margin-right: 8px;
  transition: 0.3s;
}

.Edit-payment-withoutlist-buttons-container .Edit-payment-button-update-text {
  color: var(--base-pc-color-white);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0.02em;
  line-height: 16px;
  text-align: center;
}

.Edit-payment-withoutlist-buttons-container .Edit-payment-button-cancel {
  background: var(--base-pc-color-white);
  border: 1px solid var(--pc-border-radius-xl);
}

.Edit-payment-withoutlist-buttons-container .Edit-payment-button-cancel-text {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0.02em;
  line-height: 16px;
  text-align: center;
}

.Edit-payment-withoulist-description-container p {
  text-align: start;
}

.Edit-payment-policy-withoulist-close-button {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 48px;
  top: 24px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Edit-payment-modal {
    width: 361px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Edit-payment-policy-withoulist-close-button {
    right: 24px;
  }

  .Edit-payment-withoutlist-title-text {
    font-size: 20px;
  }

  .Edit-payment-withoutlist-description-text {
    font-size: 15px;
  }

  .Edit-payment-withoutlist-buttons-container .Edit-payment-button-update-text,
  .Edit-payment-withoutlist-buttons-container .Edit-payment-button-cancel-text {
    font-size: 14px;
  }
}
