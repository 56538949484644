.Cancel-button {
  background-color: #fff;
  border: none;
  color: #7e888c;
  font-size: 16px;
  font-family: "Inter-SemiBold";
  margin-right: 12px;
}

.Save-button {
  background-color: #2fb3b4;
  border-color: #2fb3b4;
  font-family: "Inter-Regular";
  max-width: 98px;
  min-width: 98px;
  right: 0;
}

.Save-button:hover {
  background-color: #278f90;
  border-color: #278f90;
}

.Success-button-container {
  display: flex;
  flex-direction: row;
}

.Save-button-inactive {
  background-color: #b0bbbf;
  border-color: #b0bbbf;
  font-family: "Inter-Regular";
  max-width: 98px;
  min-width: 98px;
  right: 0;
}

.Save-button-inactive:hover {
  cursor: default;
}