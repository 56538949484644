.Payment-history-details-row,
.Payment-history-details-row-total {
  background-color: var(--pc-color-blue-100);
  display: flex;
  flex-direction: row;
  gap: var(--pc-border-radius-lg, 16px);
  justify-content: flex-end;
  padding: 0px 100px;
  width: 100%;
}

.Payment-history-details-row-total {
  border-color: var(--pc-color-cotton-500);
  border-style: none none solid none;
  border-width: 2px;
}

.Payment-history-details-value,
.Payment-history-details-total-value {
  align-items: center;
  color: var(--base-pc-color-black);
  display: flex;
  flex-direction: row;
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  height: 17px;
  justify-content: space-between;
  line-height: normal;
  margin-bottom: 16px;
  min-width: 200px;
}

.Payment-history-details-total-value {
  font-family: 'Gotham-Medium';
  font-weight: 350px;
}
