.Resources-main-container {
  margin-top: 48px;
}

.Resources-title {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 32px;
}

.Resources-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
}
