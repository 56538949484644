.Billing-and-payments-container {
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 18px 48px;
  width: calc(100% - 96px);
}

.Billing-and-payments-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  width: 100%;
}

.Billing-and-payments-description {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 19px;
  text-align: left;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Billing-and-payments-container {
    display: flex;
    flex-direction: column;
    margin: 18px 24px 18px 32px;
  }
}
