.Generic-modal-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 520px;
}

.Generic-modal-container > h3 {
  color: var(--charcoal-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 6px;
  width: 100%;
}

.Generic-modal-description-text {
  color: var(--charcoal-300);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
  margin: 0;
}

.Generic-modal-close-button {
  align-items: center;
  background: var(--base-pc-color-white);
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  padding: 8px;
  top: 20px;
}

.Generic-modal-close-button > img {
  flex-shrink: 0;
  height: 14.857px;
  width: 14.857px;
}

.Generic-modal-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  width: 100%;
}

.Generic-modal-buttons-container:has(> :nth-child(2)) {
  justify-content: left;
}