.Flex {
  display: flex;
}

.Flex-column {
  flex-direction: column;
}

.Flex-row {
  display: flex;
  flex-direction: row;
  width: auto;
}

.Flex-wrap {
  flex-wrap: wrap;
}

.Align-items-flex-end {
  align-items: flex-end;
}

.Hide {
  display: none;
}

.Branded-icon {
  stroke: var(--primary-text);
}
