.Found-lost-pet-contact-form-form {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  width: 425px;
}

.Found-lost-pet-contact-form-title {
  font-family: "Inter-Regular";
  font-size: 47px;
  font-weight: 400;
  line-height: 83.05px;
  margin-bottom: 24px;
  text-align: center;
}

.Found-lost-pet-contact-form-submit-button {
  align-self: center;
  height: 49px !important;
  margin: 32px 0 72px;
  width: 294px !important;
}

/* Mobile */
@media (max-width: 568px) {
  .Found-lost-pet-contact-form-title {
    font-size: 40px;
    line-height: 59px;
  }

  .Found-lost-pet-contact-form-form {
    width: 83%;
  }

  .Found-lost-pet-contact-form-submit-button {
    width: 100% !important;
  }
}
