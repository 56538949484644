.My-pets-image-editable-container {
  position: relative;
  width: 100%;
}

.My-pets-image-editable-rotate {
  background: none;
  border: none;
  cursor: pointer;
}

.My-pets-image-editable-cropper-container {
  border-radius: 4px;
  height: 308px;
  margin: 0 18px;
  overflow: hidden;
  position: relative;
  width: 484px;
}

.My-pets-image-editable-crop-area {
  border: 4px solid #fff !important;
  color: rgba(254, 254, 254, 0.6) !important;
}

.My-pets-image-editable {
  background-color: var(--pc-color-blue-300);
}

.My-pets-image-editable-slider-container {
  left: 32px;
  margin-top: 8px;
  position: absolute;
  right: 90px;
}

.My-pets-image-editor-actions-box {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 8px 24px;
}

.My-pets-image-editable-rotate-arrow-left {
  transform: scaleX(-1);
}

.My-pets-image-editor-actions-top-bottom {
  display: flex;
  gap: 10px;
}

