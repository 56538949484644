.Modal-wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.Modal-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Modal-backdrop-old {
  background-color: rgba(30, 34, 38, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.Modal-backdrop {
  backdrop-filter: blur(3px);
  background-color: rgba(28, 37, 48, 0.75);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.Modal-container-card-content {
  align-items: center;
  animation-duration: 0.5s;
  background-color: #fff;
  border-radius: 16.2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.20);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.Modal-container-card-content:has(> .Content-scrollable-y) {
  overflow: hidden;
}

.Modal-card-button-close {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 20px;
}

@keyframes modalAppearOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modalDisappearOpacity {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes modalAppear {
  from {
    bottom: -150vh;
  }

  to {
    bottom: 0;
  }
}

@keyframes modalDisappear {
  from {
    bottom: 0;
  }

  to {
    bottom: -150vh;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Modal-card-title {
    font-family: 'Inter-Bold';
    font-size: 20px;
    margin: 32px 0 8px 0;
    min-height: 30px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Modal-container {
    max-width: 100%;
  }

  .Modal-container-card-content {
    width: 90%;
  }

  .Modal-container-card-content, .My-pets-add-vet-modal {
    width: 99% !important;
  }

  .Multi-modal-card-container {
    animation-duration: 0.4s;
  }

  .Modal-card-title {
    font-family: 'Inter-Bold';
    font-size: 20px;
    margin: 32px 0 10px 0;
  }
}
