.Public-footer {
  align-items: center;
  background: #f1fde1;
  display: flex;
  height: 124px;
  justify-content: space-between;
  padding: 0 36px 0 49px;
}

.Public-footer-support {
  color: var(--gray-icon-text);
  font-family: 'Inter-Medium';
  font-size: 18px;
  letter-spacing: -0.7px;
  line-height: 21.78px;
}

.Public-footer-support-email {
  margin-top: 8px;
}

.Public-footer-support-email > a {
  color: #000;
  font-family: 'Inter-Regular';
  margin-left: 10px;
}

.Public-footer-copyright {
  color: var(--gray-subtext);
  font-size: 14px;
  letter-spacing: -0.7px;
  line-height: 17px;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Public-footer {
    flex-direction: column;
    height: 142px;
    padding: 29px 17px 20px;
    text-align: center;
  }
}

/* Tablet Landscape */
@media (min-width: 569px) and (max-width: 1200px) and (orientation: landscape) {
  .Public-footer {
    align-items: center;
    background: #f1fde1;
    display: flex;
    flex-direction: row;
    height: 124px;
    justify-content: space-between;
    padding: 0 36px 0 49px;
    text-align: start;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Public-footer {
    flex-direction: column;
    padding: 18px 17px 24px 16px;
    text-align: center;
  }
  
  .Public-footer-support {
    font-size: 14px;  
    margin-bottom: 24px;
  }

  .Public-footer-copyright {
    font-size: 12px;
  }
}

/* Small resolution adjustment */
@media (max-height: 900px) and (min-width: 569px) {
  .Public-footer {
    height: 80px;
    padding: 0 36px 0 49px;
  }
}
