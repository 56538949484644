.Empty-screen-common-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
  width: 100%;
}

.Empty-screen-common-container > h3 {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 16px;
}

.Empty-screen-common-container > span {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  width: 470px;
}
