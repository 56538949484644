.Pet-protect-copay-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 433px;
  padding: 28px;
}

.Pet-protect-copay-modal > img {
  height: 60px;
  margin-bottom: 16px;
  width: 60px;
}

.Pet-protect-copay-modal > h3 {
  color: #22435e;
  font-family: 'Inter-Semibold';
  font-size: 18px;
  margin-bottom: 18px;
}

.Pet-protect-copay-modal > h4 {
  color: #1e2226;
  font-family: 'Inter-Medium';
  font-size: 16px;
  margin-bottom: 16px;
}

.Pet-protect-copay-modal > p {
  color: #3d484d;
  font-family: 'Inter-Medium';
  font-size: 14px;
  margin-bottom: 20px;
}

.Pet-protect-modal-confirm {
  background-color: #b8ede8;
  border-radius: 8px;
  border: none;
  color: #182f43;
  font-family: 'Inter-Semibold';
  margin-bottom: 24px;
  padding: 16px 20px;
  width: 90%;
}

.Pet-protect-modal-nevermind {
  color: #1e2226;
  font-family: 'Inter-Medium';
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-protect-copay-modal {
    padding: 16px;
  }

  .Pet-protect-copay-modal > h3 {
    font-size: 16px;
    margin-bottom: 16px;
  }
  
  .Pet-protect-copay-modal > h4 {
    font-size: 14px;
  }
  
  .Pet-protect-copay-modal > p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  .Pet-protect-modal-confirm {
    letter-spacing: -1.09px;
    margin-bottom: 24px;
    padding: 16px 20px;
    width: 100%;
  }
  
  .Pet-protect-modal-nevermind {
    font-size: 14px;
  }
}
