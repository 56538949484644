.Upload-files-modal-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 26px;
  width: 100%;
}

.Upload-files-modal-wrapper {
  width: 520px;
}

.Upload-files-assign-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.Upload-files-assign-list li {
  height: 20px;
  margin-bottom: 16px;
  overflow: hidden;
  padding-right: 13px;
  width: 50%;
}

.Upload-files-assign-column .Checkbox-label {
  width: 85%;
}

.Upload-files-modal-file {
  border: none;
  height: auto;
  margin: 0;
  max-height: 166px;
  max-width: 100%;
}

.Upload-files-modal-file .File-row-left {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
  padding-right: 15px;
  width: 69%;
}

.Upload-files-modal-empty {
 border: 1px solid var(--pc-color-cotton-300);
 border-radius: 6px;
 box-shadow: 0 2px 8px 0 #0000001e;
 height: 160px;
 margin-bottom: 32px;
}

.Upload-files-modal-empty img { 
  margin-bottom: 16px;
}

.Upload-modal-error {
  color: #cc0000;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 25px;
}

.Upload-files-title {
  font-family: 'Gotham-Rounded-Bold';
  margin-bottom: 8px;
  display: block;
  color: var(--pc-color-onyx-500);
}

h3.Upload-files-title {
  font-size: 14px;
}
