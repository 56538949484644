.Found-lost-pet-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
}

.Figo-lost-pet-content-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.Figo-lost-pet-found-title {
  color: #01313d;
  font-family: "Inter-Regular";
  font-size: 56px;
  font-weight: 400;
  line-height: 83.05px;
  margin-top: 70px;
  text-align: center;
}

.Figo-lost-pet-found-rounded-button-base {
  background-color: #01313d;
  border-color: #01313d;
  border-radius: 115px;
  border-style: solid;
  color: #e6fe73;
  height: 49px;
  outline: none;
  transition: all ease 0.3s;
  width: 100px;
}

.Figo-lost-pet-found-rounded-button-base:is(:hover, :focus, :active) {
  background-color: #01313d;
  border-color: #01313d !important;
  opacity: 0.5;
}

.Figo-lost-pet-found-pet-text-my-family-button {
  height: 49px;
  margin: 32px 0 72px;
  width: 294px;
}

.Figo-lost-pet-found-pet-text-my-family-text {
  font-family: "Inter-Semibold";
  font-size: 14.12px;
}

.Found-lost-pet-content-title {
  color: #01313d;
  font-family: "Inter-Regular";
  font-size: 56px;
  font-weight: 400;
  line-height: 83px;
  padding-top: 70px;
  text-align: center;
}

.Found-lost-pet-content-subtitle {
  color: #000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 16px;
  max-width: 421px;
  text-align: center;
}

.Found-lost-pet-inputs-container {
  align-items: flex-start;
  align-self: center;
  background-color: #fcf2e8;
  border-radius: 104px;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  padding: 12px;
  width: fit-content;
}

.Found-lost-pet-input-tag {
  height: 49px;
  min-height: 49px;
  min-width: 204px;
  width: 204px;
}

.Found-lost-pet-input-tag-error {
  background-color: rgba(248, 95, 37, 0.05);
  border-color: #f85f25;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  height: 41px !important;
  outline: none;
  width: 150px;
}

.Found-lost-pet-button-search {
  height: 49px;
  margin-left: 16px;
  width: 156px;
}

.Found-lost-pet-button-search:focus {
  background-color: rgba(1, 49, 61, .5) !important;
}

.Figo-lost-pet-gratitude {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  margin: 24px 0 32px 0;
  width: 580px;
}

.Figo-lost-pet-contact { 
  color: #01313d;
  font-family: "Inter-Regular";
  font-weight: 700;
  line-height: 22.4px;
  text-decoration: underline;
  transition: all 0.3s ease;
}

.Figo-lost-pet-contact:hover {
  color: #01313d;
  opacity: 0.5;
}

.Figo-lost-pet-learn-more {
  align-items: center;
  border: none;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-bottom: 72px;
  width: 249px;
}

.Figo-lost-pet-form-container {
  display: inherit;
  flex-direction: column;
  margin-bottom: 91px;
}

/* Mobile */
@media (max-width: 568px) {
  .Found-lost-pet-content-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 59.32px;
  }

  .Found-lost-pet-content-subtitle {
    font-size: 16px;
    margin: 16px 32px 0 32px;
  }

  .Found-lost-pet-input-tag {
    width: 184px;
  }

  .Found-lost-pet-button-search {
    width: 108px;
  }

  .Figo-lost-pet-form-container {
    margin-bottom: 87px;
  }

  .Figo-lost-pet-found-pet-text-my-family-button {
    width: 78%;
  }

  .Found-lost-pet-inputs-container {
    flex-wrap: wrap;
  }

  .Figo-lost-pet-gratitude {
    margin: 16px 32px 32px 32px;
    text-align: center;
    width: fit-content;
  }

  .Figo-lost-pet-learn-more {
    margin-bottom: 99px;
    width: 78%;
  }

  .Figo-lost-pet-found-title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 400;
  }
}
