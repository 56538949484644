.Pending-amount-modal-container {
  display: flex;
  max-height: 525px;
  min-height: 378px;
  width: 540px;
}

.Pending-amount-modal-title {
  color: #22435e;
  font-family: "Inter-SemiBold";
  font-size: 22px;
  margin-top: 32px;
}

.Pending-amount-modal-subtitle {
  color: #1e2226;
  font-size: 16px;
  line-height: 23px;
  margin-top: 16px;
  max-width: 424px;
  text-align: center;
}

.Pending-amount-modal-policies-container {
  margin-top: 32px;
}

.Pending-amount-due-payment-row {
  align-items: center;
  border-color: #d9dee1;
  border-style: none none solid none;
  border-width: 1px;
  display: flex;
  height: 49px;
  justify-content: space-between;
  padding: 16px 14px;
  width: 447px;
}

.Pending-amount-due-payment-row:last-child {
  border-style: none;
}

.Pending-amount-due-quantity {
  color: #7e888c;
}

.Pending-amount-modal-buttons-container {
  display: flex;
  justify-content: center;
  margin: 32px 0;
  padding: 0 64px;
}

.Pending-amount-modal-button {
  border-radius: 8px;
  border-width: 0;
  height: 60px;
  width: 148px;
}

.Pending-amount-modal-button-accept {
  background-color: #2fb3b4;
}

.Pending-amount-modal-button-cancel {
  background-color: #eceef0;
  margin-right: 16px;
}

/* Mobile */
@media (max-width: 568px) {
  .Pending-amount-modal-container {
    padding: 0 8px;
    width: 100%;
  }

  .Pending-amount-modal-policies-container {
    position: relative;
    width: 100%;
  }

  .Pending-amount-due-payment-row {
    width: 100%;
  }

  .Pending-amount-modal-button-cancel {
    margin-right: 8px;
  }
}