.Reimbursement-policy-details-container {
  display: flex;
  flex-direction: column;
}

.Reimbursement-policy-details-container > h1 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin-bottom: 16px;
}

.Reimbursement-policy-details-card {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  width: 1084px;
}

.Reimbursement-policy-details-card > img {
  margin-right: 48px;
  width: 146px;
}

.Reimbursement-policy-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.Reimbursement-policy-info-container {
  display: flex;
  flex-direction: column;
}

.Reimbursement-policy-info-container:nth-of-type(2) {
  margin: 0 0 0 24px;
}

.Reimbursement-policy-info-container:nth-of-type(3) {
  margin: 24px 0 0 0;
  width: 100%;
}

.Reimbursement-policy-info-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin-bottom: 8px;
}

.Reimbursement-policy-info-value {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 367px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Reimbursement-policy-details-card {
    width: 720px;
  }
}
