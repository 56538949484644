.Group-button-selector-items-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 40px;
  border: 1px solid var(--pc-color-onyx-100);
  box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  padding: 3.33px 0;
  width: 224px;
}

.Group-button-selector-container {
  display: flex;
  flex-direction: column;
}

.Group-button-selector-label {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
}

.Group-button-selector-icon,
.Group-button-selector-icon-active {
  height: 18px;
  margin-right: 8px;
  stroke: var(--pc-color-onyx-500);
  width: 18px;
}

.Group-button-selector-icon-active {
  stroke: var(--base-pc-color-white);
}

.Group-button-selector-button,
.Group-button-selector-button-active {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  width: 100px;
}

.Group-button-selector-button-active:disabled {
  background-color: var(--pc-color-onyx-300);
}

.Group-button-selector-button-active {
  background-color: var(--pc-color-blue-300);
  border-radius: 40px;
  height: 33.333px;
  width: 112px;
}

.Group-button-selector-button > span,
.Group-button-selector-button-active > span {
  color: var(--pc-color-onyx-500);
  font-family: 'Inter-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.408px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
}

.Group-button-selector-button-active > span {
  color: var(--base-pc-color-white);
  font-weight: 600;
}
