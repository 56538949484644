.Change-coverage-frame__container {
  display: flex;
  flex-direction: column;
  padding: 24px 48px 52px 48px;
  width: 100%;
}

.Change-coverage-frame__page-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
}

.Change-coverage-frame__page-title > h1 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin: 0;
}

.Coverage-overview-iframe > iframe {
  border: none;
  height: 100vh;
  width: 100%;
}

@media (min-width: 569px) and (max-width: 1200px) {
  .Change-coverage-frame__container {
    width: 720px;
  }
}
