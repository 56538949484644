.Powerup-info-section-container {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  width: 100%;
}

.Powerup-info-section-container > h2 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
}

.Powerup-info-card-container {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
}

.Powerup-info-grid-container {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 482px 482px;
  margin-top: 24px;
}

.Powerup-info-title-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Powerup-info-title-container > h4 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin: 0;
}

.Powerup-info-included-container {
  align-items: center;
  display: flex;
  gap: 8px;
}

.Powerup-info-included-container > img {
  height: 15.002px;
  width: 15px;
}

.Powerup-info-not-included {
  color: var(--pc-color-onyx-200) !important;
}

.Powerup-info-included-container > span,
.Powerup-info-not-included {
  color: var(--primary-text);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Powerup-info-description {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.Powerup-info-learn-more {
  align-items: center;
  background-color: transparent;
  border-color: var(--primary-text);
  border-style: none none solid none;
  border-width: 1px;
  color: var(--primary-text);
  display: flex;
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  gap: 8px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 4px 0px;
  text-align: center;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Powerup-info-grid-container {
    grid-template-columns: 100%;
  }
}
