.Claim-details-container {
  background-color: var(--base-pc-color-white);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 24px; 
  height: auto;
  margin-bottom: 24px;
  min-width: 720px;
  padding: 32px;
  width: 100%;
}

.Shimmer-wrapper {
  margin: 12px 0;
}

.Modal-add-file-container {
  align-items: center;
  flex-direction: column;
  height: 360px;
  justify-content: center;
  margin-top: 20px;
  width: 736px;
}

.Input-buttons-add-file-container {
  display: flex;
  height: 80px;
  margin-left: 25px;
  margin-top: 10px;
  width: 93%;
}

.Input-add-files {
  align-items: center;
  display: flex;
  height: 40px;
  width: 70%;
}

.Input-btts-container {
  align-items: center;
  display: flex;
  height: 65px;
  justify-content: flex-end;
  margin-top: 15px;
  width: 35%;
}

.Btn-close-blue .Btn-cancel {
  background-color: var(--pc-color-blue-300);
  height: 60px;
  margin-right: 0;
  width: 80px;
}

.Btn-close-blue .No-clicked {
  color: #fff;
  font-family: "Inter-SemiBold";
  font-size: 16px;
} 

.Input-file-add-browse {
  align-items: center;
  color: #3d484d;
  cursor: pointer;
  display: flex;
  position: relative;
  width: 110px;
}

.Input-btn {
  align-items: center;
  border-radius: 9px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.Btn-submit {
  background-color: #2fb3b4;
  color: #fff;
  font-family: "Inter-SemiBold";
  font-size: 16px;
  height: 60px;
  width: 119px;
}

.Btn-submit-blue {
  background-color: var(--pc-color-blue-300);
}

.Btn-submit-non-files {
  background-color: #b0bbbf;
  color: #fff;
  height: 60px;
  width: 119px;
}

.Btn-submit-disabled {
  pointer-events: none;
}

.Btn-cancel {
  background-color: #fff;
  color: #7e888b;
  margin-right: 48px;
}

.Claim-details-general-container-multibrand {
  display: flex;
  flex-direction: column;
  font-family: "Gotham-Regular";
  padding: 38px 48px;
}

.Claim-details-general-body {
  display: flex;
  gap: 20px;
}

.Claim-details-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Claim-details-header-content {
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
}

.Claim-details-header-title {
  color: var(--pc-color-blue-300);
  display: flex;
  font-family: "Gotham-Rounded-Bold";
  font-size: 24px;
  margin: 0;
}

.Claim-details-pet-info {
  display: flex;
  margin-top: 16px;
}

.Claim-details-pet-info > img {
  align-items: center;
  background-color: var(--pc-color-onyx-100);
  border-radius: 100px;
  border: none;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  margin-right: 8px;
  width: 40px;
}

.Claim-details-pet {
  display: flex;
  flex-direction: column;
}

.Claim-details-pet-name {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 16px;
  letter-spacing: 0.32px;
}

.Claim-details-pet-breedname {
  color: var(--pc-color-onyx-200);
  font-size: 12px;
  letter-spacing: 0.24px;
}

.Claim-details-header-company-logo {
  padding: 0 8px;
  align-self: flex-start;
  flex: 2;
  text-align: right;
}

/* Figo */
.Claim-details-header-company-1 {
  height: 52px;
  width: 135px;
}

/* PPI */
.Claim-details-header-company-2 {
  height: auto;
  width: 200px;
}

/* AKC */
.Claim-details-header-company-3 {
  height: auto;
  width: 159px;
}

/* PPI OnePack */
.Claim-details-header-company-4 {
  height: auto;
  width: 230px;
}

/* Spot */
.Claim-details-header-company-5 {
  width: auto;
}

.Claim-info-wrapper {
  display: flex;
  gap: 0 48px;
}

.Claim-info-col {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px 0;
}

.Claim-info-item {
  color: var(--base-pc-color-black);
  display: flex;
  justify-content: space-between;
}

.Claim-info-title {
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: -0.408px;
  margin: 0;
  text-wrap: nowrap;
}

.Claim-info-value {
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-weight: 400;
  text-wrap: nowrap;
}

.Claim-info-bold {
  font-family: "Gotham-Rounded";
}

.Claim-info-value-attentionNeeded {
  color: var(--pc-color-hydrant-400);
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: -0.408px;
}

.Claim-diagnosis-container {
  width: 100%;
}

.Claim-diagnosis-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Claim-details-info-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.Claim-diagnosis-dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  width: 60%;
}

.Claim-info-item-column {
  flex-direction: column;
  justify-content: flex-start;
}

.Claim-description-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Claim-description-title {
  font-family: "Gotham-Rounded";
  color: var(--base-pc-color-black);
  font-size: 16px;
  font-weight: 350;
}

.Claim-description-body {
  background-color: var(--pc-color-cotton-100);
  border-radius: 6px;
  border: 1px solid var(--pc-color-cotton-500);
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
  line-height: 20px;
  min-height: 105px;
  overflow: auto;
  padding: 16px;
  width: 100%;
}

.Claim-attachment-multibrand-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Claim-attachments-wrapper {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  min-width: 352px;
}

.Claim-attachment-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 300px;
}

.Claim-attachments-title {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-Rounded-Bold";
  font-size: 24px;
}

.Claim-attachments-items-wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 300px;
}

.Claim-attachments-item-container {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin-bottom: 19.95px;
  overflow: hidden;
  text-align: left;
}

.Claim-attachments-item-img img {
  scale: 1.1;
}

.Claim-attachments-item-text {
  display: flex;
  flex-direction: column;
}

.Claim-attachments-item-name {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 14px;
  font-weight: 350;
  letter-spacing: -0.40799999237060547px;
  line-height: 17px;
  margin-bottom: 2px;
  max-width: 245px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Claim-attachments-item-date {
  color: var(--base-pc-color-black);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.40799999237060547px;
  line-height: 17px;
  margin-bottom: 0;
  text-align: left;
}

.Claim-button-upload-attachment {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  cursor: pointer;
  display: flex;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  gap: 8px;
  letter-spacing: 2%;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
}

.Claim-attention-needed-container {
  background-color: var(--pc-color-yellow-100);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 8px;
  max-width: 1104px;
  min-height: 80px;
  padding: 16px;
  width: 100%;
}

.Claim-attention-needed-title-container {
  display: flex;
  margin-bottom: 6.5px;
  width: 100%;
}

.Claim-attention-needed-title-container h2 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin: 3px 0 0 10px;
  text-align: right;
}

.Claim-attention-needed-description-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 34px;
  width: calc(100% - 34px);
}

.Claim-attention-needed-description-container p {
  color: var(--pc-color-onyx-400);
  font-family: "Gotham-Regular";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 17px;
  margin-bottom: 0;
  text-align: left;
}

.Claim-attention-needed-description-container button {
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-400);
  cursor: pointer;
  font-family: "Gotham-medium";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 17px;
  margin: -1px 0 0;
  text-align: left;
  text-decoration: underline;
  width: 94px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Shimmer-wrapper {
    width: 98%;
  }

  .Claim-details-container {
    min-width: 100%;
    width: 100%;
  }

  .Claim-detail-general-container-multibrand {
    flex-wrap: wrap;
  }

  .Claim-details-general-body {
    display: flex;
    flex-direction: column;
  }

  .Claim-attention-needed-container {
    width: calc(100% - 16px);
  }
}
