.Policy-card-container {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  overflow: hidden;
  padding: 24px;
  width: 100%;
}

.Policy-card-data-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;
}

.Policy-card-price-container {
  align-items: center;
  display: flex;
  margin-bottom: 24px;
}

.Policy-card-price-img {
  margin-right: 41.3px;
  width: 120px;
}

.Policy-card-price-img-1 {
  height: 46px;
}

.Policy-card-price-img-1 + p {
  margin-top: -15px;
}

.Policy-card-price-img-2 {
  height: 25.13px;
  margin-top: 5px;
}

.Policy-card-price-img-3 {
  height: 48px;
  margin-top: 0px;
}

.Policy-card-price-img-4 {
  height: 44.58px;
  margin: 0 24px 0px 0;
  scale: 1.5;
  width: 146px;
}

.Policy-card-price-img-5 {
  height: 53.86px;
  margin-top: 0px;
}

.Policy-card-price-text {
  align-items: center;
  color: var(--base-pc-color-black);
  display: flex;
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-weight: 400;
  height: 100%;
  letter-spacing: 0em;
  line-height: 36px;
  margin: 0;
  text-align: left;
}

.Policy-card-price-text span {
  font-family: "Gotham-Medium";
  font-size: 24px;
  font-weight: 350;
  letter-spacing: 0em;
  line-height: 29px;
  margin-left: 8px;
  text-align: left;
}

.Policy-card-details-container {
  display: flex;
  width: 100%;
}

.Policy-card-details-item {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.Policy-card-details-item-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0em;
  line-height: 19px;
  margin-bottom: 8px;
  max-width: 155px;
  text-align: left;
  width: max-content;
}

.Policy-card-details-item-title-onePack {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0em;
  line-height: 19px;
  margin-bottom: 8px;
  text-align: left;
  width: max-content;
}
.Policy-card-details-item-content {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}
.Policy-card-details-item-content-onePack {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
  max-width: 484px;
}
.Policy-card-button-container {
  display: flex;
  justify-content: flex-end;
}

.Policy-card-button-details {
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--pc-color-blue-300);
  border: none;
  color: var(--pc-color-blue-300);
  cursor: pointer;
  display: flex;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 27px;
  justify-content: space-between;
  letter-spacing: 2%;
  line-height: 16px;
  padding-bottom: 2px;
  padding: 4px 0 0;
  text-align: center;
  text-transform: capitalize;
  width: 100px;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Policy-card-details-container {
    flex-wrap: wrap;
  }
}

@media (min-width: 569px) and (max-width: 1037px) {
  .Policy-card-details-item:last-child {
    margin-top: 32px;
    width: 100%;
  }
}

