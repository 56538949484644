.My-vet-card-item-container {
  align-items: flex-start;
  align-self: stretch;
  background: var(--base-pc-color-white);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0 0;
  min-width: 384px;
  opacity: 1;
  padding: 24px;
  position: relative;
  width: auto;
}

.My-vet-card-item-first-row {
  align-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 80px;
}

.My-vet-card-item-first-row > span {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Book";
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.My-vet-card-item-email {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-Book";
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.My-vet-card-image-container {
  align-items: center;
  border-radius: 50%;
  height: 65px;
  justify-content: center;
  width: 65px;
}

.My-vet-card-no-img {
  height: 65px;
  margin-top: 3px;
  width: 65px;
}

.My-vet-card-info-address {
  padding-right: 16px !important;
}

.My-vet-card-img {
  border-radius: 50%;
  height: 65px;
  width: 65px;
}

.Remove-vet {
  background-color: transparent;
  border: none;
  height: fit-content;
  margin: 10px 16px 0 0;
  padding: 0;
  position: absolute;
  right: 0;
  width: fit-content;
}

.My-vet-card-item-info-container {
  align-items: center;
  display: flex;
  gap: 45px;
  justify-content: space-between;
}

.My-vet-card-info-name {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
}

.My-vet-card-info-address {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
}

.My-vet-card-info-telephone {
  box-sizing: border-box;
  flex-grow: 1;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  width: 155px;
}

.Remove-vet-container {
  align-items: center;
  justify-content: center;
}

.My-vet-card-remove-icon {
  height: 18px;
  width: 18px;
}

.My-vet-card-item-info-container > p,
.My-vet-card-info-address > p {
  margin: 0;
}

.My-vet-card-remove-button {
  align-items: center;
  border-radius: 5px;
  color: var(--base-pc-color-white);
  display: flex;
  height: 30px;
  justify-content: flex-end;
  margin-bottom: 16px;
  padding: 0 !important;
  position: absolute;
  right: 24px;
  top: 30px;
  width: 18px;
}

@media (max-width: 1295px) {
  .My-vet-card-item-container {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
  }

  .My-vet-card-item-info-container {
    gap: 22px;
    min-width: 436px;
  }

  .My-vet-card-info-telephone {
    width: 150px;
  }

  .My-vet-card-info-address {
    width: 200px;
  }

  .My-vet-card-remove-button {
    align-items: end;
    align-self: center;
    right: 24px;
    top: inherit;
  }
}

@media (max-width: 900px) {
  .My-vet-card-item-info-container {
    max-width: 90%;
  }

  .My-vet-card-info-address {
    font-size: 15px;
    width: 150px;
  }
}

@media (max-width: 650px) {
  .My-vet-card-item-info-container {
    max-width: 90%;
  }

  .My-vet-card-info-name {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .My-vet-card-item-info-container {
    min-width: 300px;
  }

  .My-vet-card-remove-button {
    right: 14px;
  }

  .My-vet-card-info-telephone {
    font-size: 15px;
  }

  .My-vet-card-info-address {
    font-size: 15px;
    width: 50px;
  }
}