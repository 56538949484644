.Pet-selector-multi-header {
  align-content: center;
  align-items: center;
  border-color: #d9dee1;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline-color: #2fb3b4;
  width: 100%;
}

.Pet-selector-multi-list-overlayed-container {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

.Pet-selector-multi-list-hidden {
  opacity: 0;
  pointer-events: none;
  top: -10px;
}

.Pet-selector-multi-list-item-container {
  align-items: center;
  display: flex;
  justify-content: center;
  outline-color: #2fb3b4;
  width: 100%;
}

.Pet-selector-multi-list-item-old {
  align-items: center;
  background-color: #fff;
  border-color: #fff;
  border-radius: 4px;
  color: #3d484d;
  cursor: pointer;
  display: flex;
  font-family: "Inter-Regular";
  font-size: 14px;
  height: 36px;
  justify-content: flex-start;
  margin: 4px 8px;
  outline-color: #2fb3b4;
  padding: 8px 4px 8px 16px;
  text-align: left;
  width: 100%;
}

.Pet-selector-multi-list-item-old:hover {
  background-color: rgba(184, 237, 232, 0.5);
  border-color: rgba(184, 237, 232, 0.5);
  outline-color: rgba(184, 237, 232, 0.5);
}

.Pet-selector-multi-list-item-selected {
  background-color: #b8ede8;
  border-color: #b8ede8;
}

.Pet-selector-multi-arrow-closed {
  height: 10px;
  transition: all 0.3s ease;
  width: 16px;
}

.Pet-selector-multi-arrow-open {
  transform: rotate(180deg);
}

.Pet-selector-multi-arrow-container {
  cursor: pointer;
  margin-right: 16px;
  outline: 0;
}

.Pet-selector-pets-selected {
  border-color: #7c52bf;
  background-color: #7c52bf1a;
}

.Pet-selector-new-main-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(112, 144, 176, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 54px;
  min-width: 416px;
  padding: 12px 16px;
  width: 416px;
}

.Full-width {
  min-width: auto;
  width: 100%;
}

.Pet-selector-new-row {
  align-items: center;
  display: flex;
}

.Pet-selector-new-separator {
  background-color: var(--pc-color-cotton-300);
  border-radius: 100px;
  height: 48px;
  margin-right: 32px;
  width: 2px;
}

.Pet-selector-new-reset-label {
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  cursor: pointer;
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin-right: 16px;
}

.Pet-selector-new-container {
  background: var(--pc-color-blue-100);
  border-color: var(--pc-color-blue-300);
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: 41px;
  position: relative;
  width: 109px;
}

.Pet-selector-new-header {
  align-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline-color: #2fb3b4;
  width: 100%;
}

.Pet-selector-new-selected-item {
  align-items: center;
  display: flex;
  pointer-events: none;
  text-indent: 16px;
  width: 100%;
}

.Pet-selector-new-selected-item > p {
  color: var(--base-pc-color-black);
  font-family: "Gotham-medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.28px;
  line-height: normal;
  margin: 0;
}

.Pet-selector-new-arrow-closed {
  height: 5px;
  transition: all 0.3s ease;
  width: 10px;
}

.Pet-selector-new-arrow-open {
  transform: rotate(180deg);
}

.Pet-selector-multi-list {
  background-color: var(--base-pc-color-white);
  border-color: #eceef0;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .08);
  display: flex;
  flex-direction: column;
  max-height: 172px;
  overflow-y: auto;
  padding-bottom: 4px;
  position: absolute;
  top: 45px;
  transition: all 0.3s ease;
  width: 300px;
  z-index: 2;
}

.Pet-selector-multi-list > ul {
  margin: 0px;
  padding: 0px;
}

.Pet-selector-multi-list-item {
  align-items: center;
  background-color: #fff;
  border-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: 12px 24px;
  width: 100%;
}

.Pet-selector-multi-list-item > span {
  color: var(--charcoal-300);
  font-family: "Gotham-regular";
  font-size: 16px;
  margin-left: 11px;
}

.Pet-selector-multi-list-item:hover {
  background-color: var(--pc-color-blue-100);
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Pet-selector-new-main-container  {
    margin: 0 0 22px 0;
    width: 100%;
  }
}
