.Pet-selector-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0 32px;
  width: 100%;
}

.Pet-selector-pet-non-selected {
  max-width: 324px;
  max-height: 200px;
}

.Pet-selector-pet-selected,
.Pet-selector-pet-selected:active,
.Pet-selector-pet-selected:focus {
  border-color: var(--pc-color-blue-300);
  box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.16), 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  max-height: 200px;
  max-width: 324px;
  outline-color: var(--pc-color-blue-300);
}

/* Tablet - Medium */
@media (max-width: 801px) {
  .Pet-selector-container {
    padding: 0 8px;
  }
}

/* Tablet - other */
@media (max-width: 753px) {
  .Pet-selector-container {
    justify-content: center;
  }
}
