.Provider-selector {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  max-width: 90%;
  padding: 24px;
  width: 520px;
}

.Provider-selector-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 6px;
}

.Provider-selector-desc {
  color: var(--pc-color-onyx-300);
  font-family: "Gotham-Regular";
  font-size: 16px;
  margin-bottom: 24px;
}

.Provider-selector-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 0;
  width: 100%;
}

.Provider-selector-list button {
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--pc-color-cotton-300);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
}

.Provider-selector-go-icon {
  height: 24px;
  object-fit: contain;
  transform: rotate(180deg);
}

/* Figo */
.Provider-selector-1,
/* AKC */
.Provider-selector-3,
/* PPI OnePack */
.Provider-selector-4,
/* Spot */
.Provider-selector-5 {
  height: 56px;
  width: auto;
}

/* PPI */
.Provider-selector-2 {
  height: 41px;
  width: 200px;
}
