.File-claimbot-claim-submitted-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.File-claimbot-claim-submitted-container > img {
  height: 60px;
  width: 60px;
}

.File-claimbot-claim-submitted-container > h2 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
  margin: 24px 0 0 0;
}

.File-claimbot-claim-submitted-container > span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
}

.File-claimbot-claim-submitted-container > span > a {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}