.One-inc-add-payment-button {
  align-items: center;
  background-color: #2fb3b4;
  border-radius: 8px;
  color: #fff;
  display: flex;
  font-family: 'Inter-SemiBold';
  height: 48px;
  justify-content: center;
  padding: 0 22px;
}

.One-inc-add-payment-button:hover {
  cursor: pointer;
}
 
.One-inc-add-payment-button img {
  height: 25px;
  margin-right: 8px;
  width: 33px;
}

.One-modal-details {
  align-items: center;
  display: flex;
}

.One-modal-details-image  {
  height: 36px;
}

.One-modal-details div {
  display: flex;
  flex-direction: column;
  margin: 0 12px;
}

.One-modal-details-button {
  background-color: transparent;
  border: none;
  color: #2fb3b4;
  font-family: 'Inter-SemiBold';
  font-size: 14px;
  margin: 32px 0;
}

.One-modal-details strong {
  color: #3d484d;
  font-family: 'Inter-Regular';
  text-align: left;
}

.One-modal-last-digits {
  color: #697980;
  font-family: 'Inter-Regular';
}

.One-modal-details div span:last-child {
  color: #697980;
  font-family: 'Inter-Regular';
  font-size: 12px;
}

.One-modal-button-select {
  background: none;
  border: 2px solid #fff;
  height: 50px;
  margin-bottom: 10px;
}

.One-modal-button-select:hover {
  background: #b8ede8;
  border-radius: 9px;
  border: 2px solid #d9dee1;
  cursor: pointer;
  opacity: 0.9;
}

.One-modal-button-select:focus {
  border: 2px solid #d9dee1;
}

.One-inc-radio-button-container {
  align-items: center;
  display: flex;
}

.One-inc-radio-button-container input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #eceef0;
  border-radius: 100%;
  border: 2px solid #eceef0;
  cursor: pointer;
  height: 20px !important;
  margin-right: 10px;
  min-width: 20px;
  position: relative;
  top: 4px;
  width: 20px;
}

.One-inc-radio-button-container input:checked {
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    background: no-repeat center url("../../assets/check.svg");
    border: transparent !important;
    height: 20px;
    min-width: 20px;
    opacity: 1;
    width: 20px;
}

.One-inc-radio-button-container input:focus {
  outline: none;
}

.One-inc-radio-button-container label {
  color: #3d484d;
  cursor: pointer;
  font-family: 'Inter-Medium';
  font-size: 17px;
  margin-bottom: 0;
}

.One-inc-button-loading {
  height: 30px;
  position: relative;
  width: 30px;
}

.One-inc-button-loading div {
  left: -47px;
  position: absolute;
}

.One-inc-payment-title {
  align-items: center;
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 20px;
  justify-content: center;
  margin: 32px 0 16px 0;
  min-width: 288px;
  text-align: center;
}

.One-inc-payment-message {
  color: #1e2226;
  font-family: 'Inter-Regular';
  font-size: 14px;
  text-align: center;
}

.One-inc-payment-button-cancel {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  height: 48px;
  justify-content: center;
  width: 100%;
}

.One-inc-payment-button-cancel-text {
  color: #7e888c;
  font-family: "Inter-SemiBold";
  font-size: 16px;
}

.One-inc-payment-button-save {
  align-items: center;
  background-color: #2fb3b4 !important;
  border-radius: 8px;
  height: 48px;
  justify-content: center;
  width: 100%;
}

.One-inc-payment-button-save:disabled {
  background-color: #b0bbbf !important;
  border-color: #b0bbbf !important;
}

.One-inc-payment-button-save-text {
  color: #fff;
  font-family: "Inter-SemiBold";
  font-size: 16px;
}

.One-inc-payment-modal {
  max-width: 360px;
}

.One-inc-payment-modal > div:first-child {
  margin: 0 32px 16px 32px;
}
