.Filter-claims-menu-item:hover {
  background-color: var(--pc-color-blue-100);
  border: none;
  outline: none;
}

.Filter-claims-menu-item:active {
  background-color: var(--pc-color-blue-100);
  border: none;
  outline: none;
}

.Filter-claims-menu-item:focus {
  background-color: var(--pc-color-blue-100);
  border: none;
  outline: none;
}

.Filter-claims-menu-item {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 8px 0px;
}

.Filter-claims-menu-item:hover {
  background-color: var(--pc-color-blue-100) !important;
}

.Filter-claims-menu-button-label {
  align-items: flex-start;
  color: var(--pc-color-onyx-500);
  display: flex;
  flex: 1 0 0;
  gap: 8px;
}

.Filter-claims-menu-button-container {
  background-color: transparent;
  border: none;
  position: relative;
  white-space: pre;
}

.Filter-claims-menu-button-container:focus{
  border: none;
  outline: none;
}

.Filter-claims-icon-box {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
}

.Filter-claims-checkbox {
  position: relative;
}

.Filter-claims-menu-button-span {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 2px 10px;
}

.Filter-claims-menu-container {
  background: transparent;
  border-color: transparent;
  cursor: pointer;
  display: flex;
}

.Filter-claims-menu-container:disabled {
  opacity: 0.5;
  cursor: default;
}

.Filter-claims-menu-main-container {
  max-height: 24px;
}

.Filter-claims-menu {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eceef0;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  left: 36px;
  padding: 8px 0px;
  position: absolute;
  width: 250px;
}

.Filter-claims-menu-button {
  align-items: center;
  background: transparent;
  border-radius: 8px;
  border: 0px;
  display: flex;
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  justify-content: space-between;
  line-height: normal;
  width: 100%;
}

.Filter-claims-text {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  margin-right: 10px;
  text-align: center;
  text-transform: capitalize;
  white-space: pre;
}

.Filter-claims-menu-title {
  color: var(--pc-color-onyx-200);
  cursor: initial;
  font-family: 'Gotham-Medium';
  font-size: 12px;
  font-style: normal;  font-weight: 600;
  line-height: 16px;
  margin: 6px 15px;
  text-align: left;
}

.Filter-claims-menu-separator-line {
  background-color: var(--cotton-grey-pc-color-cotton-100, #F9FAFA);
  height: 1px;
  margin: 4px 0px;
  top: 32px;
  width: 100%;
}

.Filter-claims-text-icon {
  vertical-align: middle;
}

.Filter-claims-inner-container {
  color: var(--pc-color-blue-300);
  display: flex;
  margin-left: 8px;
}

.Filter-claims-content {
  display: inline-flex;
  position: relative;
}

.Filter-claims-content::after {
  background-color: var(--pc-color-blue-300);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}

/* Tablet */
@media (max-width: 1200px) {
  .Filter-claims-menu {
    right: 0;
    top: 8px;
  }
}
