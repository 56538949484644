.No-claims-placeholder-container {
  display: flex;
  flex-direction: column;
  padding: 72px 0 559px 0;
}

.No-claims-placeholder-container > h3 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin: 0;
  text-align: center;
}

.No-claims-placeholder-container > span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.12px;
  line-height: normal;
  margin-top: 16px;
  text-align: center;
}

/* Tablet - Medium */
@media (max-width: 770px) {
  .No-claims-placeholder-container {
    padding-bottom: 500px;
  }
}
