.Contact-us-modal-container {
  align-items: flex-start;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  min-width: 520px;
  padding: 24px;
  position: relative;
}

.Contact-us-modal-close-button {
  background-color: var(--base-pc-color-white);
  border: none;
  position: absolute;
  right: 32px;
  top: 24px;
}

.Contact-us-modal-back-button {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-color: var(--pc-color-blue-300);
  border-style: none none solid none;
  border-width: 1px;
  display: flex;
  justify-content: center;
  min-height: 24px;
  padding: 0;
  position: absolute;
}

.Contact-us-modal-back-button > img {
  height: 16px;
  margin-right: 8px;
}

.Contact-us-modal-back-button > span {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}

.Contact-us-modal-title {
  color: var(--charcoal-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
}

.Contact-us-modal-sub-title {
  color: var(--charcoal-300);
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Contact-us-modal-figo-icon {
  height: 56px;
  width: 146px;
}

.Contact-us-modal-ppi-icon {
  height: 41px;
  width: 200px;
}

.Contact-us-modal-akc-icon {
  height: 56px;
  width: 117px;
}

.Contact-us-figo-logo {
  height: 56px;
  width: 146px;
}

.Contact-us-akc-logo {
  height: 74px;
  width: 155px;
}


.Contact-us-ppi-logo {
  height: 41px;
  width: 200px;
}

.Contact-us-modal-header-container {
 display: flex;
 flex-direction: column;
 width: 100%;
}

.Contact-us-modal-header-title {
  color: var(--charcoal-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-weight: 350;
  letter-spacing: -0.005em;
  line-height: 28px;
  margin-left: 8px;
  text-align: left;
}

.Contact-us-modal-header-description {
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 19px;
  margin: 0 0 16px 9px;
  text-align: left;
}

.Contact-us-modal-header-description:empty {
  margin-bottom: 0;
}

.Contact-us-modal-ppi-one-pack-icon,
.Contact-us-ppi-one-pack-logo {
  height: auto;
  width: 177px;
  margin-left: -10px;
}

.Margin-top-8 {
  margin-top: 8px;
}

.Margin-top-15 {
  margin-top: 15px;
}

.Margin-top-16 {
  margin-top: 16px;
}

.Margin-top-32 {
  margin-top: 32px;
}

.Margin-right-33 {
  margin-right: 33px;
}
