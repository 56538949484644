.Footer-found-lost-pet-container {
  align-items: center;
  background-color: #01313d;
  display: flex;
  height: 143px;
  justify-content: space-between;
  padding: 0 43px;
  width: 100%;
}

.Footer-found-lost-pet-text {
  color: #fcf2e8;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.Footer-found-lost-pet-contact-figo {
  color: #fcf2e8;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  transition: all 0.3s ease;
}

.Footer-found-lost-pet-contact-figo:hover {
  color: #fcf2e8;
  opacity: 0.5;
}

.Footer-found-lost-pet-copyright-container {
  padding-right: 15px;
  text-align: end;
}

.Footer-found-lost-pet-copyright-text {
  color: #fcf2e8;
  font-family: "Inter-Regular";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
}

.Found-lost-pet-crazy-pattern-background {
  height: 75px;
  width: 100%;
}

/* Mobile */
@media (orientation: portrait) and (max-width: 568px) {
  .Footer-found-lost-pet-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  .Footer-found-lost-pet-copyright-container {
    margin: 16px 0 0 0;
    padding: 0;
    text-align: center;
  }

  .Footer-found-lost-pet-copyright-text {
    font-size: 10px;
    line-height: 12.1px;
  }
}
