.Change-coverage-screen-preview-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 32px 48px 48px 48px;
  width: 100%;
}

.Change-coverage-screen-preview-container > h3 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin: 0;
}

.Change-coverage-screen-price-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 24px 0 16px 0;
}

.Change-coverage-screen-price,
.Change-coverage-screen-price-decimals {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded-Bold";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Change-coverage-screen-price-decimals {
  align-self: flex-start;
  font-size: 25px;
}

.Change-coverage-screen-price-month {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin-left: 5px;
}

.Change-coverage-screen-preview-dates {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 48px;
}

.Change-coverage-screen-preview-dates > b {
  color: rgba(29, 36, 51, 0.80);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Change-coverage-screen-price-container > h3 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 28px;
  letter-spacing: -0.12px;
}

.Change-coverage-screen-benefits-container {
  display: flex;
  flex-direction: row;
  gap: 77px;
  margin: 24px 0 48px 0;
}

.Change-coverage-screen-benefit-title,
.Change-coverage-screen-benefit-value {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Change-coverage-screen-benefit-value {
  font-family: "Gotham-Regular";
  font-weight: 400;
  margin-top: 8px;
}

.Change-coverage-preview-pet-protect-container {
  margin-bottom: 48px;
}

.Change-coverage-preview-powerup-description {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px 0 24px 0;
}

.Change-coverage-preview-powerup-powerups-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 48px;
  width: 100%;
}

.Change-coverage-preview-want-changes-text {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
}

.Change-coverage-preview-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 11px;
  justify-content: center;
  margin-top: 48px;
  width: 100%;
}


.Change-coverage-preview-change-button,
.Change-coverage-preview-keep-button {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 20px;
  border: 1px solid var(--pc-border-radius-xl);
  color: var(--pc-color-onyx-500);
  display: flex;
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  gap: 8px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 12px 16px;
  text-align: center;
  width: 249.688px;
}

.Change-coverage-preview-change-button,
.Change-coverage-preview-change-button:disabled {
  background-color: var(--figo-primary-deep-blue-100);
  color: var(--base-pc-color-white);
}

.Change-coverage-preview-change-button:hover:enabled,
.Change-coverage-preview-change-button:active {
  background-color: var(--figo-primary-deep-blue-100);
  color: var(--base-pc-color-white);
  opacity: 0.8;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Change-coverage-preview-powerup-powerups-container {
    flex-direction: column;
  }
}

/* Mobile */
@media (max-width: 569px) {
  .Change-coverage-screen-preview-container {
    padding: 32px 24px 48px 24px;
  }

  .Change-coverage-screen-preview-container > h3,
  .Change-coverage-screen-preview-dates,
  .Change-coverage-screen-benefit-value,
  .Change-coverage-preview-powerup-description,
  .Change-coverage-preview-want-changes-text {
    text-align: left;
  }
  
  .Change-coverage-screen-benefits-container {
    flex-wrap: wrap;
    gap: 24px;
  }

  .Change-coverage-preview-powerup-powerups-container {
    flex-wrap: wrap;
  }

  .Change-coverage-preview-buttons-container {
    flex-direction: column-reverse;
  }

  .Change-coverage-preview-keep-button,
  .Change-coverage-preview-change-button {
    width: 100%;
  }
}
