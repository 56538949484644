.Pet-tag-modal-main-container {
  align-items: center;
  background: var(--base-pc-color-white);
  border-radius: 16px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  min-height: 698px;
  padding: 24px;
  width: 520px;
}

.Pet-tag-modal-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Pet-tag-modal-main-container h3 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin: 0;
}

.Pet-tag-modal-close-icon-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 32px;
  justify-content: center;
  padding: 8px;
  width: 32px;
}

.Pet-tag-modal-close-icon {
  height: 16px;
  pointer-events: none;
  width: 16px;
}

.Pet-tag-modal-description {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
  white-space: pre-wrap;
  width: 100%;
}

.Pet-tag-modal-content-container {
  width: 100%;
}

.Pet-tag-modal-tag-input-container {
  margin-bottom: 39px;
}

.Pet-tag-modal-finder-input { 
  min-height: 72px;
}

.Pet-tag-modal-row-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 39px;
}

.Pet-tag-modal-phone-input {
  margin: 25px 0 0 24px;
}

.Pet-tag-modal-contact-input {
  width: 224px;
}

.Pet-tag-modal-phone-error {
  margin: 8px 0 0 20px;
}

.Pet-tag-modal-footer-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.Pet-tag-modal-footer-container-margin {
  margin-top: 20px;
}
