.Billing-payments-information-main-container {
  display: flex;
  flex-direction: column;
}

.Billing-payments-gap-8 {
  gap: 8px;
}

.Billing-payments-information-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Billing-payments-information-double-row {
  flex-direction: row;
  gap: 32px;
  margin-top: 36px;
}

.Billing-payments-information-title {
  font-family: "Gotham-Rounded";
  font-size: 20px;
  line-height: normal;
  margin: 0;
}

.Billing-payments-information-row-title {
  font-family: "Gotham-Medium";
  font-size: 16px;
  margin: 0;
}

.Billing-payments-information-row-description {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-book";
  font-size: 16px;
}
