.Home-main-container {
  background-color: var(--pc-color-cotton-100);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 48px 48px 48px;
}

.Home-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.54px;
  line-height: 44px;
}

.Home-sub-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.54px;
  line-height: 100%;
  margin-top: 4px;
  text-transform: uppercase;
}
