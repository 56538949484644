.Submit-success-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Submit-portrait {
  position: relative;
  width: 100%;
}

.Submit-background {
  border-radius: 16px 16px 0 0;
  width: 100%;
}

.Submit-success-container > button {
  background-color: #2fb3b4;
  border-radius: 8px;
  border: none;
  color: #fff;
  font: 16px 'Inter-SemiBold';
  height: 60px;
  margin-bottom: 86px;
  width: 201px;
}

.Check-button-plus {
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.Submit-title-container {
  margin: 40px 0 44px 0;
  width: fit-content;
}

.Submit-title {
  color: #22435e;
  font: 16px "Inter-SemiBold";
}

.Submit-almost-done {
  color: #22435e;
  font: 22px "Inter-Bold";
}

.Submit-message-container {
  color: #3d484d;
  font: 16px "Inter-Regular";
  margin: 44px 32px 60px 32px;
  text-align: center;
  width: 256px;
}

.Submit-date {
  font: 16px "Inter-SemiBold";
}

.Submit-message {
  color: #3d484d;
  font: 16px "Inter-Regular";
  text-align: center;
}

.Submit-contact-us {
  color: #2fb3b4;
  font: 16px "Inter-SemiBold";
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Submit-success-container {
    height: fit-content;
    margin: -80px 0;
    width: 83%;
  }

  .Submit-balloon {
    position: absolute;
    right: 63.42px;
    top: -56px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Submit-success-container {
    margin-top: -30px;
    width: 100%;
  }

  .Submit-balloon {
    position: absolute;
    right: 0px;
    top: -15px;
    z-index: 3;
  }

  .Submit-background {
    border-radius: 0;
  }

  .Submit-title-container {
    margin: 56px 0 47px 0;
  }

  .Submit-message-container {
    margin: 41px 32px 60px 32px;
  }
}
