.My-pets-profile-info-card-content {
  align-self: center;
  background-color: var(--pc-color-cotton-300);
  border-radius: 8px;
  border-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.My-pets-profile-info-title-button {
  display: flex;
  justify-content: space-between;
}

.My-pets-profile-info-card-information {
  position: relative;
}

.My-pets-profile-info-card-box {
  justify-content: space-between;
}

.My-pets-profile-info-card-birthday-container {
  align-items: center;
  align-self: flex-end;
  background-color: #f8f8f8;
  border-radius: 5px;
  height: 48px;
  justify-content: center;
  margin: 28.46px 17px 0 0;
  width: 149px;
}

.My-pets-profile-info-card-birthday-container div {
  display: flex;
  margin-left: 7.5px;
}

.My-pets-profile-info-card-birthday-label {
  color: #7e888c;
  font-family: "Inter-Medium";
  font-size: 10px;
  line-height: 10px;
}

.My-pets-profile-info-card-birthday-value {
  color: #22435e;
  font-family: "Inter-Medium";
  font-size: 13px;
}

.My-pets-profile-info-card-pet-details-bio {
  align-items: baseline;
  display: flex;
  justify-content: flex-start;
  margin: 24px 0;
}

.My-pets-profile-info-card-pet-details-bio-box {
  align-items: baseline;
  display: flex;
  justify-content: flex-start;
}

.My-pets-profile-info-card-pet-details-bio-no-family-members {
  align-items: baseline;
  margin: 24px 0 32px 0;
  max-width: 545px;
}

.My-pets-profile-info-card-pet-details-bio p {
  color: #000;
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 8px;
  max-width: 550px;
}

.My-pets-profile-info-card-pet-details-bio-no-family-members p {
  color: #3d484d;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  margin: 0 13px;
}

.My-pets-profile-info-card-pet-details {
  padding: 16px 0 0 16px;
  width: -webkit-fill-available;
}

.My-pets-profile-info-title-button > h1 {
  color: var(--pc-color-blue-300);
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  max-width: 416px;
}

.My-pets-profile-info-card-pet-details-row span {
  border-left: 1.5px solid var(--pc-color-blue-300);
  color: #1e2226;
  font-family: "Gotham-Regular";
  font-size: 18px;
  font-weight: 700;
  padding: 0 10px;
}

.My-pets-profile-info-card-pet-details-row span:first-child {
  border-left: none;
  padding-left: 0;
}

.My-pets-profile-info-card-image {
  border: 2.39px solid #fff;
  height: 180px;
  margin-top: -83px;
  width: 180px;
}

.My-pets-profile-info-card-image-container,
.Pet-profile-image-container {
  align-items: center;
  margin-left: 55px;
  max-height: 101px;
  position: relative;
}

.My-pets-profile-info-card-image-container > h1 {
  color: #fff;
  font-family: "Inter-Bold";
  font-size: 47.74px;
  line-height: normal;
  position: absolute;
  top: -32px;
}

.My-pets-item-card-image > h1 {
  color: var(--primary-700);
  font-family: 'Gotham-Rounded';
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 72px;
  position: absolute;
  text-align: center;
  top: -36px;
  left: 42px;
}


.My-pets-profile-info-card-image-container > .My-pets-item-card-image-button {
  background: none;
  border: none;
  color: #b8ede8;
  font-family: "Inter-Bold";
  font-size: 19.1px;
  position: absolute;
  top: 35.37px;
}

.My-pets-item-card-image > .My-pets-item-card-image-button {
  background: none;
  border: none;
  color: var(--primary-700);
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 30px;
  left: 17px;
}

.My-pets-profile-info-card-add-photo-button {
  background-color: transparent;
  background-image: url('../../../assets/myPets/camera-my-pets.svg');
  height: 41px;
  width: 41px;
}

.My-pets-profile-info-card-edit {
  align-items: center;
  position: absolute;
  right: 16px;
  top: 16px;
}

.My-pets-profile-info-card-edit-button {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 0;
  color: #fff;
  display: flex;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  height: 40px;
  justify-content: center;
  margin: 0 48px 14px 0;
  padding: 0 20px;
  white-space: nowrap;
}

.My-pets-profile-info-card-edit-icon {
  height: 16px;
  margin: 0 8px 1px 0;
  width: 16px;
}

.My-pets-profile-info-card-edit img {
  width: 23px;
  height: auto;
}

.My-pets-profile-info-card-edit span {
  color: #3d484d;
  flex-wrap: nowrap;
  font-family: "Inter-Medium";
  font-size: 14px;
  margin: 0 8px;
}

.My-pets-profile-info-card-edit button {
  background-color: inherit;
  border: none;
  color: #2fb3b4;
  display: inline-block;
  font-family: "Gotham-Regular";
  font-size: 16px;
  padding: 14px 28px;
  padding: 6px 12px;
}

.My-pets-profile-info-card-family-members {
  margin: 32px 0;
}

.My-pets-profile-info-card-family-members > div {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 0 -8px;
  max-width: 555px;
}

.My-pets-profile-info-card-family-members-image {
  border-radius: 50%;
  height: 64px;
  width: 64px;
}

.My-pets-family-members-container {
  margin: 5px 4px;
  position: relative;
}


.My-pets-profile-info-card-family-members > span {
  color: var(--navy);
  font-family: "Gotham-Regular";
  font-size: 16px;
}

.My-pets-family-members-container > h1 {
  color: #fff;
  font-family: "Inter-Bold";
  font-size: 25px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25%;
  width: 100%;
}

.My-pets-family-members-container > button {
  display: none;
}

.Pet-profile-image {
  background-color: var(--pc-color-leaf-200);
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .My-pets-profile-info-card-family-members > div {
    max-width: 385px;
  }

  .My-pets-profile-info-card-pet-details > h1 {
    max-width: 230px;
  }

  .My-pets-profile-info-card-pet-details {
    padding: 20px 0 0 20px;
  }

  .My-pets-profile-info-card-edit-button {
    margin: 0px 24px 14px 0px;
  }
}

@media (max-width: 768px) {
  .My-pets-profile-info-card-image {
    max-height: 30vw;
    max-width: 30vw;
  }

  .My-pets-profile-info-card-pet-details-bio-box {
    max-width: 420px;
  }

  .My-pets-profile-info-card-pet-details-bio {
    justify-content: right;
    margin-right: 50px;
  }
}

@media (max-width: 600px) {
  .My-pets-profile-info-card-pet-details-row {
    width: 95%;
  }

  .My-pets-profile-info-card-pet-details-bio-box {
    max-width: 300px;
  }

  .My-pets-profile-info-card-pet-details-bio {
    justify-content: right;
    margin-right: 50px;
  }
}
