.Reimbursement-edit-menu-container {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  bottom: -30px;
  box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.16), 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 24px;
  transition: all .2s ease;
  width: 250px;
  z-index: 1;
}

.Reimbursement-edit-menu-container-hidden {
  display: none;
}

.Reimbursement-edit-menu-display-upwards {
  bottom: 60px;
}

.Reimbursement-edit-button {
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--pc-color-blue-300);
  border-style: none none solid none;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 4px 0px;
}

.Reimbursement-edit-button > span {
  color: var(--pc-color-blue-300);
  text-align: center;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: 0.32px;
}

.Reimbursement-edit-menu-container ul {
  margin: 0;
  padding: 8px 0;
  width: 100%;
}

.Reimbursement-edit-menu-item-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  padding: 10px 16px;
}

.Reimbursement-edit-menu-item-container:hover {
  background-color: var(--pc-color-cotton-100);
  cursor: pointer;
}

.Reimbursement-edit-menu-item-container img {
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.Reimbursement-edit-menu-item-container span {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Reimbursement-edit-menu-overlay-container {
  z-index: -1 !important;
}
