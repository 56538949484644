.Cancel-policy-modal-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 280px;
  justify-content: flex-start;
  padding: 32px;
  width: 381px;
}

.Cancel-policy-modal-title {
  color: #22435e;
  font-family: "Inter-SemiBold";
  font-size: 23px;
}

.Cancel-policy-modal-content {
  color: #3d484d;
  font-size: 16px;
  margin-top: 16px;
  text-align: center;
}

.Cancel-policy-modal-content-green {
  color: #2fb3b4;
}

.Cancel-policy-modal-close-button {
  background-color: #2fb3b4;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-family: "Inter-SemiBold";
  font-size: 16px;
  height: 50px;
  margin-top: 50px;
  min-height: 50px;
  outline: none !important;
  text-align: center;
  width: 100px;
}
