.Docs-loading-container {
  padding-top: 211px;
  width: 1120px;
}

.Docs-table-container-old th, td {
  border-width: 1px;
  height: 67px;
}

.Docs-table-container-old th {
  color: #22435e;
  font-family: "Inter-SemiBold";
  font-size: 18px;
  height: 44px;
  vertical-align: top;
}

.Docs-table-container-old thead {
  border-color: var(--pc-color-cotton-300);
  border-style: none none solid none;
  border-width: 1px;
}

.Docs-table-container-old td {
  color: #3d484d;
  font-family: "Inter-Medium";
  font-size: 16px;
}

/* re-design */
.Docs-table-container th, td {
  border-width: 1px;
  height: 67px;
}

.Docs-table-container th {
  color: var(--base-pc-color-black);
  font-family: "Gotham-medium";
  font-size: 16px;
  height: 59px;
  vertical-align: middle;
}

.Docs-table-container thead {
  border-color: var(--pc-color-cotton-300);
  border-style: none none solid none;
  border-width: 1px;
}

.Docs-table-container td {
  color: var(--charcoal-300);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.408px;
  line-height: normal;
}

.Docs-table-container-full-width {
  width: 100%;
}