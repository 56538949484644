.Checkbox-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 25px;
  justify-content: center;
}

.Checkbox-container:focus {
  border: none;
  outline: none;
}

.Checkbox-label {
  color: #3d484d;
  font-size: 14px;
  width: 133px;
}

.Checkbox-common-container {
  position: relative;
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  margin: 0;
  cursor: pointer;
}

.Checkbox-common-container input[type="checkbox"] {
  background-color: transparent;
  cursor: pointer;
  height: auto !important;
  left: 5px;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 5px;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1200px) {
  .Checkbox-container {
    flex-grow: 1;
    justify-content: flex-start;
  }
}
