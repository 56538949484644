.Billing-premium-cost-figo-monthly-container {
  display: flex;
  flex-direction: column;
}

.Billing-premium-cost-figo-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-rounded";
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.Billing-premium-cost-figo-divider {
  background-color: var(--pc-color-onyx-500);
  height: 1px;
  width: 100%;
}

.Billing-premium-cost-figo-annual-container {
  display: flex;
  flex-direction: row;
  gap: 82px;
  justify-content: center;
}

.Billing-premium-cost-figo-annual-container > h1 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-rounded";
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  padding: 0;
}

.Billing-premium-cost-figo-annual-container > span {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-rounded";
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  padding: 0;
}
