.EditPaymentMethod-modal-error-card-container {
  align-items: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  min-height: 375px;
  width: 381px;
}

.EditPaymentMethod-modal-success-card-container {
  align-items: center;
  border-radius: 12px;
  border-width: 0 !important;
  display: flex;
  flex-direction: column;
  min-height: 328px;
  width: 381px;
}

.EditPaymentMethod-modal-error-scrollable {
  display: flex;
  flex-direction: column;
  max-height: 375px;
  margin: 0 !important;
  width: 317px;
}

.EditPaymentMethod-modal-success-scrollable {
  display: flex;
  flex-direction: column;
  max-height: 375px;
  min-height: 46px;
  width: 317px;
}

.EditPaymentMethod-image-title {
  height: 64px;
  margin-top: 32px;
  width: 64px; 
}

.EditPaymentMethod-title {
  color: #22435e;
  font-family: "Inter-SemiBold";
  font-size: 22px;
  height: 30px;
  margin-top: 16px;
}

.EditPaymentMethod-subtitle {
  color: #3d484d;
  font-family: "Inter-Regular";
  font-size: 16px;
  margin-top: 16px;
  min-height: 92px;
  text-align: center;
  width: 317px;
}

.EditPaymentMethod-content-success {
  color: #3d484d;
  font-family: "Inter-Regular";
  font-size: 16px;
  margin-top: 16px;
  min-height: 46px;
  text-align: center;
  width: 317px;
}

.EditPaymentMethod-image-button-container {
  background-color: #2fb3b4;
  border-radius: 8px;
  border-width: 0;
  height: 60px;
  margin: 32px 0;
  width: 109px;
}

.EditPaymentMethod-image-button-container:hover {
  background-color: #009492;
  border-color: #b8ede8;
}

.EditPaymentMethod-image-button-title {
  color: #fff;
  font-family: "Inter-SemiBold";
  font-size: 16px;
}

/* Mobile */
@media (max-width: 568px) {
  .EditPaymentMethod-modal-success-card-container, 
  .EditPaymentMethod-modal-error-card-container {
    width: 100%;
  }
}