.All-policies-text {
  color: #2fbdbd;
  font-family: "Inter-SemiBold";
  font-size: 14;
}

.Hidden-item {
  display: none;
}

.Policy-item-container {
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
  padding: 6px 16px 5px 25px;
  width: 339px;
}

.Policies-select-arrow-container {
  align-items: center;
  display: flex;
  height: 12px;
  justify-content: center;
  width: 20px;
}

.Policies-select-container {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 48px;
  position: relative;
  width: 339px;
  z-index: 1;
}

.Policies-select-container:hover {
  cursor: pointer;
}

.Policies-select-data-container {
  display: flex;
  flex-direction: column;
  height: 37px;
  width: 230px;
}

.Policies-select-info-container {
  display: flex;
  flex-direction: row;
}

.Policies-select-item-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
  padding: 6px 16px 5px 25px;
  width: 339px;
}

.Policies-select-items-container {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0.05px #bebebe;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 9.89px 0 9.89px 0;
  position: absolute;
  top: 48px;
  width: 355px;
  z-index: 7;
}

.Policies-select-policy-name {
  color: #2fb3b4;
  font-family: "Inter-SemiBold";
  font-size: 13px;
}

.Policies-select-policy-period {
  color: #1e2226;
  font-size: 13px;
}

.Policies-select-policy-status {
  color: #899397;
  font-size: 13px;
  margin-left: 4px;
}

.Unselected-item {
  position: relative;
  z-index: 1;
}

.Unselected-item:hover {
  background-color: #eceef0;
  border-radius: 8px;
}

.Add-coverage-button {
  align-items: center;
  background-color: rgba(184, 237, 232, 0.75);
  border-radius: 8px;
  border: none;
  color: #2fb3b4;
  display: flex;
  font-family: "Inter-SemiBold";
  height: 48px;
  justify-content: flex-start;
  padding-left: 24px;
  width: 339px;
}

.Policies-selector-disabled,
.Pet-selector-disabled {
  background-color: #e7e7e7;
  pointer-events: none;
}

.Policies-selector-disabled .Policies-select-policy-name,
.Policies-selector-disabled .Policies-select-policy-status,
.Policies-selector-disabled .Policies-select-policy-period,
.Pet-selector-disabled .Pet-select-selected-name {
  color: #565656;
}

.Policies-selector-disabled {
  border-radius: 8px;
}

@media (max-width: 1200px) {
  .Policies-select-item-container {
    width: auto;
  }
  
  .Policies-select-items-container {
    align-items: flex-start;
  }
}
