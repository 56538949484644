.Pet-select-items-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0.05px #bebebe;
  padding: 8px;
  position: absolute;
  width: 236px;
  z-index: 7;
}

.Pet-select-name-container {
  display: flex;
  flex: auto;
  min-height: 20px;
  max-height: 40px;
}

.Pet-select-selected-arrow-container {
  align-items: center;
  display: flex;
  height: 12px;
  justify-content: center;
  width: 20px;
}

.Pet-select-selected-container,
.Pet-select-selected-container-coverage {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 48px;
  padding: 0 16px 0 8px;
  width: 240px;
}

.Pet-select-selected-container:hover {
  background-color: #eceef0;
  border-radius: 8px;
  cursor: pointer;
}

.Pet-select-selected-name {
  color: #3c4455;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}

.Pet-select-selected-name-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: auto;
}

.Pet-select-unselected-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 48px;
  padding: 0 19.37px 0 6.64px;
  width: 220px;
}

.Pet-select-unselected-item:hover {
  background-color: #eceef0;
  border-radius: 8px;
  cursor: pointer;
}

.Pet-selected-container {
  display: flex;
  flex: auto;
  height: 20px;
}

.Pet-selected-image {
  border-radius: 20px;
  height: 40px;
  margin-right: 12.63px;
  width: 40px;
}

.Pets-select-container {
  display: flex;
  height: 48px;
  width: 240px;
}

.Pet-text-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-select-selected-container-coverage {
    height: 78px;
    margin: 12px 0 0 40px;
    padding: 0 11px 0 8px;
    width: 80vw;
  }

  .Pets-select-container {
    display: flex;
    flex: 1;
    width: 80vw;
  }

  .Pet-selector-disabled-coverage {
    background-color: #e3e3e3;
    box-shadow: rgb(0, 0, 0, 0.05) 0 0 16px 0;
    pointer-events: none;
  }

  .Pet-selected-image {
    height: 45px;
    margin-right: 16px;
    width: 45px;
  }

  .Pet-select-selected-name,
  .Pet-select-selected-breed {
    color: #777;
    font-family: 'Inter-Medium';
    font-size: 20px;
    text-align: start;
  }

  .Pet-select-selected-breed {
    font-family: 'Inter-Regular';
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Pet-select-selected-arrow-container {
    height: 14.4px;
    width: 24.87px;
  }
}
