.Policy-alerts-container {
  margin-bottom: 24px;
}

.Policy-renewal-info-container {
  background-color: var(--pc-color-blue-100);
  border-radius: 8px;
  border: 1px solid var(--pc-color-blue-300);
  display: flex;
  flex: 1;
}

.Policy-renewal-container-red {
  background-color: rgba(248, 95, 37, 0.05);
  border: solid 1px #f85f25;
}

.Policy-alerts-separation {
  margin-top: 16px;
}

.Policy-alert-info-red-link {
  color: var(--primary-700);
  font-family: "Gotham-Rounded";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Policy-alert-icon {
  height: 20px;
  margin: 16px 8px 0 16px;
  width: 20px;
}

.Policy-alert-info-description {
  color: #202327;
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Policy-alert-info-title {
  color: var(--charcoal-500);
  font-family: 'Gotham-Rounded';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin-bottom: 4px;
}

.Policy-alert-description-container {
  display: flex;
}

.Policy-alert-description {
  color: #1e2226;
  font-size: 14px;
  line-height: normal;
}

.Policy-alert-info-link {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 100%;
  text-decoration-line: underline;
}

.Policy-alert-bold-text {
  color: #202327;
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Policy-alert-content {
  display: flex;
  flex-direction: column;
  margin: 16px 64.93px 17px 0;
}

@media (width: 768px) {
  .Policy-alert-content {
    width: 628.07px;
  }
}
