.Contact-us-company-container {
  display: flex;
  flex-direction: column;
  min-width: 472px;
  padding: 8px;
}

.Contact-us-modal-container-with-back-button 
.Contact-us-company-header-container {
  margin-top: 24px;
}

.Contact-us-company-header-title {
  color: var(--charcoal-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-weight: 350;
  letter-spacing: -0.005em;
  line-height: 28px;
  text-align: left;
}

.Contact-us-company-header-description {
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 19px;
  margin: 0 0 16px 0;
  text-align: left;
}

.Contact-us-extra-text {
  color: var(--pc-color-onyx-300);
  font-family: "Gotham-Regular";
  margin-bottom: 0;
  margin-top: 24px;
}

.Contact-us-extra-text:empty {
  height: 0;
  margin: 0;
}

.Contact-us-extra-text > p:last-child {
  margin-bottom: 0;
}
