.Tooltip-main-container[data-teal=true] {
  --tooltipFont: #fff;
  --tooltipPrimary: var(--teal);
}

.Tooltip-main-container[data-teal=false] {
  --tooltipFont: #1e2226;
  --tooltipPrimary: #28ede8;
}

.Tooltip-icon-default {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.Tooltip-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 250px;
  z-index: 1;
}

.Tooltip-description-container {
  align-items: center;
  background-color: var(--tooltipPrimary);
  border-radius: 2px;
  display: flex;
  height: auto;
  justify-content: center;
  overflow: hidden;
  padding: 8px 16px;
  position: absolute;
  width: 240px;
}

.Tooltip-description {
  color: var(--tooltipFont) !important;
  font-family: "Inter-Regular" !important;
  font-size: 13px !important;
  letter-spacing: -0.4px;
  line-height: 17px;
  text-align: center;
}

.Tooltip-icon-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.Tooltip-arrow-container {
  display: flex;
  height: 10%;
  left: 1px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.Tooltip-arrow-container-top {
  left: 0px;
  top: -6px;
}

.Tooltip-arrow-container-top-left {
  left: 0px; 
  top: -6px;
}

.Tooltip-arrow-container-down {
  left: 3px;
  top: 25px;
}

.Tooltip-arrow-container-left {
  left: -10px;
  top: 3px;
}

.Tooltip-arrow-container-right {
  left: 5px;
  top: -7px;
}

.Tooltip-arrow-down {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--tooltipPrimary);
  height: 0;
  width: 0;
}

.Tooltip-arrow-top {
  border-bottom: 20px solid var(--tooltipPrimary);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  height: 0;
  width: 0;
}

.Tooltip-arrow-left {
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--tooltipPrimary);
  border-top: 10px solid transparent;
  height: 0;
  left: 15px;
  position: relative;
  top: 11px;
  width: 0;
}

.Tooltip-arrow-right {
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--tooltipPrimary);
  border-top: 10px solid transparent;
  height: 0;
  width: 0;
}

.Tooltip-backdrop {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
}
