.File-claimbot-claim-selector-container {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  max-width: 482px;
  width: 100%;
}

.File-claimbot-claim-selector-container > h3 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.File-claimbot-claim-selector-options-container {
  display: flex;
  flex-wrap: wrap;
}

.File-claimbot-claim-selector-item-label {
  align-items: center;
  color: var(--charcoal-500);
  display: flex;
  font-family: "Gotham-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  line-height: normal;
}

.File-claimbot-claim-selector-item-container {
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  margin: 8px 0;
  width: 50%;
}

.File-claimbot-claim-selector-item-label > input {
  display: none;
}

.File-claimbot-claim-selector-item-label > img {
  height: 24px;
  margin-right: 6px;
  object-fit: contain;
  width: 24px;
}
