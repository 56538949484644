.Folder-card {
  margin: 15px;
}

.Folder-container-card {
  background-color:var(--base-pc-color-white);
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px var(--pc-color-grey-150);
  cursor: pointer;
  min-height: 202px;
  position: relative;
  width: 248px;
}

.Folder-icon-card {
  position: absolute;
  margin: 9px;
}

.Folder-title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: var(--base-pc-color-black);
  display: -webkit-box;
  font-family: "Inter-SemiBold";
  font-size: 20px;
  line-height: 1.2;
  margin: 84px 0 12px 0;
  overflow: hidden;
  word-break: break-word;
}

.Folder-title-width {
  max-width: 179px;
}

.Folder-unread-files {
  margin-left: 17px;
}

.Folder-unread-files-amount {
  color: var(--pc-color-onyx-300);
  font-family: "Inter-Medium";
  font-size: 14px;
  position: relative;
}

.Folder-unread-files-amount:before {
  background: var(--pc-color-leaf-300);
  border-radius: 50%;
  content: "";
  height: 9px;
  left: -15px;
  position: absolute;
  top: 5px;
  width: 9px;
}

.Folder-unread-files-date {
  color: var(--pc-color-onyx-300);
  display: block;
  font-family: "Inter-Regular";
  font-size: 12px;
}

.Folder-dots-container {
  position: absolute;
  right: 8px;
  top: 0px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Folder-container {
    flex-basis: 26%;
    margin-right: 0;
    min-width: 220px;
  }

  .Folder-container-card {
    flex-basis: 31%;
    min-width: 200px;
  }
  
  .Folder-title-width {
    max-width: 165px;
  }
}

/* Mobile */
@media (max-width: 698px) {
  .Folder-container-card {
    min-width: 160px;
  }
}
