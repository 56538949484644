.Multibrand-claim-card {
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  width: 100%;
}

.Claim-header {
  align-items: center;
  background-color: var(--primary-background);
  color: var(--base-pc-color-white);
  display: flex;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  height: 64px;
  justify-content: space-between;
  padding: 0 32px;
  width: 100%;
}

.Claim-header[data-closed=true] {
  background-color: var(--pc-color-onyx-200);
}

.Claim-header-logo {
  height: 34px;
}

.Claim-data-row-label {
  color: var(--pc-color-onyx-400);
  font-family: "Gotham-Medium";
  letter-spacing: -0.408px;
  margin-right: 8px;
}

.Claim-data-row-text {
  color: var(--pc-color-onyx-400);
  display: inline-block;
  font-family: "Gotham-Regular";
  letter-spacing: -0.408px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  max-width: 450px;
}

.Claim-data-content {
  flex: 1;
}

.Claim-data-container {
  display: flex;
  justify-content: space-between;
  padding: 24px 32px 32px;
  width: 100%;
}

.Claim-data-figo-container {
  flex-direction: column;
}

.Multibrand-claim-card + .Multibrand-claim-card {
  margin-top: 16px;
}

.Claim-data-info {
  margin-left: 40px;
}

.Claim-data-pet {
  font-family: "Gotham-Medium";
  font-size: 18px;
  letter-spacing: -0.408px;
}

.Claim-data-pet-image {
  height: 32px;
  margin-right: 8px;
  width: 32px;
}

.Claim-data-figo {
  flex: 1;
}

.Claim-data-container-open {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.Claim-data-amount {
  background-color: var(--primary-background);
  border-radius: 8px;
  color: var(--base-pc-color-white);
  font-family: 'Gotham-Rounded';
  font-size: 18px;
  line-height: 21.6px;
  margin: 0px 8px 0px 0px;
  min-width: 100px;
  padding: 10px 20px 10px 20px;
  text-align: center;
}

.Claim-data-status {
  color: var(--pc-color-hydrant-400);
}

.Claim-header-button {
  background: none;
  border: none;
  color: var(--base-pc-color-white);
  font-family: 'Gotham-Rounded';
  font-size: 16px;
  line-height: 19.2px;
  text-align: center;
}
