.My-pets-slider-container {
  align-items: center;
  display: flex;
  position: relative;
}

.My-pets-slider-plus {
  margin-left: 16px;
}

.My-pets-slider-plus, .My-pets-slider-minus {
  height: 20px;
  width: 20px;
}

.My-pets-slider {
  align-items: center;
  display: flex;
  padding: 0 18px;
  position: absolute;
  width: 100%;
}

.My-pets-slider-container input[type=range] {
  height: 20px !important;
  margin-left: 6px;
  margin-right: -10px;
  opacity: 0;
  padding: 0 !important;
  width: 40%;
  z-index: 1;
}

.My-pets-slider-rail {
  background-color: var(--pc-color-cotton-300);
  border-radius: 4px;
  height: 7px;
  margin-left: 12px;
  width: 40%;
}

.My-pets-slider-value-track {
  align-items: center;
  display: flex;
  left: 26px;
  pointer-events: none;
  position: absolute;
  width: 38%;
}

.My-pets-slider-track {
  background-color: var(--pc-color-blue-300);
  border-radius: 4px;
  height: 7px;
  position: absolute;
}

.My-pets-slider-value {
  background-color: var(--pc-color-blue-300);
  border-radius: 50px;
  border: 1px solid var(--pc-color-blue-300);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  height: 20px;
  position: absolute;
  transform: translateZ(0);
  width: 20px;
  will-change: left;
}

.My-pets-slider-percentage {
  margin: 6px;
}
