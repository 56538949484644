.Shimmer {
  animation : shimmer 10s infinite;
  background-size: 1000px 100%;
  background: linear-gradient(to right, #f8f8f8 4%, #ececec 25%, #f8f8f8 36%);
  color: transparent !important;  
  display: flex;
}

.Shimmer > * {
  color: transparent !important;
  display: none !important;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}