.Change-coverage-screen-options-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 32px 48px 48px 48px;
  width: 100%;
}

.Change-coverage-screen-options-container > h3 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 16px;
}

.Change-coverage-screen-options-description {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
  width: 100%;
}

/* Mobile */
@media (max-width: 568px) {
  .Change-coverage-screen-options-container {
    padding: 32px 24px 0;
  }

  .Change-coverage-screen-options-container > h3,
  .Change-coverage-screen-options-description {
    text-align: left;
  }
}
