.Modifier-modal-info-container {
  background-color: var(--base-pc-color-white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  padding: 24px;
  width: 520px;
}

.Modifier-modal-info-content-subtitle {
  align-self: flex-start;
  color: var(--pc-color-onyx-300);
  font-family: "Gotham-Regular";
  font-size: 16px;
  line-height: normal;
  margin-top: 19.5px;
}

.Modifier-modal-info-content-subtitle:empty {
  margin-top: 0;
}

.Modifier-modal-info-content-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.Modifier-modal-info-content-title-container,
.Modifier-whats-covered-title-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  width: 100%;
}

.Modifier-modal-title-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Modifier-modal-close-icon {
  background-color: transparent;
  border-radius: 100px;
  border: none;
  padding: 8px;
}

.Modifier-modal-close-icon > img {
  height: 16px;
  width: 16px;
}

.Modifier-whats-covered-title-container {
  flex-direction: column;
  justify-content: center;
}

.Modifier-modal-info-content-title {
  color: var(--charcoal-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin: 0;
}

.Modifier-modal-info-content-image-container {
  align-items: center;
  background-color: #eceef0;
  display: flex;
  height: 96px;
  justify-content: center;
  margin-top: 23.5px;
  min-height: 96px;
  width: 100%;
}

.Modifier-modal-info-content-image {
  height: 100%;
  width: 100%;
}

.Modifier-modal-info-content-rows-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 16px;
  width: 100%;
}

.Modifier-modal-info-element-gone {
  display: none;
}

.Modifier-modal-info-green-label {
  color: var(--dark-mint);
  font-family: "Gotham-Medium";
  text-decoration: none;
}

.Modifier-modal-bottom-container {
  align-items: flex-end;
  display: flex;
  margin-top: 24px;
  width: 100%;
}

.Modifier-modal-bottom-container > button {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: none;
  color: var(--base-pc-color-white);
  display: flex;
  font-family: "Gotham-Medium";
  font-size: 16px;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: normal;
  padding: 12px 16px;
  width: 140px;
}

.Modifier-modal-contentful-content {
  align-items: flex-start;
  border-bottom-width: 2px;
  border-color: var(--pc-color-cotton-500);
  border-style: none none solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1px;
  margin-top: 10px;
  min-height: fit-content;
  padding: 0;
  width: 100%;
}

.Modifier-modal-contentful-content > p,
.Modifier-modal-contentful-content > ul {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Modifier-modal-contentful-content > ul {
  padding-left: 15px;
}

/* Tablet and Mobile shared styles */
@media (max-width: 1200px) {
  .Modifier-modal-info-content-container {
    text-align: justify;
  }

  .Modifier-modal-info-content-image-container {
    display: none;
  }

  .Modifier-modal-info-col-right {
    width: 100%;
  }
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Modifier-modal-info-content-title-container,
  .Modifier-whats-covered-title-container {
    flex-direction: column;
  }

  .Modifier-modal-card-container {
    border-radius: 8px;
    height: 59%;
  }

  .Modifier-modal-info-content-title {
    font-size: 24px;
  }

  .Scrollbar-margin::-webkit-scrollbar-track {
    margin-top: 90px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Modifier-modal-info-content-title-container,
  .Modifier-whats-covered-title-container {
    flex-direction: column;
  }

  .Modifier-modal-card-container {
    border-radius: 24px 24px 0 0;
    height: 75%;
    position: absolute;
    width: 100%;
  }

  .Modifier-modal-info-container {
    flex-direction: row;
    height: 100%;
    justify-content: center;
    margin-right: 0;
    width: 100%;
  }

  .Modifier-modal-info-content-title {
    font-size: 22px;
    padding-top: 32px;
  }

  .Scrollbar-margin::-webkit-scrollbar-track {
    margin-top: 73px;
  }

  .Modifier-modal-info-content-container {
    height: fit-content;
  }
}
