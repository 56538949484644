.My-pets-profile-family-picture-container {
  display: flex;
  max-height: 350px;
  min-height: 126px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.My-pets-profile-family-placeholder {
  position: relative;
}

.My-pets-profile-family-image-placeholder {
  height: 100%;
  position: relative;
  width: 100%;
}

.My-pets-profile-family-image {
  object-fit: cover;
  position: relative;
  width: 100%;
}

.My-pets-profile-family-upload-photo-styles {
  background-color: var(--pc-color-blue-300);
  background-image: url('./../../../assets/myPets/camera-my-pets.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  border-radius: 100px;
  bottom: 22px;
  height: 41px;
  right: 48px;
  width: 41px;
}

@media (max-width: 1200px) {
  .My-pets-profile-family-upload-photo-styles {
    right: 24px;
  }
}

@media (max-width: 929px) {
  .My-pets-profile-family-picture-container {
    max-height: 360px;
    max-width: 928px;
    min-height: 126px;
  }
}
