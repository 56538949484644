.Current-pet-policy-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 24px;
}

.Current-pet-policy-container > img {
  align-items: center;
  background-color: var(--pc-color-onyx-100);
  border-radius: 100px;
  border: none;
  display: flex;
  flex-shrink: 0;
  height: 56px;
  justify-content: center;
  width: 56px;
}

.Current-pet-policy-info-container {
  display: flex;
  flex-direction: column;
}

.Current-pet-policy-name {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Current-pet-policy-breed {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
}

.Current-pet-policy-shimmer {
  height: 56px;
  width: 198px;
}
