.Loading-button-container {
  background-color: #2fb3b4;
  border-color: #b8ede8;
  border-radius: 8px;
  height: 60px;
  width: 128px;
}

.Loading-button-container:hover:enabled {
  background-color: #009492;
  border-color: #b8ede8;
}


.Loading-button-container:active {
  border-color: #b8ede8;
  box-shadow: 0 0 2px 2px #b8ede8;
}

.Loading-button-container:focus {
  border-color: #b8ede8;
  box-shadow: 0 0 2px 2px #b8ede8;
}


.Loading-button-text {
  font-family: "Inter-SemiBold";
  font-size: 16px;
  text-align: center;
}