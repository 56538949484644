.Submit-container {
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  height: 335px;
  justify-content: center;
  text-align: center;
  width: 511px;
}

.Submit-container > img {
  height: 64px;
  width: 64px;
}

.Submit-container > span {
  color: #264b6a;
  font: 22px 'Inter-SemiBold';
  height: 30px;
  margin: 16px 0;
  width: 486px;
}

.Submit-container > p {
  color: #3d484d;
  font: 16px 'Inter-Regular';
  height: 46px;
  text-align: center;
  width: 436px;
}

.Submit-container > button:last-child {
  background-color: #2fb3b4;
  border-radius: 8px;
  border: none;
  color: #fff;
  font: 16px 'Inter-SemiBold';
  height: 60px;
  width: 229px;
}
