.My-pets-vets-container {
  padding: 32px 48px;
}

.My-pets-vets-title {
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
}

.My-pets-vets-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.My-pets-vets-add-vet-button {
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-weight: 350;
  justify-content: flex-end;
  line-height: 16px;
  margin-right: 12px;
  padding: 0;
  text-align: center;
}

.underline-wrapper {
  align-items: center;
  border-bottom: 1px solid var(--pc-color-blue-300);
  display: flex;
}

.My-pets-vets-add-vet-button-icon {
  height: 16px;
  margin-bottom: 1px;
  margin-right: 4px;
  width: 16px;
}
