.Delete-a-pet-modal-main-container {
  align-items: flex-start;
  background: var(--base-white);
  border-radius: 16px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 520px;
}

.Delete-a-pet-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Delete-a-pet-modal-header h3 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin: 0;
}

.Delete-a-pet-close-modal {
  align-items: center;
  background: var(--base-pc-color-white);
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  padding: 8px;
}

.Delete-pet-close-modal img {
  height: 16px;
  width: 16px;
}

.Delete-a-pet-modal-message {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.Delete-a-pet-actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
} 
