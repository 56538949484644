.Footer-pet-cloud-container {
  align-items: center;
  background-color: #f1fde1;
  color: var(--gray-icon-text);
  display: flex;
  flex-direction: row;
  font-family: 'Inter-Regular';
  font-size: 18px;
  justify-content: space-between;
  letter-spacing: 0.7px;
  line-height: 21.78px;
  margin-top: 20px;
  min-height: 127px;
  padding: 26px 38px 26px 48px;
  width: 100%;
}

.Footer-pet-cloud-email-icon {
  margin-right: 10px;
}

.Footer-pet-cloud-div-right {
  text-align: right;
  align-items: flex-end;
}

.Footer-pet-cloud-stores {
  margin: 16px 0 12px 0;
}

.Footer-pet-cloud-download-title {
  color: var(--yankees-blue);
  font-weight: 600;
}

.Footer-pet-cloud-download-email {
  color: var(--gray-subtext);
  font-size: 14px;
  font-weight: 500;
}

.Footer-pet-cloud-stores a:first-child {
  margin-right: 16px;
}

/* Talet */
@media (max-width: 1200px) {
  .Footer-pet-cloud-div-right {
    align-items: unset;
    margin-top: 32px;
    text-align: unset;
  }

  .Footer-pet-cloud-stores {
    justify-content: center
  }

  .Footer-pet-cloud-container {
    flex-direction: column;
    padding-bottom: 20px;
    text-align: center;
  }
}
