.Inbox-and-docs-email {
  color: #b8ede8;
  font-family: 'Inter-Medium';
  font-size: 16px;
  margin-left: 30.69px;
  margin-right: 8px;
}

.Inbox-and-docs-center {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Inbox-and-docs-loading-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: 18px 48px;
  width: calc(100% - 96px);
}

.Inbox-and-docs-upload {
  float: right;
}

.Inbox-and-docs-upload {
  border-radius: 8px;
  border: none;
  color: #fff;
  font-family: 'Inter-Semibold';
  line-height: 1.25;
  padding: 14px 24px;
}

.Inbox-and-docs-upload img {
  height: 20px;
  margin-right: 8px;
  transform: translateY(-2px);
  width: 20px;
}

.Inbox-and-docs-upload {
  background-color: #2fb3b4;
}

.Inbox-and-docs-activity-section {
  border: 1px dotted #3e3e3e;
  height: 350px;
  min-width: 257px;
  padding: 0 48px;
}

.Inbox-and-docs-actions-container {
  min-width: 307px;
}

.Add-folder-modal-label {
  margin-top: 26px;
}

.Inbox-and-docs-upload.Round-button {
  background-color: var(--pc-color-blue-400);
  border-radius: 20px;
  border: none;
  color: var(--base-pc-color-white);
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Inbox-and-docs-activity-section {
    margin-bottom: 32px;
  }
}

/* re-design */
.Inbox-and-docs-add-folder {
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: none;
  float: left;
  height: 40px;
  margin-right: 8px;
  padding: 0 16px;
  width: 175px;
}

.Inbox-and-docs-add-folder > span {
  color: var(--base-pc-color-white);
  font-family: 'Gotham-medium';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 16px;
}

.Inbox-and-docs-add-folder img {
  height: 16px;
  margin-right: 8px;
  width: 16px;
}

/* re-design */
.Inbox-and-docs-content {
  background-color: #f8f8f8;
  justify-content: space-between;
  max-width: 1110px;
  padding: 40px 32px 150px 48px;
}

.Inbox-and-docs-header {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  max-width: 1187px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Inbox-and-docs-content {
    flex-direction: column-reverse;
    justify-content: center;
    max-width: 100%;
    padding: 0 24px;
    width: 100%;
  }

  .Inbox-and-docs-header {
    padding-right: 0px;
  }
}
