.Family-cover-picture-container {
  width: 540px;
}

.Family-cover-picture-draggable-container {
  height: 237px;
  margin: 0 0 12px 40px;
  min-height: 237px;
  width: 460px;
}

.Family-cover-picture-rotate-buttons {
  left: 0;
  padding: 0 37.12px;
  width: 100%;
}

.Family-cover-picture-slider-container {
  left: 125px;
  width: 300px;
}
