.Contact-us-schedule-row {
  display: flex;
  flex-direction: row;
}

.Contact-us-schedule-text {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  min-width: 66px;
}
