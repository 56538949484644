.CCEM-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 24px 37px 24px;
  width: 381px;
}

.CCEM-title {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 22px;
}

.CCEM-message {
  color: #3d484d;
  font-family: 'Inter-Regular';
  font-size: 16px;
  text-align: center;
}

.CCEM-close-container {
  align-items: center;
  background-color: #2fb3b4;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 229px;
}

.CCEM-close-text {
  color: #fff;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  pointer-events: none;
}
