.Side-menu-body {
  display: flex;
  flex: 1;
}

.Side-menu-content { 
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Menu-button-text-container {
  flex: 1;
  color: #7e888c;
}

.Grow-1 {
  flex-grow: 1;
}

.Side-menu {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 32px 16px 0;
  width: 244px;
}

.Side-menu-button-container { 
  align-items: center;
  display: flex;
  flex: 1;
  padding: 10px 0px;
}

.Side-menu-button {
  align-items: center;
  display: flex;
  font-family: 'Gotham-Regular';
  width: 100%;
}

.Side-menu-action-button,
.Side-menu-button a {
  color: var(--pc-color-onyx-500);
  font-size: 14px;
  text-decoration: none;
  width: 100%;
}

.Side-menu-action-button {
  background: none;
  border: none;
  font-family: 'Gotham-Regular';
  padding: 10px 8px;
  text-align: left;
  width: 100%;
}

.Side-menu-action-button:hover,
.Side-menu-button a:hover{
  background-color: var(--pc-color-blue-300);
  border-radius: 8px;
  color: #fff;
  opacity: 0.85;
  text-decoration: none;
}

.Side-menu-action-button-icon {
  margin-right: 8px;
}

.Selected-link {
  background-color: var(--pc-color-blue-300);
  border-radius: 8px;
  color: #fff;
  flex: 1;
  text-decoration: none;
}

.Side-menu-button .Menu-button-text-container {
  color: var(--pc-color-onyx-500);
}

.Selected-link .Menu-button-text-container {
  color: #fff;
}

.Side-menu-button a:hover .Menu-button-text-container {
  color: #fff;
}

.Side-menu-button .Menu-button-text {
  display: block;
  max-width: 171px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Side-menu-action-button-icon,
.Side-menu-icon {
  stroke: var(--pc-color-onyx-500);
}

.Side-menu-action-button:hover .Side-menu-action-button-icon,
.Selected-link .Side-menu-icon,
.Side-menu-button a:hover .Side-menu-icon {
  stroke: #fff;
}

.Menu-icon-container {
  margin-right: 8px;
  padding-left: 8px;
}

.Side-menu-section-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 14px;
  margin-top: 34px;
  padding: 0 8px;
}

.Side-menu-logout-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  margin: 120px 0;
}

.Side-menu-logout {
  color: var(--pc-color-hydrant-400);
}

.Side-menu-contact-us {
  color: var(--base-pc-color-black);
}

.Side-menu-logout-icon {
  fill: var(--pc-color-hydrant-400);
  stroke: none;
}

.Side-menu-contact-us-icon {
  stroke: var(--base-pc-color-black);
}

.Side-menu-contact-us:hover .Side-menu-contact-us-icon {
  stroke: var(--base-pc-color-white);
}

.Side-menu-logout:hover .Side-menu-logout-icon {
  fill: var(--base-pc-color-white);
  stroke: none;
}
