.Billing-info-screen-container {
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 18px 0;
  width: 100%;
}

.Billing-info-screen-button-add-method {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  cursor: pointer;
  display: flex;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 27px;
  justify-content: space-between;
  letter-spacing: 2%;
  line-height: 16px;
  padding: 4px 0 0;
  text-align: center;
  text-transform: capitalize;
  width: 200px;
}

.Billing-info-screen-button-add-method div {
  display: flex;
  width: 100%;
}

.Billing-info-screen-button-add-method p {
  margin: 0;
}

.Billing-info-screen-button-add-method p {
  border-bottom: 1px solid var(--pc-color-blue-300);
}

.Billing-info-screen-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin-bottom: 16px;
  width: 100%;
}

.Billing-info-screen-title-responsive {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin-bottom: 16px;
  width: 100%;
}

.Billing-info-screen-button-image {
  margin-right: 8px;
}

/* Mobile */
@media (max-width: 568px) {
  .Billing-info-screen-container {
    margin: 0;
    padding: 16px;
  }

  .Billing-info-screen-title-responsive {
    font-family: 'Gotham-Rounded';
    font-size: 20px;
  }
}
