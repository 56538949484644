.PetCloudInput-main-container {
  position: relative;
}

.PetCloudInput-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.07px;
  line-height: 16px;
  margin: 0;
}

.PetCloudInput-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.PetCloudInput-optional {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.PetCloudInput-input::placeholder {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PetCloudInput-input {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  border: 1px solid var(--pc-color-onyx-100);
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 33px;
  line-height: normal;
  padding: 8px 9px;
  width: 100%;
}

.PetCloudInput-input:focus {
  border-radius: 8px !important;
  border: 2px solid var(--pc-color-blue-300) !important;
  outline: none !important;
}

.PetCloudInput-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.PetCloudInput-error {
  align-items: center;
  background-color: #fef1f2;
  border-radius: 4px;
  color: var(--pc-color-hydrant-400);
  display: flex;
  font-family: 'Gotham-Regular';
  font-size: 12px;
  height: 24px;
  margin: 4px 0 0;
  position: absolute;
  width: 100%;
}

.PetCloudInput-error > img {
  height: 16px;
  margin: 0 4px;
  width: 16px;
}

.PetCloudInput-error > span {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Input-disabled {
  background-color: #f8f8f8 !important;
  border: 1px solid var(--pc-color-cotton-200);
}
