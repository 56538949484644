.Modal-content-container {
  width: 520px;
}

.Microchip-modal-container {
  height: 100%;
  min-height: 363px;
  position: relative;
  width: 100%;
}

.Microchip-modal-cross-button {
  background: none;
  border: none;
  display: inline-block;
  height: 32px;
  padding: 0;
  position: absolute;
  right: 24px;
  top: 20px;
  width: 32px;
}

.Microchip-modal-content {
  padding: 26px 24px 24px;
}

.Microchip-modal-content .Microchip-form-container {
  max-width: 100%;
}

.Microchip-modal-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 6px;
}

.Microchip-modal-subtitle {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.Microchip-modal-content .Microchip-form-container .formlabel {
  align-items: center;
  color: var(--pc-color-onyx-500);
  display: flex;
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  height: 24px;
  letter-spacing: -0.07px;
  line-height: 16px;
  padding: 0px 0px 8px;
}

.Microchip-modal-content .Microchip-form-container .custom-select,
.Microchip-modal-content .Microchip-form-container .FigoInput-input {
  border: 1px solid var(--pc-color-onyx-300);
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 19px;
  padding: 8px 9px !important;
  text-align: left;
}

.Microchip-modal-content .Microchip-form-container .custom-select {
  background: url("../../../assets/chevron-outline.svg") no-repeat right 12.33px center/16px 16px;
}

.Microchip-modal-content .Microchip-form-container .custom-select option {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.Microchip-modal-content .Microchip-form-container .FigoInput-input:focus,
.Microchip-modal-content .Microchip-form-container .FigoInput-input:active {
  border-color: #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  outline: 0;
}

.Microchip-modal-content .Microchip-form-container .form-label {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-weight: 350;
  letter-spacing: -0.005em;
  line-height: 16px;
  text-align: left;
}

.Microchip-modal-content .Microchip-form-container .FigoInput-input::placeholder {
  color: var(--pc-color-onyx-500);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}

.Microchip-modal-buttons-container {
  display: flex;
  gap: 8px;
}

.Microchip-modal-save-button,
.Microchip-modal-cancel-button {
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  min-width: 140px;
  padding: 12px 16px;
}

.Microchip-modal-save-button,
.Microchip-modal-save-button:hover {
  background-color: var(--pc-color-blue-300);
  border: 1px solid var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
  transition: 0.3s;
}

.Microchip-modal-save-button:hover {
  opacity: 0.9;
}

.Microchip-modal-cancel-button {
  background: var(--base-pc-color-white);
  border: 1px solid var(--pc-border-radius-xl);
  color: var(--pc-color-onyx-500);
}

.Microchip-modal-save-button:disabled {
  background-color: var(--pc-color-cotton-200);
  border-color: var(--pc-color-cotton-200);
  color: var(--pc-color-onyx-200);
  cursor: not-allowed;
}
