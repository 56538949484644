.Modifier-modal-info-content-row {
  align-items: center;
  border-bottom-width: 2px;
  border-color: var(--pc-color-cotton-500);
  border-style: none none solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1px;
  min-height: fit-content;
  padding: 24px 0 24px;
  width: 100%;
}

.Modifier-modal-info-content-row-top {
  display: flex;
  width: 100%;
}

.Modifier-modal-info-element-gone {
  display: none;
}

.Modifier-modal-info-content-row-title,
.Modifier-modal-info-content-row-value,
.Modifier-modal-info-content-row-subtitle {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Regular";
}

.Modifier-modal-info-content-row-title {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}

.Modifier-modal-info-content-row-title-semi-bold {
  font-family: "Gotham-Medium";
}

.Modifier-modal-info-content-row-value {
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
  min-width: fit-content;
}

.Modifier-modal-info-content-row-middle {
  display: flex;
  width: 100%;
}

.Modifier-modal-info-content-row-subtitle {
  font-size: 12px;
  font-weight: 400;
  margin-top: 16px;
}

.Modifier-modal-info-no-padding {
  padding: 0;
}

/* Tablet and Mobile shared styles */
@media (max-width: 1200px) {
  .Modifier-modal-info-content-row-subtitle {
    font-size: 14px;
    padding: 0;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Modifier-modal-info-content-row-title {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
  }

  .Modifier-modal-info-content-row-subtitle {
    font-size: 14px;
    padding: 0;
  }
}
