.Figo-lost-pet-family-row {
  background-color: #95e6e2;
  display: inherit;
  height: 211px;
  justify-content: center;
  width: 100%;
}

.Figo-lost-pet-family-row > img {
  height: 244px;
  margin-top: 21px;
  width: 295px;
}

/* Mobile */
@media (max-width: 568px) {
  .Figo-lost-pet-family-row {
    height: 120px;
  }
  .Figo-lost-pet-family-row > img {
    height: 143px;
    max-width: 172px;
    width: 60%;
  }
}
