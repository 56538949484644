.Pet-card-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-color: #fff;
  border-radius: 8px;
  border-style: solid;
  border-width: 3px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  max-width: 348px;
  min-width: 324px;
  padding: 24px;
  transition: all 0.2s ease;
}

.Pet-card-container:hover {
  opacity: 0.5;
}

.Pet-card-top-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  width: 296px;
}

.Pet-card-top-container > img {
  align-items: center;
  background-color: var(--pc-color-onyx-100);
  border-radius: 100px;
  border: none;
  display: flex;
  flex-shrink: 0;
  height: 72px;
  justify-content: center;
  margin-right: 16px;
  width: 72px;
}

.Pet-card-top-info-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Pet-card-name {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 4px;
  max-width: 208px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Pet-card-breed {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 208px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Pet-card-bottom-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.Pet-card-age-container,
.Pet-card-gender-container,
.Pet-card-weight-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: center;
  width: min-content;
}

.Pet-card-vertical-divider {
  background-color: var(--pc-color-cotton-300);
  width: 2px;
}

.Pet-card-info-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin-bottom: 4px;
}

.Pet-card-info-subtitle {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  white-space: nowrap;
}

.Pet-card-info-subtitle-gender {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
}
