.My-vets-placeholder-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
}

.My-vets-placeholder-container > img {
  height: 60px;
  width: 60px;
}

.My-vets-placeholder-container > h4 {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin: 8px 0 16px 0;
  text-align: center;
}

.My-vets-placeholder-text {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Book';
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  text-align: center;
  white-space: break-spaces;
}

.My-vets-placeholder-button {
  align-items: center;
  background: var(--pc-color-blue-300);
  border-radius: 20px;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  margin-top: 36px;
  min-width: 140px;
  padding: 12px 16px;
  width: 143.5px;
}

.My-vets-placeholder-button > span {
  color: var(--base-pc-color-white);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
}
