.Download-apps-main-container {
  align-items: center;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.Download-apps-header-container {
  align-items: center;
  background-color: var(--yankees-blue);
  color: #fff;
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 55px 0 41.15px;
  width: 100%;
}

.Download-apps-card-container {
  align-items: center;
  display: flex;
  flex: 1;
  margin: 78px 0 55px;
}

.Download-apps-card {
  align-items: center;
  background-color: #fff;
  border-radius: 32px;
  box-shadow: 0 0 30px rgba(141, 151, 158, 0.2);
  display: flex;
  flex-direction: column;
  height: 720px;
  width: 600px;
}

.Download-apps-card > h1 {
  color: var(--yankees-blue);
  font-family: "Inter-regular";
  font-size: 32px;
  letter-spacing: -0.70176px;
  margin-top: 24px;
}

.Download-apps-card > span {
  color: var(--gray-icon-text);
  font-family: "Inter-regular";
  font-size: 16px;
  letter-spacing: -0.408px;
  line-height: 22px;
  margin-top: 16px;
  text-align: center;
  width: 60%;
}

.Download-apps-icon-store-container {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 282px;
}

.Download-apps-icon-store {
  height: 37.96px;
  width: 125.2px;
}

.Download-apps-qr-code {
  height: 123px;
  margin-top: 32px;
  width: 123px;
}

.Download-apps-phone-number-input {
  border-color: var(--gray-icon-text);
  border-style: none none solid none;
  border-width: 1px;
  margin-top: 24px;
  padding: 8px;
  width: 144px;
}

.Download-apps-phone-number-input::placeholder {
  color: var(--gray-subtext);
  font-family: "Inter-regular";
  font-size: 14px;
  letter-spacing: -0.408px;
  line-height: 26px;
}

.Download-apps-submit-button {
  align-items: center;
  background-color: var(--teal);
  border-color: var(--teal);
  border-radius: 29px;
  border-style: solid;
  color: #fff;
  display: flex;
  font-family: "Inter-regular";
  font-size: 14px;
  height: 33px;
  justify-content: center;
  line-height: 16.94px;
  margin-top: 16px;
  padding: 8px;
  width: 148px;
}

.Download-apps-arrow-check {
  height: 10.67px;
  margin-right: 4px;
  width: 13.95px;
}

/* Mobile */
@media (max-width: 568px) {
  .Download-apps-card {
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    margin: 0;
    width: 100%;
  }

  .Download-apps-pet-collage-image {
    width: 100%;
  }

  .Download-apps-icon-store-container {
    margin-top: 32px;
  }

  .Download-apps-qr-code {
    margin-top: 40px;
  }

  .Download-apps-main-container {
    background-color: #fff;
  }

  .Download-apps-submit-button {
    margin-bottom: 19px;
  }
}
