.Billing-payments__container {
  color: var(--base-pc-color-black);
  display: flex;
  flex-direction: column;
  font-family: "Gotham-Regular";
  gap: 1.5rem;
  margin-top: 3rem;
}

.Billing-payments__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Billing-payments__title {
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
}

.Billing-payments__body {
  display: flex;
  gap: 2.5rem;
}

.Billing-payments-general {
  display: flex;
  gap: 40px;
}

.Billing-payments__footer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  width: 100%;
}

.Billing-payments__footer > h5 {
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
}

.Payment-method__card {
  align-items: center;
  background: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  width: 100%;
}

.Payment-method__text {
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
}

.Payment-method__add-button {
  align-items: center;
  background-color: var(--primary-text);
  border-radius: 20px;
  border: none;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  margin-left: auto;
  padding: 12px 16px;
}

.Payment-method__add-button > span {
  color: var(--base-pc-color-white);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}

.Payment-method__add-button > img {
  height: 16px;
  width: 16px;
}
