.Add-a-vet-modal-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  max-width: 700px;
  min-width: 520px;
  padding: 24px;
}

.Add-a-vet-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}

.Add-a-vet-title {
  color: var(--charcoal-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-weight: 350;
  line-height: 0.91;
  margin: 0;
  text-align: left;
}

.Add-a-vet-modal-subtitle {
  color: var(--charcoal-300);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  margin: 0 !important;
  white-space: break-spaces;
}

.My-pets-manual-add-input-titles {
  color: var(--pc-color-onyx-500);
  display: flex;
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-weight: 350;
  justify-content: space-between;
  line-height: 16px;
  margin-bottom: 8px;
}

.My-pets-manual-add-input-legend {
  color: rgba(29, 36, 51, 0.80);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: 16px;
  text-align: center;
}

.My-pets-manual-add-input-container {
  display: flex;
  flex-direction: column;
}

.My-pets-manual-add-input-container>input {
  padding-left: 8px !important;
}

.Add-a-vet-modal-buttons-container {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.Add-a-vet-cancel-button :hover {
  border-radius: 20px;
  color: var(--base-pc-color-white);
}

.My-pets-manual-add-save-btn {
  align-items: center;
  background-color: #22435e;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  width: 116px;
}

.My-pets-manual-add-save-btn>.Loading-button-text {
  font-family: 'Inter-Regular';
}

.My-pets-manual-add-save-btn:hover {
  background-color: #203e57;
}

.Add-a-vet-close-btn {
  background: none;
  border: none !important;
  display: flex;
  justify-content: flex-end;
  outline: none !important;
  padding: 0 8px 8px 8px;
}

.My-pets-manual-add-close-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.My-pets-manual-add-close-btn:focus {
  outline: none;
}

.My-pets-manual-add-cancel-btn {
  background-color: #b0bbbf;
  border-radius: 5px;
  border: 1px solid #b0bbbf;
  color: #fff;
  height: 48px;
  margin-right: 16px;
  width: 116px;
}

.My-pets-manual-add-cancel-btn:hover {
  background-color: #b0b0b0;
}

.My-pets-manual-add-error-message {
  color: #cc0000;
  font-family: 'Inter-Regular';
  font-size: 13px;
  margin-top: 8px;
}
