.Dropdown-container-error .Dropdown-header {
  border-color: #f85f25;
}

.Dropdown-error {
  color: #f85f25;
}

.Dropdown-list-hidden {
  opacity: 0;
  pointer-events: none;
  top: -10px
}

.Dropdown-list-overlay-container {
  background-color: transparent;
  opacity: 0.5;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.Dropdown-container {
  min-height: 37px;
  position: relative;
}

.Dropdown-header {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  border: 1px solid var(--pc-color-cotton-300);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  line-height: normal;
  padding: 8px 9px;
  width: 100%;
}

.Dropdown-header p {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  line-height: 1.25;
  margin: 0;
}

.Dropdown-header img {
  height: 16px;
  width: 16px;
}

.Dropdown-list {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  margin-top: 42px;
  max-height: 300px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.Dropdown-list > ul {
  height: fit-content;
  margin: 0;
  padding: 8px 0px 7px;
  width: 100%;
}

.Dropdown-placeholder {
  color: var(--pc-color-onyx-300) !important;
  font-family: 'Gotham-Regular';
}

.Dropdown-header-disabled {
  background-color: var(--pc-color-cotton-300);
}
