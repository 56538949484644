.Change-coverage-preview-submit-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 0 48px 0;
}

.Change-coverage-preview-submit-container > h3 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 16px;
}

.Change-coverage-preview-submit-container > p {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 48px;
  text-align: center;
  width: 660px;
}

.Preview-submit-plan-container {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.Preview-submit-plan-top-row {
  align-items: flex-start;
  border-bottom: 1px solid var(--figo-primary-deep-blue-100);
  border-style: none none solid none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  width:  100%;
}

.Preview-pet-icon {
  height: 38px;
  width: 38px;
}

.Preview-pet-icon path:first-child {
  fill: var(--figo-primary-deep-blue-100);
}

.Preview-pet-name-updated-plan {
  color: var(--pc-color-onyx-500);
  display: flex;
  flex-direction: column;
  font-family: 'IvyEpic-Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
}

.Preview-plan-info-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
}

.Preview-plan-info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
}

.Preview-plan-info-value-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
}

.Preview-plan-info-value-row > img {
  height: 16px;
  width: 16px;
}

.Preview-plan-info-name,
.Preview-plan-info-name-changed {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
}

.Preview-plan-info-name-changed {
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
}

.Preview-plan-info-value,
.Preview-plan-info-value-changed {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 24px;
  text-align: right;
}

.Preview-plan-info-value-changed {
  text-decoration: line-through;
}

.Preview-plan-info-initial-value {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}


.Preview-plan-info-powerups-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;
  width: 100%;
}

.Preview-plan-info-note {
  align-items: flex-start;
  align-self: center;
  background-color: var(--pc-color-yellow-100);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  margin: 48px 0 0 0;
  padding: 16px;
  width: 988px;
}

.Preview-plan-info-note-top-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  margin-bottom: 6.5px;
}

.Preview-plan-info-note-top-row > img {
  height: 20px;
  width: 20px;
}

.Preview-plan-info-note-top-row > span {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Preview-plan-info-note-text,
.Preview-plan-info-note-text-contact-us > span {
  color: var(--pc-color-onyx-400);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Preview-plan-info-note-text-contact-us > span {
  font-family: 'Gotham-Medium';
}

.Preview-plan-info-note-text-contact-us {
  background-color: transparent;
  border: none;
  padding: 0;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Preview-plan-info-note {
    width: 624px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Change-coverage-preview-submit-container {
    padding: 32px 24px;
  }

  .Change-coverage-preview-submit-container > p {
    width: auto;
  }

  .Change-coverage-preview-submit-container > h3,
  .Preview-pet-name-updated-plan > span,
  .Preview-plan-info-note-text,
  .Preview-plan-info-name-changed,
  .Preview-plan-info-name {
    text-align: left;
  }

  .Preview-plan-info-note-text {
    margin-left: 30px;
  }

  .Preview-submit-plan-container,
  .Preview-plan-info-note {
    width: 100%;
  }

  .Preview-plan-info-name,
  .Preview-plan-info-name-changed {
    flex: 1;
  }

  .Preview-plan-info-value-row {
    flex: 2;
  }
}
