.Payment-method-card-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 88px;
  padding: 0 24px 0 25.25px;
  position: relative;
  width: 100%;
}

.Payment-method-card-container[data-default=true] {
  border: 2px solid var(--pc-color-leaf-400);
}

.Payment-method-card-default-label {
  background-color: var(--pc-color-leaf-400);
  border-radius: 4px;
  color: var(--base-pc-color-white);
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 12px;
  gap: 8px;
  letter-spacing: 0.4px;
  padding: 4px 8px;
  position: absolute;
  top: -12px;
}

.Payment-method-card-data-container {
  align-items: center;
  display: flex;
  height: 100%;
}

.Payment-method-card-icon {
  height: 40px;
  margin-right: 10.5px;
  width: 40px;
}

.Payment-method-card-credit-card {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 19px;
  margin-bottom: 0;
  text-align: left;
}

.Payment-method-card-type {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 19px;
  margin-bottom: 0;
  text-align: left;
}

.Payment-method-card-credit-card span {
  margin-left: 24px;
}

.Payment-method-card-exp {
  color: #6d6d6d;
  font-family: 'Gotham-Medium';
  font-size: 12px;
  margin-left: 24px;
}

.Payment-method-card-icon-checked {
  height: 16px;
  margin: 0 4px 0 24px;
  scale: 1.5;
  width: 16px;
}

.Payment-method-card-default {
  color: var(--pc-color-leaf-400);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0em;
  line-height: 19px;
  text-align: left;
}

.Payment-method-card-button-details {
  align-items: center;
  background-color: transparent;
  color: var(--pc-color-blue-300);
  cursor: pointer;
  display: flex;
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 27px;
  justify-content: space-between;
  letter-spacing: 2%;
  line-height: 16px;
  padding-bottom: 2px;
  padding: 4px 0 0;
  text-align: center;
  text-decoration: underline;
  text-transform: capitalize;
  text-underline-offset: 3px;
  width: 33px;
}

.Payment-method-card-tooltip-container {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 8px 14px 0px #00000029;
  height: 40px;
  position: absolute;
  right: 16px;
  top: 65px;
  width: 250px;
}

.Payment-method-card-tooltip-container ul {
  list-style: none;
  margin-bottom: 0;
  padding-inline-start: 0;
}

.Payment-method-card-tooltip-button {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 18.5px;
  width: 100%;
}

.Payment-method-card-tooltip-button div {
  display: flex;
  width: 100%;
}

.Payment-method-card-tooltip-button p {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 0 8px;
}

.Payment-method-card-tooltip-button:hover {
  background-color: var(--pc-color-cotton-100);
}

.Payment-method-card-info-wrapper {
  display: flex;
  flex-wrap: wrap;
}

/* Mobile */
@media (max-width: 568px) {
  .Payment-method-card-container {
    padding: 0 12px;
  }

  .Payment-method-card-credit-card {
    font-size: 12px;
    max-width: 120px;
  }

  .Payment-method-card-credit-card span {
    margin-left: 0;
  }

  .Payment-method-card-info-wrapper {
    gap: 5px;
  }

  .Payment-method-card-icon-checked {
    height: 12px;
    margin-left: 4px;
    width: 12px;
  }

  .Payment-method-card-default {
    font-size: 12px;
  }

  .Payment-method-card-button-details {
    font-size: 12px;
    margin-left: 4px;
    padding: 0;
  }
}
