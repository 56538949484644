.Figo-select-container {
  display: flex;
  flex-direction: column;
  height: 41px;
  margin-bottom: 12px;
  position: relative;
  width: 100%;
}

.Figo-select-container > p {
  margin-bottom: 8px;
}

.Figo-select-container-error {
  padding-bottom: 18px;
}

.Figo-select-header {
  align-content: center;
  align-items: center;
  background-color: transparent;
  border-color: #d9dee1;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-height: 41px;
  outline-color: #2fb3b4;
  padding: 0;
  width: 100%;
}

.Figo-select-header:disabled {
  border-color: var(--pc-color-cotton-200);
}

.Figo-select-header-error {
  border-color: #f85f25;
}

.Figo-select-header-error-message {
  color: #f85f25;
  font-size: 13px;
  padding: 4px 1px 0 0;
}

.Figo-select-selected-item-placeholder {
  color: #7e888c;
}

.Figo-select-selected-item-text {
  color: #3d484d;
}

.Figo-select-selected-item {
  align-items: center;
  display: flex;
  min-height: 41px;
  pointer-events: none;
  text-indent: 14px;
  width: 100%;
}

.Figo-select-selected-item-disabled {
  background-color: var(--pc-color-cotton-200);
  color: #7e888c94;
}

.Figo-select-selected-item > p {
  font-size: 14px;
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Figo-select-list-overlayed-container {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

.Figo-select-list {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .08);
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  max-height: 261px;
  overflow-y: auto;
  padding-bottom: 4px;
  position: absolute;
  top: 23px;
  transition: all 0.3s ease;
  width: 100%;
  z-index: 2;
}

.Figo-select-list > ul {
  margin: 0px;
  padding: 0px;
}

.Figo-select-list-hidden {
  opacity: 0;
  pointer-events: none;
  top: -10px;
}

.Figo-select-list-item-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 5px 10px 2px 9px;
  min-height: 30px;
  outline-color: #2fb3b4;
}

.Figo-select-list-item {
  align-items: center;
  background-color: #fff;
  border-color: #fff;
  border-radius: 4px;
  color: #3d484d;
  display: flex;
  font-size: 13.33px;
  height: 36px;
  justify-content: flex-start;
  outline-color: #2fb3b4;
  padding: 0px 5px;
  text-align: left;
  transition: all 0.25s ease;
  width: 100%;
}

.Figo-select-list-item:hover {
  background-color: rgba(184, 237, 232, 0.5);
  border-color: rgba(184, 237, 232, 0.5);
  outline-color: rgba(184, 237, 232, 0.5);
}

.Figo-select-list-item-selected {
  background-color: #b8ede8;
  border-color: #b8ede8;
}

.Figo-select-arrow-closed {
  height: 20px;
  pointer-events: none;
  transition: all 0.3s ease;
  width: 20px;
}

.Figo-select-arrow-open {
  transform: rotate(180deg);
}

.Figo-select-arrow-container {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: 0;
  position: absolute;
  right: 8px;
  z-index: 3;
}

.Figo-select-search-input {
  align-items: center;
  border-radius: 5px;
  border-style: none;
  color: #3d484d;
  display: flex;
  font-family: 'Inter-Medium';
  font-size: 14px;
  height: max-content;
  justify-content: center;
  outline: 0;
  pointer-events: all;
  position: relative;
  text-indent: 4px;
  width: 100%;
  z-index: 3;
}

.Figo-select-search-input::placeholder {
  color: #d9dee1;
  font-family: "Inter-Medium";
  font-size: 16px;
  opacity: 1;
}

@media (max-width: 568px) {
  .Figo-select-container-landing {
    width: 100% !important;
  }
}

/* Safari 9.0 and up support */
@supports (-webkit-hyphens:none)
{
  .Figo-select-container-error-safari {
   height: auto;
   margin-bottom: -14px;
  }
}
