.iframe-container {
  background-color: rgba(30, 34, 38, 0.6);
  height: 95%;
  max-height: 650px;
  overflow-x: hidden;
  width: 100%;
  z-index: 1;
}

.iframe-content {
  border: 0;
  height: 100%;
  width: 111%;
  z-index: -1;
}

.iframe-padding {
  padding: 0 80px 0 0;
}
