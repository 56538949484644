.Claims-screen {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 48px;
}

.Claims-screen-cards-container {
  display: block;
  margin: 18px 32px 32px;
}

.Claims-screen-selectors-container {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eceef0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  width: 100%;
}

.Claims-screen-main-title {
  align-self: flex-start;
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
}

.Claims-screen-secondary-title {
  align-self: flex-start;
  color: #000;
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  padding: 2px 2px 12px;
}

.Claims-screen-cards-container > h2:nth-child(n+2) {
  margin: 36px 0 12px 0;
}

.Claims-screen-selector-row {
  display: flex;
  justify-content: flex-start;
}

.Claims-screen-filter-row {
  align-items: center;
  display: flex;
}

.Claims-screen-buttons-row {
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  padding: 12px 38px 12px 32px;
}

.Claims-screen-closed-claim-container {
  margin-left: 3px;
}

.Claims-screen-shimmer {
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-top: 16px;
}

.Claims-screen-shimmer-container {
  display: flex;
  height: 137px;
  justify-content: space-between;
  padding: 24px 32px 32px;
  width: 100%;
}

.Claims-screen-shimmer-label {
  height: 16px;
  margin: 8px;
  width: 140px;
}

.Claims-screen-shimmer-img {
  height: 56px;
  margin: 8px;
  width: 56px;
}

.Claims-screen-shimmer-title {
  height: 32px;
  margin: 2px 2px 12px;
  padding: 2px 2px 12px;
  width: 250px;
}

.Claims-screen-shimmer-row {
  height: 100%;
  width: 100%;
}

.Claims-screen-result-text-container {
  display: flex;
  justify-content: center;
  margin-right: 0rem;
  padding: 16px 0px 16px;
}

.Claims-screen-result-text {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Claims-screen-no-match-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 380px;
  justify-content: flex-start;
}

.Claims-screen-no-match-title {
  color: var(--base-pc-color-black, #000);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-top: 72px;
  text-align: center;
}

.Filter-claims-clear-button {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 1px solid var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 35px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  min-width: 140px;
  text-align: center;
  text-transform: capitalize;
  white-space: pre;
}

.Filter-claims-clear-button:hover {
  background-color: var(--pc-color-blue-400);
  color: var(--base-pc-color-white);
}

.Load-more-claims-button {
  -ms-text-underline-position: under;
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  cursor: pointer;
  margin-top: 32px;
  text-decoration: underline;
  text-underline-position: under;
}

@media (max-width: 1200px) {
  .Filter-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
