.Costco-quote-modal-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 24px 36px 24px;
  width: 520px;
}

.Costco-quote-modal-main-container > img {
  margin-top: 20px;
  width: 150px;
}

.Costco-quote-modal-main-container > h1 {
  color: var(--charcoal);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin: 12px 0 0 0;
}

.Costco-quote-modal-main-container > span {
  color: var(--charcoal);
  font-family: "Gotham-Book";
  font-size: 16px;
  margin: 8px 0 0 0;
  text-align: center;
  width: 385px;
}

.Costco-quote-modal-main-container > span > span {
  font-family: "Gotham-Medium";
}

.Costco-quote-modal-button {
  align-items: center;
  border-radius: 20px;
  border-width: 1px;
  display: flex;
  justify-content: center;
  min-width: 351px;
  padding: 10px 16px 8px 16px;
}

.Costco-quote-modal-button > span {
  font-family: "Gotham-Rounded";
  font-size: 16px;
  line-height: normal;
}

.Costco-quote-modal-button-blue {
  background-color: var(--pc-color-blue-300);
  border-color: var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
}

.Costco-quote-modal-button-white {
  background-color: var(--base-pc-color-white);
  border-color: var(--pc-color-onyx-500);
  color: var(--pc-color-onyx-500);
}
