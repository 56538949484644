.Pet-cloud-input-container {
  position: relative;
  width: 100%;
}

.Pet-cloud-input-label {
  color: var(--pc-color-onyx-500);
  display: block;
  font-family: 'Gotham-Rounded-Bold';
  font-size: 14px;
  letter-spacing: -0.07px;
  line-height: 16px;
  margin-bottom: 8px;
  opacity: 1;
  pointer-events: none;
}

.Pet-cloud-input-control {
  align-items: center;
  align-self: stretch;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  border: 1px solid var(--pc-color-onyx-100);
  display: flex;
  padding: 8px 9px;
  width: 100%;
}

.Pet-cloud-input-control:focus {
  border: 1px solid var(--pc-color-onyx-100) !important;
  box-shadow: none !important;
  outline: none !important;
}

.Pet-cloud-input-control::placeholder {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Input-control:focus + .Input-label,
.Input-control:placeholder-shown + .Input-label,
.Input-control:not([value=""]) + .Input-label
{
  font-size: 14px;
  transform: translateY(-20px) scale(.8);
}

.Pet-cloud-input-control-extra-space {
  padding-right: 45px;
}

.Pet-cloud-input-extra-item {
  position: absolute;
  right: 9px;
  bottom: 10px;
}

.Pet-cloud-input-error, .Pet-cloud-input-error:focus {
  border: 1px solid var(--error) !important;
}