.Tag-pet-modal-selector-container {
  width: 317px;
}

.Tag-pet-modal-error {
  bottom: 115px;
  color: #f85f25;
  left: 0;
  max-height: 48px;
  overflow: hidden;
  padding: 0 30px;
  position: absolute;
  right: 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
