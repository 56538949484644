.Change-coverage-powerup-card {
  align-items: flex-start;
  background: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: var(--pc-space-5, 24px);
}

.Change-coverage-powerup-card-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Change-coverage-powerup-card-top-row > h4 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin: 0;
}

.Change-coverage-powerup-card-status-pill {
  align-items: center;
  background: var(--figo-primary-deep-blue-15);
  border-radius: 100px;
  display: flex;
  gap: 4px;
  justify-content: center;
  padding: 4px 8px;
}

.Change-coverage-powerup-card-status-pill > img {
  height: 16px;
  width: 16px;
}

.Change-coverage-powerup-card-status-pill > span {
  color: var(--figo-primary-deep-blue-100);
  font-family: "Gotham-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 14px;
  letter-spacing: 0.28px;
}

.Change-coverage-powerup-card-detail {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.Change-coverage-powerup-card-learn-more {
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--figo-primary-deep-blue-100);
  border-style: none none solid none;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 4px 0px;
}

.Change-coverage-powerup-card-learn-more > span {
  color: var(--figo-primary-deep-blue-100);
  text-align: center;
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: 0.32px;
}

/* Mobile */
@media (max-width: 568px) {
  .Change-coverage-powerup-card-top-row > h4,
  .Change-coverage-powerup-card-detail {
    text-align: left;
  }
  
  .Change-coverage-powerup-card-status-pill {
    height: fit-content;
    min-width: fit-content;
  }
}
