.My-account-navigation-container {
  align-items: flex-start;
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.My-account-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  margin: 32px 56px;
}

.My-account-container-links {
  align-items: center;
  display: flex;
  gap: 80px;
  justify-content: center;
  margin-bottom: 30px;
}

.My-account-container-links > a,
.My-account-container-links > a:hover {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-Medium";
  font-size: 14px;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.My-account-grid-container {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 522px 522px;
  pointer-events: none;
}

.My-account-card-item {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  height: 114px;
  padding: 24px;
  pointer-events: all;
  width: 100%;
}

.My-account-card-icon {
  height: 24px;
  width: 24px;
}

.My-account-card-icon-background {
  border-radius: 100px;
  background-color: var(--pc-color-blue-100);
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  padding: 12px;
  width: 48px;
}

.My-account-card-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  text-align: left;
}

.My-account-card-info > h4 {
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -1px;
  line-height: 24px;
  margin-bottom: 8px;
  width: 90%;
}

.My-account-card-info > p {
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.My-account-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin-bottom: 24px;
  text-transform: capitalize;
  width: 100%;
}

.Breadcrumbs-container {
  margin: 32px 0 0 48px;
  max-height: 36px;
}

.Breadcrumb-item-account,
.Breadcrumb-item-account-active {
  background-color: var(--pc-color-cotton-300);
  border: none;
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.408px;
  line-height: normal;
  padding: 0;
}

.Breadcrumb-item-account-active {
  color: var(--pc-color-onyx-200);
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .My-account-container {
    display: flex;
    flex-wrap: wrap;
    margin: 32px 24px;
  }

  .My-account-grid-container {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 348px 348px;
    pointer-events: none;
  }

  .My-account-card-item {
    height: 172px;
  }
}
