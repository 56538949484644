.Microchip-screen-container {
  height: 100%;
  min-height: 100vh;
  padding: 32px 48px;
}

.Microchip-screen-header-container {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.Microchip-screen-page-title {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
  margin-bottom: 0;
  text-transform: capitalize;
  width: 100%;
}

.Microchip-screen-button-add-microchip,
.Microchip-screen-button-edit-microchip {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  cursor: pointer;
  display: flex;
  font-family: 'Gotham-Rounded';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 27px;
  justify-content: space-between;
  letter-spacing: 2%;
  line-height: 16px;
  max-width: 160px;
  padding: 4px 0 0;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

.Microchip-screen-button-add-microchip,
.Microchip-screen-button-edit-microchip {
  border-bottom: 1px solid var(--pc-color-blue-300);
}

.Microchip-screen-button-image {
  color: var(--pc-color-blue-300);
  height: 16px;
  margin: 0 8px 1px 0;
  width: 16px;
}

.Microchip-screen-card {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  padding: 32px 48px;
  width: 100%;
}

.Microchip-screen-card-left-side {
  display: flex;
  gap: 48px;
}

.Microchip-screen-title-information {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin-bottom: 12px;
}

.Microchip-screen-information {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Breadcrumbs-microchip-container {
  margin: 0 0 16px 0;
}

.Breadcrumbs-microchip-inner-container {
  align-items: center;
  display: flex;
  height: 100%;
  list-style: none;
  margin-bottom: 0;
  padding-inline-start: 0;
}

.Breadcrumbs-microchip-inner-container img {
  height: 16px;
  width: 16px;
}

.Breadcrumbs-microchip-item button,
.Breadcrumbs-microchip-item-active {
  background-color: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.408px;
  line-height: normal;
  padding: 0;
}

.Breadcrumbs-microchip-item-active {
  color: var(--pc-color-onyx-200);
  min-width: 35px;
}

.Breadcrumbs-microchip-item button {
  border: none;
}

.Breadcrumbs-divider {
  margin: 0 12px 3px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Microchip-screen-container {
    padding: 32px 24px;
  }

  .Microchip-screen-card-left-side {
    flex-direction: column;
    gap: 32px;
  }
}
