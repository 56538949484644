.Pet-Cloud-confirm-changes-modal .Generic-modal-buttons-container {
  justify-content: center;
}

.Pet-Cloud-confirm-changes-modal-text-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.Pet-Cloud-confirm-changes-modal-text {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded";
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin-top: 25.33px;
}
.Pet-Cloud-confirm-changes-modal
  .Generic-modal-buttons-container
  .Action-button,
.Pet-Cloud-confirm-changes-modal
  .Generic-modal-buttons-container
  .Action-button-white {
  height: 40px;
  width: 220px;
}

.Generic-modal-close-button:focus {
  outline: none;
}

.Generic-modal-close-button > img {
  display: none;
  outline: none;
}
