.List-item-1 {
  padding-left: 24px;
}

.List-item-2 {
  padding-left: 45px;
}

.List-item-container {
  cursor: pointer;
  display: block;
  font-family: 'Gotham-Regular';
  font-size: 14px;
  height: 40px;
  line-height: 1.25;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 10px 16px 10px 16px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.List-item-selected {
  display: flex;
  justify-content: space-between;
}

.List-item-selected,
.List-item-container:hover {
  background-color: var(--pc-color-cotton-100);
}
