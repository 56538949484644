.Restore-button-container {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  width: 175px;
}

.Restore-button-container-disabled {
  align-items: center;
  background-color: #b0bbbf;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  width: 175px;
}

.Restore-button-text {
  color: var(--base-pc-color-white);
  font-family: "Gotham-medium";
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  pointer-events: none;
}

.Restore-button-icon {
  height: 16px;
  margin-right: 8px;
  width: 16px;
}
