.Loading-button-container {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 1px solid var(--pc-border-radius-xl);
  color: #fff;
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  margin-top: 24px;
  min-width: 140px;
  padding: 12px 16px;
  text-align: center;
}

.Loading-button-container:hover:enabled {
  background-color: var(--pc-color-blue-300);
  border-color: #b8ede8;
}

.Loading-button-text {
  font-family: "Inter-SemiBold";
  font-size: 16px;
  text-align: center;
}

.Loading-button-icon {
  margin-right: 8px;
}

.Loading-button-container:disabled {
  background-color: var(--pc-color-cotton-200);
  border: none;
  color: var(--pc-color-onyx-200);
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  margin-top: 24px;
  min-width: 140px;
  padding: 12px 16px;
  text-align: center;
}
