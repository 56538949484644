.Insurance-account-container {
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  margin: 18px 48px;
}

.Insurance-account-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  width: 100%;
}

.Insurance-account-description {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin: 0;
  width: 80%;
}

.Insurance-account-info-container {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  margin: 24px 0 0 0;
  min-height: 476px;
  padding: 32px 48px;
  width: 1084px;
}

.Insurance-account-shimmer-container {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 18px 48px;
  padding: 32px 48px;
  width: 70%;
}

.Insurance-account-shimmer-element {
  height: 40px;
  width: 60%;
}

.Insurance-account-subtitle {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
  margin-bottom: -16px;
  margin-bottom: 0;
  text-transform: capitalize;
  width: 100%;
}

.Insurance-account-subtitle-description {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 16.8px;
  margin-bottom: -8px;
  max-width: 446px;
}

.Insurance-account-buttons-container {
  display: flex;
  justify-content: space-between;
  width: 446px;
}

.Insurance-account-button-contact-us,
.Insurance-account-button-contact-submit {
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  font-family: "Gotham-Medium";
  gap: 8px;
  height: 40px;
  justify-content: center;
  padding: 12px 16px;
  width: 214px;
}

.Insurance-account-button-contact-us {
  background: var(--base-pc-color-white);
  border: 1px solid var(--pc-border-radius-xl);
  color: var(--pc-color-onyx-500);
}

.Insurance-account-button-contact-submit {
  background-color: var(--pc-color-blue-300);
  border: 1px solid var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
  transition: 0.3s;
}

.Insurance-account-button-contact-submit:disabled {
  background-color: var(--pc-color-cotton-200);
  border-color: var(--pc-color-cotton-200);
  color: var(--pc-color-onyx-200);
  cursor: not-allowed;
}

.Insurance-account-title-form {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 16px;
  line-height: 19.2px;
  margin-bottom: -28px;
}

.Insurance-account-checkboxes-container {
  margin-top: 19px;
}
.Insurance-account-checkbox {
  align-items: center;
  display: flex;
  height: 24px;
  margin-bottom: 18px;
  width: 100%;
}

.Insurance-account-checkbox span {
  margin-left: 10px;
}

.Insurance-account-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -13px 0 35.5px;
  max-width: 436px;
  width: 436px;
}

.Insurance-account-inputs-container input,
.Insurance-account-inputs-container select {
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 33px !important;
  line-height: 16.8px;
  margin-right: 8px;
  padding: 9px 8px 7px;
}

.Insurance-account-inputs-container-row {
  flex-direction: row;
}

.Insurance-account-inputs33-contanier {
  display: flex;
  height: 42px;
  justify-content: space-between;
  width: 100%;
}

.Insurance-account-select-33 {
  align-items: center;
  align-self: stretch;
  appearance: none;
  background-color: var(--base-pc-color-white);
  background-image: url('../../../../assets/chevron-fill.svg');
  background-position: 95% center;
  background-repeat: no-repeat;
  border-radius: 8px;
  border: 1px solid var(--pc-color-onyx-100);
  color: var(--pc-color-onyx-300);
  display: flex;
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
  min-width: 140px;
  padding-right: 20px; 
  padding: 9px 8px 7px;
}
.Insurance-account-select option {
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}

.Insurance-account-input-33 {
  width: 140px;
}

.Insurance-account-input-50 {
  width: 214px;
}

.Insurance-account-input-100 {
  width: 436px;
}

.Insurance-account-input-error {
  border-color: var(--pc-color-hydrant-400);
}

.Insurance-account-button-contact-us,
.Insurance-account-button-contact-submit span {
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0.02em;
  line-height: 16px;
  text-align: center;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Insurance-account-container {
    margin: 18px 32px;
  }

  .Insurance-account-info-container {
    margin: 32px 0 0 0;
    width: 720px;
  }
}
