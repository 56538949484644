.File-claim-button-blue {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 1px solid var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 35px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  min-width: 140px;
  text-align: center;
  white-space: pre;
}

.File-claim-button-blue:hover {
  background-color: var(--pc-color-blue-400);
  color: var(--base-pc-color-white);
}

