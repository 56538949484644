.Uninsured-pet-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 85vh;
}

.Uninsured-pet-card {
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  margin: 24px 48px 0;
  width: 100%;
}

.Uninsured-pet-card-title {
  align-self: flex-start;
  color: #000;
  font-family: 'Gotham-Rounded';
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  margin: 24px 48px 0;
}

.Uninsured-pet-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
}

.Uninsured-pet-content-subtitle {
  color: #000;
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-weight: 350;
  line-height: 28px;
  margin-top: 12px;
  margin: 0;
  text-align: center;
  width: 90%;
}

.Uninsured-pet-content-paragraph {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  margin: 20px 0;
  text-align: center;
}

.Uninsured-pet-buttons {
  display: flex;
  gap: 7px;
}

.Uninsured-pet-buttons-get-quote {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 1px solid var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  margin-top: 12px;
  min-width: 140px;
  padding: 0px 60px;
  text-align: center;
}

.Uninsured-pet-buttons-learn-more {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 20px;
  border: 1px solid var(--pc-border-radius-xl);
  color: #000;
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.32px;
  line-height: 16px;
  margin-top: 12px;
  min-width: 140px;
  padding: 0px 60px;
  text-align: center;
  text-decoration: none;
}

.Uninsured-pet-buttons-learn-more:hover {
  background-color: var(--pc-color-cotton-300) !important;
  color: var(--pc-color-onyx-500);
}

.Uninsured-pet-buttons-get-quote:hover {
  background-color: var(--pc-color-blue-400);
  color: var(--base-pc-color-white);
}

.Uninsured-pet-card-pet-card-profile {
  margin: 24px 48px 0;
}

@media (max-width: 1200px) {
  .Uninsured-pet-container {
    height: 60vh;
  }

  .Uninsured-pet-card {
    height: 85%;
  }
}

@media (max-width: 610px) {
  .Uninsured-pet-buttons {
    display: block;
  }

  .Uninsured-pet-content-subtitle {
    text-align: center;
    width: 400px;
  }
}
