.No-vet-modal-content-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 520px;
}

.No-vet-modal-container {
  padding: 32px 40px;
}

.No-vet-modal-container > h4 {
  color: var(--title);
  font-family: 'Inter-Semibold';
  font-size: 22px;
  margin: 0 0 24px;
  max-width: 276px;
  text-align: center;
}

.No-vet-modal-content-container > h4 {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin: 8px 0 16px 0;
  text-align: center;
  width: 70%;
}

.No-vet-modal-container > p {
  color: #3d484d;
  margin-bottom: 32px;
  max-width: 318px;
  text-align: center;
}

.No-vet-modal-content-container > p {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Book';
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin-bottom: 43.5px;
  text-align: center;
}

.No-vet-modal-action-button {
  background-color: var(--pc-color-blue-300);
  border-radius: 8px;
  border: none;
  color: #fff;
  font-family: 'Inter-Semibold';
  padding: 14px 32px;
}

.No-vet-modal-placeholder-img {
  height: 60px;
  margin: 19.5px 0 0 0;
  width: 60px;
}

.No-vet-modal-buttons-container {
  align-self: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.No-vet-modal-cancel-button,
.No-vet-modal-save-button {
  align-items: center;
  border-radius: 20px;
  border: 1px solid var(--pc-border-radius-xl);
  display: flex;
  font-family: 'Gotham-Medium';
  gap: 8px;
  height: 40px;
  justify-content: center;
  padding: 12px 16px;
  width: 143.5px;
}

.No-vet-modal-cancel-button {
  background: var(--base-pc-color-white);
}

.No-vet-modal-save-button {
  background-color: var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
}
