.Message-modal-body-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  width: 317px;
}

.Message-modal-body-text {
  font-size: 15px;
  text-align: center;
}

.Message-modal-body-title {
  color: #22435e;
  font-family: "Inter-SemiBold";
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 16px;
}

.Message-modal-button {
  border-radius: 8px;
  border: none !important;
  min-height: 60px;
  width: 150px;
}

.Message-modal-card {
  align-items: center;
  border-radius: 12px !important;
  display: flex;
  max-height: 825px;
}

.Message-modal-card-content {
  align-items: center;
  border-radius: 12px !important;
  display: flex;
  flex-direction: column;
  margin: 32px 12px 32px 12px;
  max-height: 825px;
  min-width: 349px;
  overflow-y: auto;
  padding: 0px 20px 0px 20px;
  scrollbar-color: #2fb3b4 #eceef0;
  scrollbar-width: thin;
}

.Message-modal-card-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.Message-modal-card-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eceef0; 
  border-radius: 10px;
}

.Message-modal-card-content::-webkit-scrollbar-thumb {
  background: #2fb3b4;
  border-radius: 4px;
}

.Message-modal-card-content::-webkit-scrollbar-thumb:hover {
  background: #b8ede8; 
}

.Message-modal-image {
  height: 64px;
  margin-bottom: 16px;
  width: 64px;
}

/* Mobile */
@media (max-width: 568px) {
  .Message-modal-card {
    width: 90%;
  }
}
