.My-pets-item-card-container {
  align-items: center;
  background-color: #fff;
  border-color: #eceef0;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display:  flex;
  flex-direction: column;
  height: 323px;
  margin: 64px 16px 0 16px;
  width: 256px;
}

.My-pets-item-card-image-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: -6px;
  position: relative;
}

.My-pets-item-card-image-container > h1 {
  color: #fff;
  font-family: "Inter-Bold";
  font-size: 31.11px;
  line-height: normal;
  position: absolute;
  top: -4px;
}

.My-pets-item-card-image-button {
  background: none;
  border: none;
  color: #b8ede8;
  font-family: "Inter-Bold";
  font-size: 12.44px;
  position: absolute;
  top: 39.56px;
}

.My-pets-item-card-image {
  border-radius: 50%;
}

.My-pets-item-card-profile-image-icon {
  background-color: var(--pc-color-blue-300);
  background-image: url('./../../assets/myPets/camera-my-pets.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  border-radius: 100px;
  height: 41px;
  width: 41px;
}

.My-pets-item-card-image-pet-list {
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.16);
  margin-top: -32px;
  max-height: 112px;
  min-height: 112px;
  width: 112px;
}

.My-pets-item-card-name-container {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: center;
  margin-top: 10px;
  width: 94%;
}

.My-pets-item-card-name {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #22435e;
  display: -webkit-box;
  font-family: 'Inter-SemiBold';
  font-size: 24px;
  line-height: normal;
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.My-pets-item-card-name-small {
  font-size: 18px;
}

.My-pets-item-card-breed-container {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-top: 3px;
  text-align: center;
  width: 100%;
}

.My-pets-item-card-breed-container > span {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #7e888c;
  display: -webkit-box;
  height: fit-content;
  line-height: normal;
  overflow: hidden;
  width: 90%;
}

.My-pets-item-card-row-container {
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;
  min-height: 48px;
  width: 216px;
}

.My-pets-item-card-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 70px;
}

.My-pets-item-card-row-header {
  color: #7e888c;
  font-family: 'Inter-Medium';
  font-size: 10px;
  line-height: normal;
}

.My-pets-item-card-row-content {
  color: #22435e;
  font-family: 'Inter-Medium';
  font-size: 13px;
  line-height: normal;
  margin-top: 4px;
}

.My-pets-item-card-col-divider {
  background-color: #eceef0;
  height: 40px;
  width: 1px;
}

.My-pets-item-card-footer-button {
  background-color: #2fb3b4;
  border-radius: 8px;
  border-style: none;
  color: #fff;
  font-family: 'Inter-SemiBold';
  margin: 16px 0 28px 0;
  min-height: 48px;
  outline-color: #178a8a !important;
  transition: all 0.3s ease;
  width: 216px;
}

.My-pets-item-card-footer-button:hover {
  opacity: 0.8;
}

.My-pets-item-card-image-action {
  border-radius: 50%;
}

/* Tablet */
@media (max-width: 1200px) {
  .My-pets-item-card-name {
    margin-top: 16px;
  }

  .My-pets-item-card-breed > span {
    height: 54px;
  }
}
