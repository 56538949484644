.Young-pet-modal-container {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 381px;
}

.Young-pet-modal-title {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 22px;
  margin-top: 32px;
}

.Young-pet-modal-message {
  color: #3d484d;
  font-family: 'Inter-Regular';
  font-size: 16px;
  margin-top: 16px;
  text-align: center;
  width: 300px;
}

.Young-pet-button-container-blue {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  margin: 32px 0;
  width: 150px;
}

.Young-pet-button-text {
  color: #fff;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  pointer-events: none;
}
