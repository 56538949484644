.Confirm-primary-vet-modal-container {
  padding: 32px 99px;
}

.Confirm-primary-vet-modal-container > h2 {
  color: var(--title);
  font-family: 'Inter-Semibold';
  font-size: 22px;
  line-height: 20px;
  margin: 16px 0 8px;
}

.Confirm-primary-vet-modal-container > p {
  color: #1e2226;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-bottom: 24px;
  text-align: center;
  width: 322px;
}

.Confirm-primary-vet-modal-name {
  color: #1e2226;
  font-size: 15px;
  letter-spacing: -0.4px;
  line-height: 20px;
}

.Confirm-primary-vet-modal-address {
  color: #6d7579;
  font-family: 'Inter-Medium';
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 32px;
}

.Confirm-primary-vet-modal-back {
  background-color: #b0bbbf;
  border-radius: 5px;
  border: none;
  color: #fff;
  height: 40px;
  margin-right: 8px;
  width: 99px;
}

.Confirm-primary-vet-modal-confirm {
  background-color: #22435e;
  border-radius: 5px;
  border: none;
  color: #fff;
  height: 40px;
  width: 215px;
}

.Confirm-primary-vet-modal-confirm > .Loading-button-text {
  font-family: 'Inter-Regular';
}

.Confirm-primary-vet-modal-confirm:hover {
  background-color: #1f3e58;
}

.Confirm-primary-vet-modal-error {
  color: #cc0000;
  font-size: 12px;
}
