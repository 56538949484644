.File-claimbot-evie {
  align-items: flex-start;
  display: flex;
  margin-bottom: 24px;
}

.File-claimbot-evie > img {
  border-radius: 32px;
  height: 56px;
  width: 56px;
}

.File-claimbot-evie-bubble-message {
  -moz-box-shadow: 0px 2px 6px -2px rgba(162,162,162,0.5);
  -webkit-box-shadow: 0px 2px 6px -2px rgba(162,162,162,0.5);
  align-self: center;
  background-color: var(--pc-color-cotton-100);
  border-radius: 12px;
  box-shadow: 0px 2px 6px -2px rgba(162,162,162,0.5);
  height: fit-content;
  margin-left: 16px;
  padding: 16px 24px;
  position: relative;
  width: 454px;
  z-index: 0;
}

.File-claimbot-evie-bubble-message > span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.File-claimbot-evie-bubble-message > span > a {
  color: var(--pc-color-blue-300);
  text-decoration: underline;
}
