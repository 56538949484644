.Age-selector-container {
  display: flex;
  flex-direction: column;
  width: 224px;
}

.Age-selector-input-container {
  display: flex;
  flex-direction: row;
  height: 33px;
  width: 100%;
}

.Age-selector-input-label {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.07px;
  line-height: 16px;
}

.Age-selector-input {
  border-radius: 8px;
  border: 1px solid var(--pc-color-onyx-100);
  color: #202020;
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  height: 41px !important;
  line-height: normal;
  position: relative;
  width: 224px;
}

.Age-selector-arrow-closed {
  height: 16px;
  pointer-events: none;
  position: absolute;
  right: 9px;
  top: 10px;
  transition: all 0.3s ease;
  width: 16px;
}

.Age-selector-arrow-open {
  transform: rotate(180deg);
}

.Age-selector-input::placeholder {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Age-selector-input:focus {
  outline: none;
}

.Age-selector-input-error {
  background-color: #fef7f4 !important;
  border-color: rgb(248, 95, 37) !important;
  border-width: 1px !important;
}
