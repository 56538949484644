.Orca-get-quote-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
}

.Orca-get-quote-frame iframe {
  border: none;
  height: 100vh;
  width: 100%;
}
