.Coverage-overview-figo-progress-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  min-width: 574px;
  width: 100%;
}

.Coverage-overview-figo-progress-row,
.Coverage-overview-figo-progress-row-deductible {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Coverage-overview-figo-progress-row-deductible {
  margin-top: 55px;
}

.Coverage-overview-figo-progress-title {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Coverage-overview-figo-progress-values {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}

.Coverage-overview-progress {
  background-color: var(--pc-color-cotton-300);
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  height: 12px;
  margin-top: 8px;
  width: 100%;
}

.Coverage-overview-progress-fill,
.Coverage-overview-progress-fill-unlimited {
  background-color: var(--deep-blue-main);
  border-radius: 32px;
  height: 12px;
  transition: all 1.5s ease-in-out;
}

.Coverage-overview-progress-fill-unlimited {
  background-color: var(--dark-mint);
}

.Coverage-overview-progress-full {
  align-items: center;
  background-color: var(--pc-color-leaf-100);
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  margin-top: 16px;
  padding: 4px 8px;
}

.Coverage-overview-progress-full > img {
  height: 16px;
  width: 16px;
}

.Coverage-overview-progress-full > span {
  color: var(--pc-color-leaf-400);
  font-family: "Gotham-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.28px;
  line-height: 14px;
}

.Coverage-overview-progress-shimmer {
  height: 40px;
  margin-bottom: 55px;
  width: 574px;
}
