.Resource-card-card {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 345px;
}

.Resource-card-description {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 16px;
}

.Resource-card-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 24px;
}

.Resource-card-button {
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border-width: 0;
  color: var(--base-pc-color-white);
  cursor: pointer;
  font-family: "Gotham-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.32px;
  line-height: 16px;
  margin-top: 32px;
  padding: 12px 32px;
  width: fit-content;
}

/* Tablet */
@media (max-width: 1200px) {
  .Resource-card-card {
    width: 48%;
  }
}
