.Banner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 18px;
  margin-top: -40px;
  transition: margin-top 1s;
}

.Banner-container-show {
  margin-top: 0px;
}

.Banner-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Banner-icon-container {
  align-items: center;
  display: flex;
  flex-direction: row;  
  font-size: 12px;
}

.Banner-logo {
  border-radius: 8px;
  height: 40px;
}

.Banner-text-name-container {
 display: flex;
 flex-direction: column;
 justify-content: center;
}

.Banner-text-name-container span:first-child {
  font-family: "Inter-SemiBold";
  padding-left: 12px;
}

.Banner-text-name-container span:last-child {
  font-family: "Inter-Regular";
  padding-left: 11px;
}

.Banner-button {
  background: none;
  border: 0px none;
  color: #007aff;
  font-family: "Inter-Regular";
  font-size: 16px;
  height: 100%;
}

.Banner-button:hover {
  outline: none;
  outline: #007aff;
}

.Banner-button:active {
  outline: #007aff;
}

.Banner-hide {
  display: none;
}
