.Billing-premium-cost-orca-monthly-container {
  display: flex;
  flex-direction: column;
  min-width: 280px;
}

.Billing-premium-cost-orca-title {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-rounded";
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.Billing-premium-cost-orca-divider {
  background-color: var(--pc-color-onyx-500);
  height: 1px;
  width: 100%;
}

.Billing-premium-cost-orca-top-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 6px;
}

.Billing-premium-cost-orca-bottom-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Billing-premium-cost-orca-annual-container {
  display: flex;
  flex-direction: row;
  gap: 82px;
  justify-content: center;
}

.Billing-premium-cost-orca-divider-dashed {
  border-color: var(--pc-color-onyx-500);
  border-style: dotted;
  border-width: 1px 0 0 0;
  width: 100%;
}

.Billing-premium-cost-orca-light-text {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-book";
  font-size: 16px;
}

.Billing-premium-cost-orca-annual-container > h1 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-rounded";
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  padding: 0;
}

.Billing-premium-cost-orca-annual-container > span {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-rounded";
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  padding: 0;
}
