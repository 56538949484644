.File-viewer-container-multibrand {
  background-color: rgba(30, 34, 38, 0.6);
  display: flex;
  height: 95%;
  justify-content: center;
  max-height: 650px;
  overflow-x: hidden !important;
  overflow: auto;
  width: 100%;
}

.File-viewer-image,
.File-viewer-error-container {
  max-height: 95%;
  max-width: 100%;
}

.File-viewer-error-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.File-viewer-error-container > img {
  margin-top: 5%;
}

.File-viewer-error-container > p {
  color: #fff;
  font-family: 'Inter-Medium';
  font-size: 16px;
  height: 100%;
  margin-top: 5%;
  text-align: center;
  width: 50%;
}

/* Custom Scrollbar */
.File-viewer-container::-webkit-scrollbar,
.File-viewer-container-multibrand::-webkit-scrollbar {
  width: 8px !important;
}

.File-viewer-container::-webkit-scrollbar-track {
  background-color: rgba(236, 238, 240, 1);
}

.File-viewer-container-multibrand::-webkit-scrollbar-track {
  background-color: var(--base-pc-color-white);
}

.File-viewer-container::-webkit-scrollbar-thumb {
  background: #2fb3b4;
}

.File-viewer-container-multibrand::-webkit-scrollbar-thumb {
  background: var(--pc-color-blue-300);
}

.File-viewer-container::-webkit-scrollbar-thumb,
.File-viewer-container::-webkit-scrollbar-track,
.File-viewer-container::-webkit-scrollbar,
.File-viewer-containe-multibrandr::-webkit-scrollbar-thumb,
.File-viewer-container-multibrand::-webkit-scrollbar-track,
.File-viewer-container-multibrand::-webkit-scrollbar {
  border-radius: 4px;
}
