.File-claimbot-signature-container {
  display: flex;
  flex-direction: column;
  width: 454px;
}

.File-claimbot-signature-selector {
  display: flex;
  gap: 24px;
}

.File-claimbot-signature-type-option {
  background-color: var(--base-pc-color-white);
  border-color: var(--pc-color-cotton-300);
  border-style: none none solid none;
  border-width: 2px;
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 8px 0;
  transition: all 0.3s ease-in-out;
}

.File-claimbot-signature-type-option > svg {
  transition: all 0.3s ease-in-out;
}

.File-claimbot-signature-type-option-img {
  height: 20px;
  margin-right: 8px;
  stroke: var(--pc-color-onyx-200);
  width: 20px;
}

.File-claimbot-signature-type-option-img-selected {
  height: 20px;
  margin-right: 8px;
  stroke: var(--pc-color-onyx-500);
  width: 20px;
}

.File-claimbot-signature-type-selected {
  border-color: var(--pc-color-blue-300);
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-medium";
}

.File-claimbot-signature-content-container-drawing {
  margin-top: 32px;
}

.File-claimbot-signature-content-container-typing {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
}

.File-claimbot-signature-content-container-typing > input {
  background: var(--base-pc-color-white);
  border-radius: 8px;
  border: 1px solid var(--pc-color-grey-150);
  color: var(--charcoal-300);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: fit-content !important;
  line-height: normal;
  padding: 8px !important;
}

.File-claimbot-signature-error {
  color: var(--error);
  font-size: 12px;
  font-family: "Gotham-regular";
}

.File-claimbot-signature-row {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.File-claimbot-signature-row > span {
  color: var(--charcoal-500);
  font-family: "Gotham-rounded";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.07px;
  line-height: 16px;
}

.File-claimbot-signature-row > button {
  color: var(--pc-color-blue-300);
  font-family: "Gotham-rounded";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  background-color: var(--base-pc-color-white);
  border-style: none none solid none;
  border-color: var(--pc-color-blue-300);
  border-width: 1px;
  padding: 0 0 2px 0;
}

.File-claimbot-signature-content-container-typing > p {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.File-claimbot-signature-text-preview-container {
  border-color: var(--pc-color-cotton-300);
  border-style: none none solid none;
  border-width: 3px;
  height: 80px;
  margin-bottom: 40px;
  object-fit: contain;
  width: 100%;
}
