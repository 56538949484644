.Breadcrumb-item {
  background: transparent;
  border: none;
  color: var(--pc-color-blue-300);
  font-family: "Gotham-regular";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.408px;
  line-height: normal;
  padding: 0;
  transition: all 0.3s ease;
}

.Breadcrumb-item:hover {
  opacity: 0.5;
}

.Breadcrumb-item-active,
.Breadcrumb-item-active:hover {
  background: transparent;
  color: var(--pc-color-onyx-200);
}
