.Inbox-and-docs-empty-top-container {
  border-bottom: 1px solid #eceef0;
  padding-bottom: 19px;
}

.Inbox-and-docs-no-content {
  align-items: center;
  flex: 1;
  height: 271px;
  justify-self: center;
  margin-top: 108px;
  max-width: 361px;
}

.Inbox-and-docs-empty-title {
  color: #182f43;
  font-family: "Gotham-Medium";
  font-size: 25px;
  font-weight: 350px;
  line-height: 28px;
  letter-spacing: -0.125px;
  margin-top: 24px;
}

.Inbox-and-docs-empty-subtitle {
  color: #182f43;
  font-family: "Gotham-Regular";
  font-size: 20px;
  font-weight: 325px;
  line-height: 28px;
  letter-spacing: -0.1px;
}

.Inbox-and-docs-browse {
  font-family: "Gotham-Medium";
  margin: 0 5px;
  width: fit-content;
}

.Browse-text {
  border: none;
  color: inherit;
  padding: 0;
  background: none;
  cursor: pointer;
  text-decoration: underline;
}
