.Inbox-card-container {
  background-color: var(--base-pc-color-white);
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px var(---base-pc-color-black);
  cursor: pointer;
  height: 116px;
  justify-content: space-between;
  margin-top: 27px;
  padding: 22px 20px;
  width: 792px;
}

.Inbox-card-email-container {
  justify-content: center;
  margin-left: 22px;
}

.Inbox-card-email {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  line-height: 24px;
}

.Inbox-card-text {
  color: var(----pc-color-onyx-100);
  font-family: 'Inter-Regular';
  font-size: 14px;
}

.Inbox-card-unread-files {
  align-items: flex-start !important;
  justify-content: center;
  margin-right: 28px;
}

.Inbox-unread-files-amount {
  color: var(----pc-color-onyx-200);
  font-family: "Inter-Medium";
  font-size: 14px;
  position: relative;
}

.Inbox-unread-files-amount:before {
  background: var(--pc-color-leaf-300);
  border-radius: 50%;
  content: "";
  height: 9px;
  left: -15px;
  position: absolute;
  top: 5px;
  width: 9px;
}

.Inbox-unread-files-date {
  color: var(--pc-color-onyx-100);
  display: block;
  font-family: "Inter-Regular";
  font-size: 12px;
}

.Inbox-card-arrow-icon {
  height: auto;
  margin-right: 16px;
  width: 11px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Inbox-card-container {
    width: 100%;
  }

  .Inbox-title {
    padding-top: 20px;
  }
}

.Blue-background {
  align-items: center;
  background-color: var(--pc-color-blue-100);
  border-radius: 35px;
  display: inline-block;
  height: 64px;
  padding: 17px;
  position: relative;
  width: 64px;
}

.Blue-background img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.Inbox-title{
  font-family: 'Gotham-Rounded', Arial, sans-serif;
  font-size: 36px;
  font-weight: bold;
}

