.Reimbursement-screen-container {
  display: flex;
  flex-direction: column;
  margin: 18px 48px;
}

.Reimbursement-screen-shimmer-container {
  align-items: center;
  display: flex;
  height: 50%;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Reimbursement-screen-container {
    margin: 18px 24px;
  }
}
