.Rotate-filter {
  transform: rotate(180deg);
}

.No-cursor-events {
  pointer-events: none;
}

.Docs-arrow-icon {
  height: 12px;
  margin-left: 12px;
  pointer-events: none;
  transition: all 0.3s ease;
  width: 12x;
}
