.Coverage-overview-title-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 48px;
  position: relative;
  width: 100%;
}

.Coverage-overview-title-container > h2 {
  color: var(--pc-color-black);
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
  margin: 0;
}

.Coverage-overview-section-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.Coverage-overview-left-info-side {
  display: flex;
  flex-direction: column;
  min-width: 37%;
}

.Coverage-overview-right-info-side {
  display: flex;
}

.Coverage-overview-title-shimmer {
  height: 32px;
  margin-bottom: 24px;
  width: 100px;
}

.Coverage-overview-column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-right: 40px;
}

.Coverage-overview-30-day-policy-container  {
  align-items: center;
  background: var(--akc-primary-catalina-blue-15);
  border-radius: 100px;
  display: flex;
  gap: 4px;
  justify-content: center;
  margin-left: 4px;
  padding: 4px 8px;
}

.Coverage-overview-30-day-policy-container > span {
  color: var(--akc-primary-catalina-blue-100);
  font-family: "Gotham-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 14px;
  letter-spacing: 0.28px;
}

.Coverage-overview-change-coverage {
  position: absolute;
  right: 0;
}

.Coverage-overview-field {
  display: flex;
  flex-direction: column;
  min-height: 46px;
  min-width: 155px;
}

.Coverage-overview-disclaimer {
  color: var(--pc-color-black);
  font-family: "Gotham-Regular";
  width: 70%;
}

.Coverage-overview-disclaimer > strong {
  font-family: "Gotham-Medium";
  font-weight: 500;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Coverage-overview-section-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
