.Powerup-see-whats-covered-link-main-container {
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.Powerup-see-whats-covered-link {
  color: var(--deep-blue-main);
  cursor: pointer;
  font-family: 'IvyEpic-Bold';
  font-size: 12px;
  margin: 2px 0 0;
  pointer-events: none;
  text-decoration: underline;
}
