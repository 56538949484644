.Reimbursement-multibrand-screen-container {
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 18px 48px;
  width: calc(100% - 96px);
}

.Reimbursement-multibrand-screen-container > h1 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin-bottom: 8px;
}

.Reimbursement-multibrand-screen-container > p {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin: 0;
}

.Reimbursement-multibrand-cards-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Reimbursement-multibrand-card {
  align-items: flex-start;
  background: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  gap: 24px;
  justify-content: space-between;
  padding: 24px;
  width: 75vw;
}

.Reimbursement-multibrand-card-info-wrapper {
  display: flex;
}

.Reimbursement-multibrand-card-info-container {
  display: flex;
  flex-direction: column;
}

.Reimbursement-multibrand-card-info-container:nth-of-type(1) {
  width: 150px;
}

.Reimbursement-multibrand-card-info-container:nth-of-type(2) {
  margin: 0 24px;
  width: 210px;
}

.Reimbursement-multibrand-card-info-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin-bottom: 8px;
}

.Reimbursement-multibrand-card-info-value {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 367px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Reimbursement-multibrand-left-content {
  display: flex;
  flex-direction: row;
}

.Reimbursement-multibrand-left-content > img {
  height: fit-content;
  margin-right: 48px;
  width: 146px;
}

.Reimbursement-multibrand-card-details-button {
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--pc-color-blue-300);
  border-style: none none solid none;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 4px 0px;
}

.Reimbursement-multibrand-card-details-button > span {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

/* Tablet - Big */
@media (min-width: 920px) and (max-width: 1200px) {
  .Reimbursement-multibrand-card {
    gap: 16px;
    width: 92vw;
  }

  .Reimbursement-multibrand-card-info-container:nth-of-type(3) {
    margin: 0;
  }
}

/* Tablet - Medium */
@media (min-width: 761px) and (max-width: 919px) {
  .Reimbursement-multibrand-screen-container {
    margin: 18px 24px;
  }

  .Reimbursement-multibrand-card {
    gap: 16px;
    width: 94vw;
  }

  .Reimbursement-multibrand-card-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .Reimbursement-multibrand-card-info-container:nth-of-type(1) {
    width: 150px;
  }

  .Reimbursement-multibrand-card-info-container:nth-of-type(2) {
    margin: 0 0 0 24px;
    width: 180px;
  }

  .Reimbursement-multibrand-card-info-container:nth-of-type(3) {
    margin: 24px 0 0 0;
  }
}

@media (max-width: 760px) {
  .Reimbursement-multibrand-card-info-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Reimbursement-multibrand-card {
    gap: 16px;
    width: 90vw;
  }

  .Reimbursement-multibrand-card-info-container:nth-of-type(1) {
    width: 150px;
  }

  .Reimbursement-multibrand-card-info-container:nth-of-type(2) {
    margin: 24px 0;
    width: 180px;
  }

  .Reimbursement-multibrand-card-info-container:nth-of-type(3) {
    margin: 0;
  }
}
