.Login-form {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.Login-app-stores-container {
  display: flex;
  justify-content: space-evenly;
}

.Login-copyright-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Login-footer-form-social-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 150px;
}

.Login-footer-social-container {
  display: flex;
  flex-direction: row;
  flex: auto;
  padding-left: 8vw;
}

.Login-footer-social-info {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 40px;
  position: relative;
  right: 65px;
  width: 1050px; 
}

.Login-form-container {
  max-width: 320px;
}

.Login-form-email-tooltip-container {
  justify-content: flex-start;
}

.Login-form-email-tooltip {
  text-align: left !important;
}

.Login-forgot-primary-button {
  margin: 28px 0 16px;
}

.Login-forgot-secondary-button {
  margin-bottom: 40px;
}

.Login-back-button {
  margin: 20px 0;
}

.Login-reset-password-success {
  background-color: var(--success-background);
  color: var(--gray-icon-text);
  font-family: 'Inter-Semibold';
  letter-spacing: -0.408px;
  line-height: 16px;
  margin: -34px auto 96px;
  padding: 8px 23px;
}

.Login-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
  flex: auto;
  max-height: 250px;
  min-height: 250px;
  width: 100%;
}

.Login-footer-container {
  align-items: center;
  display: flex;
}

.Login-footer-copyright {
  height: 17px;
  margin: 65px 50px 0 100px;
  width: 448px;
}

.Login-footer-copyright-text {
  color: #b0bbbf;
  font-family: "Inter-Regular";
  font-size: 12px;
}

.Login-footer-form-social {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: auto;
  justify-content: center;
}

.Grass {
  background-image: url("../../assets/login/tall-grass.png");
  background-position: bottom;
  background-size: contain;
  min-height: 99.96px;
  width: 100%;
}

.Login-footer-info {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.Login-footer-row {
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin-top: 93px;
  padding-left: 5.5%;
}

.Login-form-inputs-container {
  margin-bottom: 29px !important;
}

.Login-form-input {
  background-color: transparent !important;
}

.Login-password-container {
  margin-bottom: 12px !important;
}

.Login-form-forgot-text {
  color: #000;
  font-family: 'Inter-Medium';
  font-size: 14px;
  letter-spacing: -0.4px;
  line-height: 17px;
  margin: 24px auto 16px;
  text-decoration: underline;
}

.Login-grass {
  height: 120px;
  width: 100%;
}

.Login-hydrant {
  height: 200px;
  left: 110px;
  position: relative;
  top: 15px;
  width: 188px;
  z-index: 1;
}

.Login-left-container-centered {
  right: 0vh;
}

.Login-footer-search-row {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.Login-right-container-centered {
  left: 0vh;
}

.Login-social-icon {
  height: 40px;
  width: 40px;
}

.Login-social-icons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 73px;
  width: 152px;
}

.Sign-in-title {
  color: var(--yankees-blue);
  font-family: "Inter-Semibold";
  font-size: 32px;
  letter-spacing: -0.7px;
  line-height: 38px;
  margin: 0;
  text-align: center;
}

.Sign-in-text {
  color: var(--gray-icon-text);
  letter-spacing: -0.41px;
  margin-top: 24px;
  max-width: 311px;
  text-align: center;
}

.Login-pet-search-spinner  {
  top: -20px
}

@media (max-width: 1025px){
  .Login-footer-search-row {
    width: 100%;
  }

  .Login-footer-social-info {
    flex-direction: column;
    width: 77vw;
  }

  .Login-social-icons-container {
    margin-top: 60px;
  }
}

@media (orientation: landscape) and (min-width: 768px) and (max-width: 1025px) {
  .Login-footer {
    position: relative !important;
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .Focused-content {
    opacity: 1;
    transition: 0.5s;
    z-index: 1;
  }

  .Hidden-content {
    opacity: 0;
    transition: 0.5s;
    z-index: -1;
  }

  .Login-footer {
    bottom: 0;
    display: flex;
    flex: auto !important;
    justify-content: space-around;
    position: fixed;
  }

  .Login-footer-copyright {
    margin-top: 8px;
  }

  .Login-footer-form-social {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }

  .Login-footer-form-social-container {
    max-width: 890px;
  }

  .Login-footer-info {
    min-width: 675px;
  }

  .Login-footer-social-container {
    flex-direction: column;
    min-width: 550px !important;
    position: relative;
    right: 7vw;
  }

  .Login-form-container {
    margin: 79px 0 0 165px;
    width: 384px;
  }

  .Login-figo-tag-container {
    margin-right: 0px !important;
    margin-top: 23px !important;
  }

  .Login-hydrant {
    left: 0;
  }
  
  .Video-container {
    height: 161px;
    width: 287px;
  }
}

@media (max-width: 1366px){
  .Login-footer-copyright {
    margin: 0px 50px 0 100px;
  }

  .Login-footer-social-container {
    flex-direction: column;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 479px
*/
@media (max-width: 480px) {
  .Login-app-stores-container {
    flex-direction: column;
    gap: 24px;
  }

  .No-mobile {
    display: none;
  }

  .Login-mobile {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 812
*/
@media (min-width: 481px) and (max-width: 812px) and (orientation: landscape) {
  .No-mobile {
    display: none;
  }

  .Login-mobile {
    display: flex;
    flex-direction: column;
  }
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Login-form-container{
    all: unset;
  }
}
