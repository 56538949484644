@font-face {
  font-family: "Inter-Regular";
  src: url("assets/fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-Regular.otf") format("opentype"), url("assets/fonts/Inter-Regular.woff") format("woff"),
    url("assets/fonts/Inter-Regular.ttf") format("truetype"),
    url("assets/fonts/Inter-Regular.svg#Inter-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("assets/fonts/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-SemiBold.otf") format("opentype"), url("assets/fonts/Inter-SemiBold.woff") format("woff"),
    url("assets/fonts/Inter-SemiBold.ttf") format("truetype"),
    url("assets/fonts/Inter-SemiBold.svg#Inter-SemiBold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Bold";
  src: url("assets/fonts/Inter-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-Bold.otf") format("opentype"), url("assets/fonts/Inter-Bold.woff") format("woff"),
    url("assets/fonts/Inter-Bold.ttf") format("truetype"), url("assets/fonts/Inter-Bold.svg#Inter-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Black";
  src: url("assets/fonts/Inter-Black.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-Black.otf") format("opentype"), url("assets/fonts/Inter-Black.woff") format("woff"),
    url("assets/fonts/Inter-Black.ttf") format("truetype"),
    url("assets/fonts/Inter-Black.svg#Inter-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Medium";
  src: url("assets/fonts/Inter-Medium.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Inter-Medium.otf") format("opentype"), url("assets/fonts/Inter-Medium.woff") format("woff"),
    url("assets/fonts/Inter-Medium.ttf") format("truetype"),
    url("assets/fonts/Inter-Medium.svg#Inter-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyEpic";
  src: url("assets/fonts/IvyEpic-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyEpic-Bold";
  src: url("assets/fonts/IvyEpic-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyPresto-Regular";
  src: url("assets/fonts/IvyPresto-Regular.otf") format("opentype"),
  url("assets/fonts/IvyPresto-Regular.svg") format("svg"),
  url('assets/fonts/IvyPresto-Regular.ttf')  format('truetype'),
  url('assets/fonts/IvyPresto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IvyPresto-Italic";
  src: url("assets/fonts/IvyPresto-Italic.otf") format("opentype"),
  url("assets/fonts/IvyPresto-Italic.svg") format("svg"),
  url('assets/fonts/IvyPresto-Italic.ttf')  format('truetype'),
  url('assets/fonts/IvyPresto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Regular";
  src: url("assets/fonts/Gotham-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("assets/fonts/Gotham-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Rounded";
  src: url("assets/fonts/Gotham-Rounded-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Rounded-Bold";
  src: url("assets/fonts/Gotham-Rounded-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Book";
  src: url("assets/fonts/Gotham-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

body {
  background-color: var(--pc-color-cotton-300);
  font-family: "Inter-Regular";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 1120px;
}

.Card-container {
  margin-bottom: 32px;
  width: 94.8%;
}

.Card-form {
  display: flex;
  justify-content: center;
}

.card-link {
  font-family: "Inter-SemiBold";
}

.card-text {
  color: #7e888c;
  font-size: 16px;
  line-height: 23px;
  height: 46px;
  padding-right: 16px !important;
}

.card-title {
  color: #22435e;
  font-family: "Inter-SemiBold";
  font-size: 16px;
  margin-bottom: 8px;
}

.Card-title-underline {
  background-color: rgb(125, 83, 192);
  height: 2px;
  margin-bottom: 18px;
  width: 24px;
}

.card-body {
  padding: 16px 16px 0 32px !important;
}

.Error-label {
  color: var(--pc-color-hydrant-400);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  margin: 4px 0 0;
  min-height: 16px;
}

.Error-label:empty {
  height: 0;
  margin: 0;
  min-height: 0;
}

.Input-label-error {
  color: var(--error);
}

.form-control {
  color: #202020;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #d9dee1;
}

.form-control:disabled {
  color: #b0bbbf;
  background-color: #fff;
}

.form-group {
  margin: 0;
  min-height: 94px;
  padding: 0;
}

.form-group label {
  margin-bottom: 8px !important;
}

.form-label {
  color: var(--gray-icon-text);
  font-size: 14px;
  font-family: "Inter-Medium";
}

.Forms-container {
  padding: 32px 32px 32px 32px;
}

input {
  height: 41px !important;
  padding-left: 16px !important;
}

.Inputs-container {
  padding-right: 16px !important;
}

.Input-row {
  margin-top: 18px !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.Labeless-form-group {
  margin-top: 27px !important;
}

.nav-link {
  font-family: "Inter-Regular";
}

.Select-arrow {
  height: 17px;
  width: 17px;
}

.text-muted {
  font-family: "Inter-SemiBold";
  line-height: 20px;
  font-size: 13px;
  height: 40px;
  color: #7e888c !important;
  margin-bottom: 24px;
}

.Input-error {
  background-color: transparent !important;
  border-color: var(--error) !important;
  border-width: 1px !important;
  color: var(--error);
}

.Cursor-pointer {
  cursor: pointer;
}

.Width100 {
  width: 100%;
}

@media (min-width: 1201px) {
  .Card-container {
    margin-bottom: 32px;
    width: 544px !important;
  }

  .Card-form {
    display: inline;
  }

  .Forms-container {
    padding: 30px 32px;
    width: 1440px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Button-mint:hover {
  border-color: #b8ede8 !important;
  box-shadow: 0 0 2px 2px #b8ede8;
}

.Button-mint:active {
  border-color: #b8ede8;
  box-shadow: 0 0 2px 2px #b8ede8;
}

.Button-mint:focus {
  border-color: #b8ede8;
  box-shadow: 0 0 2px 2px #b8ede8;
}

.Margin-top-0 {
  margin-top: 0;
}

.Margin-top-8 {
  margin-top: 8px;
}

.Margin-top-24 {
  margin-top: 24px;
}

.Margin-top-32 {
  margin-top: 32px;
}

.Margin-top-20-percent {
  margin-top: 20%;
}

.Padding-top-24 {
  padding-top: 24px;
}
