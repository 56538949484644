.Confirm-modal-action {
  border-radius: 8px !important;
  height: 50px;
  margin: 0 10px;
  width: 141px;
}

.Confirm-modal-actions {
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  width: 100%;
}

.Confirm-modal-body-text {
  color: #22435e;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.Confirm-modal-card {
  align-items: center;
  border-radius: 12px !important;
  display: flex;
  height: 263px;
  justify-content: space-evenly;
  width: 355px;
}

.Confirm-modal-image-title {
  height: 45px;
  width: 45px;
}

.Confirm-modal-text-body {
  height: 55px;
  width: 205px;
}

.Contact-subtext {
  color: #2fb3b4 !important;
  font-weight: 600;
}

.Custom-info {
  background-color: #2fb3b4 !important;
}

.Custom-info-blue {
  background-color: var(--pc-color-blue-300) !important;
}

.Custom-light {
  background-color: #eceef0 !important;
  color: #7e888c !important;
}

.Error-modal-card {
  align-items: center;
  border-radius: 12px !important;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  height: 389px;
  justify-content: space-evenly;
  width: 359px;
}

.Error-modal-close-button {
  align-items: center;
  background-color: #d9dee1;
  border-radius: 100px;
  border: none;
  display: flex;
  height: 28px;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 28px;
}

.Error-modal-body-text {
  color: #22435e;
  font-size: 22px;
  font-weight: 600;
  height: 60px;
  line-height: 1.36;
  text-align: center;
  width: 295px;
}

.Error-modal-close-button:hover {
  cursor: pointer;
}

.Error-modal-close-text {
  color: #fff;
}

.Error-modal-image-title {
  height: 176px;
  margin-top: 20px;
  object-fit: contain;
  width: 282px;
}

.Error-modal-subtext {
  color: #7e888c;
  font-size: 16px;
  height: 20px;
  text-align: center;
  width: 232px;
}

.Info-modal-action {
  border-radius: 8px !important;
  height: 60px;
  width: 171px;
  border: none !important;
}

.Info-modal-body-text {
  color: #22435e;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.Info-modal-card {
  align-items: center;
  border-radius: 12px !important;
  display: flex;
  height: 305px;
  width: 381px;
  padding: 32px;
}

.Info-modal-image-title {
  height: 64px;
  width: 64px;
  margin-bottom: 15.66px;
}

.Info-modal-text-body {
  height: 29.91px;
  width: 360px;
  margin-bottom: 16.43px;
}

.Info-modal-subtitle-container {
  width: 317px;
  height: 23px;
  margin-bottom: 32px;
}

.Info-modal-subtitle-text {
  color: #3d484d;
  font-size: 16px;
  text-align: center;
}

.Input-modal-button {
  height: 60px;
  width: 150px;
}

.Input-modal-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1px;
  margin-top: 16px;
  width: 317px;
}

.Input-modal-card {
  border-radius: 10px !important;
  height: 424px;
  padding: 30px;
  width: 381px;
}

.Input-modal-form-group {
  max-height: 87px;
  width: 317px !important;
}

.Input-modal-image {
  margin-bottom: 16px;
}

.Input-modal-input {
  height: 41px !important;
  width: 317px !important;
}

.Input-modal-input::placeholder {
  color: #b0bbbf !important;
}

.Input-modal-primary-text {
  font-family: "Inter-SemiBold";
  font-size: 16px;
}

.Input-modal-secondary-text {
  color: #7e888c;
  font-family: "Inter-SemiBold";
  font-size: 16px;
}

.Input-modal-subtitle {
  color: #3d484d;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
  width: 310px;
  height: 46px;
}

.Input-modal-title {
  color: #264b6a;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
}

.Modal-overlay {
  align-items: center;
  background-color: rgba(30, 34, 38, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
}

.Static-modal-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}