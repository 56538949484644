.Input-file-container {
  margin: auto;
  max-width: 680px;
}

.Input-file {
  background-color: #fff;
  border-radius: 4px;
  border: 1pt solid #d9dee1;
  display: flex;
  flex-direction: column;
  height: 228px;
  margin: auto;
  max-width: 680px;
  position: relative;
  width: 100%;
}

.Input-file > input {
  height: 100% !important;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Input-file-modal {
  margin-left: 25px;
  margin-top: 25px;
}

.Input-file-icon {
  margin-bottom: 8px;
  position: relative;
  text-align: center;
}

.Input-file-plus-doc {
  height: 20px;
  position: absolute;
  transform: translate(-35px, 20px);
  width: 20px;
}

.Input-file-browse {
  align-items: center;
  color: #2fb3b4;
  cursor: pointer;
  font-family: "Inter-Bold";
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.Input-file-count {
  color: #2c6085;
  font-family: "Inter-SemiBold";
  text-align: right;
}

.Input-file-plus {
  background-color: var(--pc-color-blue-300);
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  margin-right: 8px;
  padding: 7px;
  width: 30px;
}

.Input-file-add-files {
  color: #3d484d;
  margin: 32px auto;
  max-width: 680px;
  text-align: left;
  width: 100%;
}

.Input-file-add-browse {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  width: 110px;
}

.Input-file-add-browse > input {
  left:0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  top:0;
  width: 100%;
}

.Input-file-browse > input::-webkit-file-upload-button,
.Input-file-add-browse > input::-webkit-file-upload-button {
  cursor: pointer;
}

.Input-file-file-progress {
  background-color: #1ccfca;
  border-radius: 2px;
  height: 4px;
  transition: all 0.3s;
  width: 100%;
}

.Input-file-dropzone-cover {
  align-items: center;
  background-color: rgba(30, 34, 38, 0.6);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 6;
}

.Input-file-dropzone {
  align-items: center;
  background-color: #b8ede8;
  border: dashed 4px #2c6085;
  display: flex;
  flex-direction: column;
  height: 676px;
  justify-content: center;
  width: 1086px;
}

.Input-file-dropzone > div > span {
  color: #2c6085;
  display: block;
  font-family: 'Inter-SemiBold';
  margin-top: 16px;
}

.Input-file-action {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Regular';
  font-size: 14px;
}

.Input-file-action strong {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  text-decoration: underline;
}

.Input-file-instruction {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  text-align: center;
  width: 322px;
}

.Input-file-empty {
  align-items: center;
  border: 1px solid var(--pc-color-cotton-300);
  justify-content: center;
  overflow: auto;
}

.File-row {
  margin: 8px 0px;
  width: 100%;
}

.File-row-data {
  display: flow-root;
}

.File-row-progress-bar {
  border: 0.5px solid var(--pc-color-cotton-500);
  border-radius: 80px;
}

.File-row-name {
  color: var(--base-pc-color-black);
  display: inline-block;
  font-family: "Gotham-Medium";
  font-size: 12px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.File-row-extension {
  margin-right: 8px;
  width: fit-content;
}

.File-row-status {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 12px;
}

.File-row-left {
  display: flex;
  float: left;
}

.File-row-name-container {
  display: inline-flex;
  max-width: 60%;
}

.File-row-right {
  display: flex;
  justify-content: flex-end;
}

.File-row-size {
  color: var(--pc-color-onyx-200);
  display: inline-block;
  font-family: "Gotham-Regular";
  font-size: 12px;
  min-width: 60px;
  white-space: nowrap;
}

.File-row-delete {
  display: flex;
  margin-bottom: 8px;
  margin-right: 8px;
}

.File-row-delete button {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
}

.File-row-error {
  color: var(--pc-color-hydrant-400);
  font-family: 'Gotham-Regular';
  font-size: 10px;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1200px) {
  .Input-file {
    overflow-y: auto;
    width: 608px;
  }
}
