.Endorsement-modal-overlay {
  align-items: center;
  background-color: rgba(30, 34, 38, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
}

.Endorsement-modal-container {
  display: flex;
}

.Endorsement-left-container-alone {
  padding: 32px;
  width: 381px;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.02);
  background-color: #ffffff;
  text-align: center;
}

.Endorsement-left-container {
  background-color: #ffffff;
  border-radius: 12px 0 0 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.02);
  height: 476px;
  padding: 32px 24px;
  text-align: center;
  width: 304px;
}

.Endorsement-right-container {
  background-color: #f8f8f8;
  border-radius: 0 8px 8px 0;
  display: flex;
  height: 476px;
  justify-content: center;
  width: 432px;
}

.Endorsement-modal-title-container {
  margin-bottom: 8px;
}

.Endorsement-modal-title-container-alone {
  margin-bottom: 12px;
}

.Endorsement-modal-title {
  color: #264b6a;
  font-family: "Inter-SemiBold";
  font-size: 22px;
  line-height: 1.36;
  margin: 0;
}

.Endorsement-modal-title-alone {
  color: #264b6a;
  font-family: "Inter-SemiBold";
  font-size: 22px;
  line-height: 1.36;
  margin: 0;
}

.Endorsement-modal-image-container {
  margin-bottom: 19px;
}

.Endorsement-modal-image {
  width: 64px;
}

.Endorsement-modal-message {
  font-size: 16px;
  line-height: 1.44;
  color: #3d484d;
  margin: 0;
  width: 256px;
}

.Endorsement-modal-message-alone {
  font-size: 16px;
  line-height: 1.44;
  color: #3d484d;
  margin: 0;
  width: 317px;
}

.Endorsement-modal-message-container {
  margin-bottom: 16px;
}

.Endorsement-modal-body-container {
  margin-bottom: 16px;
}

.Endorsement-modal-body-row {
  border-bottom: 1px solid #d9dee1;
  display: flex;
  flex-direction: row;
  height: 49px;
  justify-content: space-between;
  padding: 14.5px 16px;
}

.Endorsement-modal-body-last-row {
  border-bottom: none;
}

.Endorsement-modal-buttons-container {
  display: flex;
  flex-direction: row;
}

.Endorsement-modal-button {
  border-radius: 8px;
  border-width: 0;
  height: 60px;
}

.Endorsement-Modal-button-cancel {
  background-color: #eceef0;
  margin-right: 16px;
  width: 116px;
}

.Endorsement-Modal-button-accept {
  background-color: #2fb3b4;
  width: 128px;
}

.Endorsement-Modal-button-cancel-alone {
  background-color: #eceef0;
  margin-right: 16px;
  width: 150px;
}

.Endorsement-Modal-button-accept-alone {
  background-color: #2fb3b4;
  width: 150px;
}

.Endorsement-Modal-button-text {
  font-family: "Inter-SemiBold";
  font-size: 16px;
}

.Endorsement-Modal-button-text-cancel {
  color: #7e888c;
}

.Endorsement-Modal-button-text-accept {
  color: #ffffff;
}

.Endorsement-modal-contact {
  color: #2fb3b4;
  font-family: "Inter-SemiBold";
  font-size: 16px;
  line-height: 1.25;
  margin: 24px 0 0;
}

.Endorsement-modal-contact-alone {
  color: #2fb3b4;
  font-family: "Inter-SemiBold";
  font-size: 16px;
  line-height: 1.25;
  margin: 32px 0 0;
}

.Endorsement-modal-contact-alone:hover {
  color: #2fb3b4;
}

.Endorsement-modal-contact:hover {
  color: #2fb3b4;
}

.Endorsement-modal-row-label {
  font-size: 16px;
  color: #242a31;
}

.Endorsement-modal-row-amount {
  font-size: 16px;
  color: #7e888c;
}