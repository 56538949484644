.File-claimbot-claim-summary-container {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  min-width: 433px;
}

.File-claimbot-claim-summary-pet-card {
  display: flex;
  flex-direction: row;
}

.File-claimbot-claim-summary-pet-card > img {
  border-radius: 40px;
  height: 40px;
  margin-right: 8px;
  width: 40px;
}

.File-claimbot-claim-summary-column {
  display: flex;
  flex-direction: column;
}

.File-claimbot-claim-summary-pet-name {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
}

.File-claimbot-claim-summary-pet-breed {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: normal;
}

.File-claimbot-claim-summary-rows-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.File-claimbot-claim-summary-row {
  display: flex;
  justify-content: space-between;
}

.File-claimbot-claim-summary-row-left-text {
  color: var(--base-pc-color-black);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.File-claimbot-claim-summary-row-right-text {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
