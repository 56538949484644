.File-claim-date-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 72px;
}

.File-claim-date-input-container > span {
  color: var(--charcoal-300);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.File-claim-date-input-container > input {
  border-radius: 8px;
  border: 1px solid var(--pc-color-grey-150);
  color: var(--charcoal-300);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 454px;
  padding: 8px 9px;
  text-transform: uppercase;
}
