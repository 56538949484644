.Share-post-navbar {
  margin-bottom: 30px;
}

.Share-post-nav-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 50px;
  width: 100%;
}

.Share-post-nav-header {
  width: 180px;
}

.Share-post-nav-appstore {
  margin-right: 24px;
  width: 140px;
}

.Share-post-navbar-googleplay {
  width: 140px;
}

.Share-post-container {
  align-items: center;
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0 15px;
  width: 1170px;
}

.Share-post-article {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 1px 2px 5px #ccc;
  max-width: 375px;
}

.Share-post-article-header {
  display: flex;
  padding: 9px 16px;
}

.Share-post-profile-pic {
  height: 60px;
  margin-right: 12px;
  width: 60px;
}

.Share-post-article-profile {
  margin-top: 8px;
}

.Share-post-profile-name {
  color: #000;
  font-family: "Inter-Regular";
  font-size: 18px;
}

.Share-post-profile-date {
  color: #aab5bc;
  font-family: "Inter-Regular";
  font-size: 15px;
}

.Share-post-article-pic {
  background-color: #b8ede840;
  min-height: 375px;
  min-width: 375px;
  width: 375px;
}

.Share-post-article-text {
  color: #575b5e;
  font-family: "Inter-Regular";
  font-size: 18px;
  padding: 14px 18px;
}

.Share-post-shimmer-container {
  align-items: center;
  display: flex;
  height: 554px;
  justify-content: center;
  margin: 0 auto;
  padding: 0 15px;
  width: 375px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Share-post-nav-container {
    margin-top: 20px;
  }

  .Share-post-nav-header {
    width: 140px;
  }

  .Share-post-container {
    width: 750px;
  }
}

@media (max-width: 568px) {
  .Share-post-nav-container {
    display: flex;
    flex-direction: column;
  }

  .Share-post-nav-header {
    margin-bottom: 12px;
    width: 120px;
  }

  .Share-post-store-icons {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .Share-post-nav-appstore {
    margin-bottom: 12px;
    margin-right: 0;
  }

  .Share-post-container {
    margin-bottom: 40px;
    width: 295px;
  }

  .Share-post-article 
  .Share-post-article-pic {
    min-height: 295px;
    min-width: 295px;
    width: 295px;
  }

  .Share-post-shimmer-container {
    height: 474px;
    width: 295px;
  }
}
