.Payment-history-list-container {
  background-color: var(--base-pc-color-white);
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 1068px;
}

.Payment-history-list-loading-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  width: 100%;
}

.Payment-history-list-header,
.Payment-history-list-row,
.Payment-history-list-row-details {
  background-color: var(--base-pc-color-white);
  border-color: var(--pc-color-cotton-500);
  border-radius: 8px 8px 0 0;
  border-style: none none solid  none;
  border-width: 2px;
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-evenly;
  width: 100%;
}

.Payment-history-list-footer {
  align-items: center;
  background-color: var(--base-pc-color-white);
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
}

.Payment-history-list-footer > td {
  height: min-content;
}

.Payment-history-list-footer-text {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Payment-history-list-footer-pag-container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-width: 10%;
}

.Payment-history-list-header > th,
.Payment-history-list-row > td,
.Payment-history-list-row-details > td {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  height: 100%;
  line-height: normal;
  padding: 10px 16px;
  width: 100%;
}

.Payment-history-list-row {
  border-radius: 0;
}

.Payment-history-list-row > td,
.Payment-history-list-row-details > td {
  font-family: 'Gotham-Regular';
  font-weight: 325;
}

.Payment-history-list-row-details {
  background-color: var(--pc-color-blue-100);
  border: none;
}

.Payment-history-list-arrow,
.Payment-history-list-arrow-up {
  height: 12px;
  width: 12px;
}

.Payment-history-list-arrow {
  transition: all 0.3s ease;
}

.Payment-history-list-arrow-up {
  transform: rotate(180deg);
 }

.Payment-history-list-button {
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--pc-color-blue-300);
  border-style: none none solid none;
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  padding: 4px 0px;
}

.Payment-history-list-button > span {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 12px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.24px;
  line-height: 12px;
  text-align: center;
}

.Payment-history-list-page-button,
.Payment-history-list-page-button-active {
  align-items: center;
  background-color: var(--pc-color-cotton-200);
  border-radius: 4px;
  border: none;
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  width: 32px;
}

.Payment-history-list-page-button-active {
  background-color: var(--pc-color-blue-300);
}

.Payment-history-list-page-button > span,
.Payment-history-list-page-button-active > span {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.Payment-history-list-page-button-active > span {
  color: var(--base-pc-color-white);
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Payment-history-list-container {
    width: 720px;
  }

  .Payment-history-list-shimmer-container {
    width: 600px;
  }
}
