.Rate-error-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 381px;
  padding: 40px 32px 37px;
}

.Rate-error-modal > h4 {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 22px;
  line-height: 1.364;
  margin-bottom: 16px;
}

.Rate-error-modal > p {
  color: #3d484d;
  line-height: 1.438;
  margin-bottom: 24px;
  text-align: center;
}

.Rate-error-modal a {
  color: #2fb3b4;
  font-family: 'Inter-SemiBold';
  text-decoration-color: #2fb3b4;
  text-decoration: underline !important;
}

.Rate-error-modal a :hover {
  text-decoration-color: #2fb3b4 !important;
  text-decoration: underline !important;
}

.Rate-error-modal > button {
  background-color: #2fb3b4;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-family: 'Inter-SemiBold';
  line-height: 1.25;
  padding: 20px 0;
  width: 229px;
}

/* Mobile */
@media (max-width: 568px) {
  .Rate-error-modal-container {
    max-width: 281px;
    width: 87.7%;
  }

  .Rate-error-modal {
    padding: 21.52px 0 19px;
    width: 87.7%;
  }

  .Rate-error-modal > p {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .Rate-error-modal > button {
    font-size: 13px;
    padding: 14px 0;
    width: 112px;
  }
}
