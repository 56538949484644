.Billing-details-card-container {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  min-height: fit-content;
  padding: 24px 24px 0;
  position: relative;
  width: 100%;
}

.Billing-details-upper-container {
  align-items: center;
  display: flex;
  margin-bottom: 40px;
  max-height: 55.99px;
  width: 100%;
}

.Billing-details-top-container {
  align-items: center;
  display: flex;
  margin-bottom: 32px;
  max-height: 55.99px;
  width: 100%;
}

.Billing-details-upper-img {
  height: 55.99px;
  margin-right: 52px;
  width: 146px;
}

.Billing-details-price-text {
  align-items: center;
  color: var(--base-pc-color-black);
  display: flex;
  font-family: "Gotham-Rounded-Bold";
  font-size: 36px;
  font-weight: 400;
  height: 100%;
  letter-spacing: 0em;
  line-height: 36px;
  margin: 0;
  text-align: left;
}

.Billing-details-price-text span {
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-weight: 350;
  letter-spacing: 0em;
  line-height: 29px;
}

.Billing-details-policy-details {
  display: flex;
  flex-wrap: wrap;
}

.Billing-details-policy-details-item {
  display: flex;
  flex-direction: column;
  margin: 0 24px 24px 0;
}

.Billing-details-policy-detail-item {
  display: flex;
  flex-direction: column;
  margin: 0 24px 24px 0;
}

.Billing-details-policy-details-key {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Medium";
  font-size: 16px;
  font-weight: 350;
  letter-spacing: 0em;
  line-height: 19px;
  margin-bottom: 8px;
  max-width: 148px;
  text-align: left;
  width: max-content;
}

.Billing-details-policy-details-value {
  color: var(--pc-color-onyx-200);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 19px;
  margin-bottom: 0;
  text-align: left;
}

.Billing-details-policy-details-value span {
  font-family: "Gotham-Medium";
  font-size: 24px;
  font-weight: 350;
  letter-spacing: 0em;
  line-height: 29px;
  margin-left: 8px;
  text-align: left;
}

.Billing-details-policy-status-container {
  position: absolute;
  right: 24px;
  top: 24px;
}

/* Mobile */
@media (max-width: 568px) {
  .Billing-details-card-container {
    margin-bottom: 16px;
    padding: 16px;
  }

  .Billing-details-upper-container {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 45px;
  }

  .Billing-details-top-container {
    align-items: flex-start;
    flex-direction: column;
    max-height: fit-content;
  }

  .Billing-details-top-container .Billing-details-price-text {
    margin-top: 16px;
  }

  .Billing-details-top-container .Billing-details-price-text > span {
    margin-left: 8px;
  }

  .Billing-details-policy-details {
    height: 100%;
    flex-direction: column;
  }

  .Billing-details-policy-details-item {
    margin: 0 15px 15px 0;
  }

  .Billing-details-policy-details-key {
    width: fit-content;
  }

  .Billing-details-policy-detail-item {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 12px 0;
  }

  .Billing-details-policy-details-value {
    max-width: 52%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Smaller Mobile */
@media (max-width: 390px) {
  .Billing-details-policy-detail-item {
    flex-direction: column;
    justify-content: flex-start;
  }

  .Billing-details-policy-details-value {
    max-width: 100%;
    text-wrap: wrap;
  }
}

@media (max-height: 667px) and (max-width: 568px) {
  .Billing-details-card-container {
    min-height: 450px;
  }
}
