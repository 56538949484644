.Billing-premium-cost-row {
  display: flex;
  justify-content: space-between;
}

.Billing-premium-cost-row-title,
.Billing-premium-cost-row-description {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-medium";
  font-size: 16px;
}

.Billing-premium-cost-row-description {
  margin-left: 10px;
}
