.Coverage-policy-status-active,
.Coverage-policy-status-cancelled,
.Coverage-policy-status-future,
.Coverage-policy-status-unPolicy {
  align-items: center;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 22px;
  justify-content: center;
  padding: 4px 8px;
  width: min-content;
}

.Coverage-policy-status-future {
  background-color: var(--pc-color-yellow-200);
}

.Coverage-policy-status-active {
  background-color: var(--pc-color-leaf-100);
}

.Coverage-policy-status-unPolicy {
  background-color: var(--pc-color-cotton-300);
}

.Coverage-policy-status-cancelled {
  background-color: var(--pc-color-hydrant-100);
}

.Coverage-policy-status-active > p,
.Coverage-policy-status-cancelled > p,
.Coverage-policy-status-future > p,
.Coverage-policy-status-unPolicy > p {
  font-family: "Gotham-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.28px;
  line-height: 14px;
  margin: 0;
}

.Coverage-policy-status-active > p {
  color: var(--pc-color-leaf-400);
}

.Coverage-policy-status-unPolicy > p {
  color: var(--pc-color-onyx-200);
}

.Coverage-policy-status-future > p {
  color: var(--pc-color-yellow-500);
}

.Coverage-policy-status-cancelled > p {
  color: var(--pc-color-hydrant-400);
}

.Coverage-policy-status-icon {
  height: 16px;
  width: 16px;
}
