.Card-plan-powerup-header-container {
  align-items: center;
  display: flex;
  margin-top: 24px;
  min-height: 51px;
}

.Card-plan-powerup-description {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Card-plan-powerup-description-mobile {
  display: none;
}

.Card-plan-powerup-cards-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 100%;
  margin: 8px 0 48px 0;
  width: 100%;
}

.Card-plan-wellness-checkbox-row-container {
  margin-top: 15px;
  max-height: 64px;
}

.Card-plan-wellnes-tag {
  background-color: #73dad2;
  border-radius: 11px;
  color: #22435e;
  font-family: 'Inter-Semibold';
  font-size: 12px;
  line-height: 14px;
  margin-right: 8px;
  margin-top: 15px;
  min-width: 42px;
  padding: 4px 7px 4px 8px;
  text-align: center;
}

.Powerup-Card-plan-wellnes-tag {
  border-radius: 11px;
  color: var(--deep-blue-shade-2);
  font-family: "IvyEpic-Bold";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-right: 2px;
  margin-top: 15.8px;
  padding: 2px 0px 2px 0px;
  text-align: center;
}

.Card-plan-wellness-price-checkbox {
  color: #274c6b;
  cursor: pointer;
  font-family: 'Inter-SemiBold';
  margin-top: 15px;
}

.Powerup-Card-plan-wellness-price-checkbox {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic';
  font-size: 12px;
  font-style: normal;
  line-height: 21px;
  margin-top: 15px;
}

.Card-plan-wellness-link-content {
  bottom: 100%;
  display: flex;
  left: 35px;
  padding: 0;
  position: absolute;
}

.Card-plan-powerup-image {
  height: 39.5px;
  width: 53px;
}

.Card-plan-powerup-header-title {
  align-items: center;
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'Inter-Semibold';
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  line-height: 34px;
  max-width: 500px;
  overflow: hidden;
}

.Card-plan-powerup-header-title > h3 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.12px;
  line-height: 28px;
}

.Powerup-wellness-checkbox-container-first {
  margin-top: 18.98px;
}

.Powerup-wellness-checkbox-row-container {
  height: 20px;
}

.Powerup-wellness-checkbox-row-container:first-of-type {
  margin-bottom: 15px;
}

.Powerup-wellness-link-content{
  display: none;
}


/* Tablet - Big */
@media (max-width: 1200px) {
  .Card-plan-powerup-cards-container {
    flex-direction: column;
  }

  .Card-plan-wellnes-tag {
    align-self: flex-start !important;
  }

  .Card-plan-wellness-link-container {
    cursor: pointer;
    display: flex;
    height: 0px;
    margin-top: 25px;
    position: relative;
  }
}

.Card-plan-powerup-title {
  text-align: left;
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Card-plan-wellness-checkbox-container-first,
  .Card-plan-wellness-checkbox-container {
    margin-top: 25px;
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Card-plan-wellness-checkbox-row-container:last-of-type {
    margin-bottom: 0px;
  }

  .Card-plan-powerup-header-container {
    flex-direction: column;
    margin-bottom: 24px;
  }
  
  .Card-plan-powerup-description {
    text-align: left;
  }

  .Powerup-Card-plan-wellness-price-checkbox {
    font-size: 10px;
  }

  .Powerup-Card-plan-wellness-checkbox-container {
    margin-top: 25px;
  }

  .Card-plan-wellness-link-container {
    height: 0px;
  }

  .Card-plan-wellness-price-checkbox {
    font-size: 13px;
    line-height: 1.385;
    text-align: left;
    vertical-align: top;
  }

  .Card-plan-wellness-link-content {
    bottom: 100%;
    display: flex;
  }

  .Card-plan-powerup-image {
    height: 34px;
    width: 43px;
  }

  .Card-plan-powerup-header-title {
    font-size: 24px;
    justify-content: center;
    margin-top: 48px;
    overflow: visible;
    text-align: left;
  }

  .Powerup-wellness-checkbox-row-container {
    height: 60px;
  }

  .Powerup-wellness-checkbox-container-first {
    margin-top: 16px;
  }

  .Powerup-wellness-checkbox-row-container:first-of-type {
    margin-bottom: 16px;
  }

  .Powerup-wellness-link-content {
    display: flex;
    left: 60px;
    margin-top: 8px;
    padding: 0;
    position: absolute;
  }
}
