.Upload-files-button {
  overflow: hidden;
  position: relative;
}

.Upload-files-button input {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}
