.Related-pets-selector-container {
  margin-top: 32px;
}

.Related-pets-selector-scrollable {
  display: flex;
  margin-top: 16px;
  max-height: 120px;
}

.Related-pets-selector-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.Related-pets-selector-list li {
  height: auto;
  margin-bottom: 16px;
  overflow: hidden;
  padding-right: 13px;
  width: 50%;
}

.Related-pets-selector-list-full {
  width: 100% !important;
}

.Related-pets-selector-list-item {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: left;
}

.Related-pets-selector-list-item span {
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
}
