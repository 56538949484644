.Login-mobile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Login-mobile-background-content {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 48.04px;
  text-align: center;
  width: 100%;
}

.Login-mobile-background-content h1 {
  color: #192149;
  font-family: "Inter-SemiBold";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.70176px;
  line-height: 38px;
  margin-bottom: 24px;
  text-align: center;
}

.Login-mobile-background-content p {
  color: #222;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.408px;
  line-height: 22px;
  margin-bottom: 32px;
  text-align: center;
  width: 75%;
}

.Login-pet-cloud-banner {
  background-color: #192149;
  padding: 28.34px 0 27.3px 40.88px;
}

.Login-pet-cloud-banner img {
  height: 30.35px;
  width: 186.75px;
}

.Login-mobile-figo-background {
  margin-bottom: 24px;
}

.Login-mobile-apps-container {
  display: flex;
}
