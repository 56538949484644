.Recent-activity-container {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin-left: 48px;
  margin-top: 81px;
  max-height: 400px;
  min-width: 289px;
  padding: 16px;
}

.Recent-activity-shimmer {
  height: 395px;
  width: 257px;
}

.Recent-activity-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded', Arial, sans-serif;
  font-size: 20px;
  font-weight: 350;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1200px) {
  .Recent-activity-container {
    margin: 32px 0 32px 0;
  }

  .Recent-activity-shimmer {
    height: 348px;
    width: 705px;
  }
}
