.Selector-pills-container {
  align-items: flex-start;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Selector-pills-container:nth-child(-n+2) {
  padding-bottom: 24px;
}

.Selector-pills-title {
  color: var(--pc-color-onyx-500);
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-weight: 350;
  height: 21px;
  line-height: 21px;
  margin-bottom: 8px;
}

.Selector-pills-title legend {
  font-size: 14px;
  font-weight: 700;
  margin-right: 5.7px;
}

.Selector-pills-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 12px;
}

.Selector-pills-options label {
  margin-bottom: 0px;
}
