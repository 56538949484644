.Multiple-pet-selector-main-container {
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.Multiple-pet-selector-container {
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 31px 0 0 48px;
  width: 100%;
}

.Multiple-pet-selector-container > h1 {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-Rounded";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  text-transform: capitalize;
}

.Multiple-pet-selector-container > span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
}

.Multiple-pet-selector-grid-container {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 345px 345px 345px;
  margin-top: 32px;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Multiple-pet-selector-container {
    padding: 32px 0 0 24px;
  }

  .Multiple-pet-selector-grid-container {
    grid-template-columns: 348px 348px;
  }
}
