.File-claimbot-main-container {
  display: flex;
  flex-direction: column;
  margin: 24px 32px;
}

.Screen-header-claimbot > h1 {
  color: var(--charcoal-500);
  font-family: "Gotham-Rounded";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin: 0;
}

.File-claimbot-content {
  background-color: var(--base-pc-color-white);
  border-radius: 6px;
  border: 1px solid var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 1116px;
  padding: 30px 0;
  width: 100%;
}

/* ---old design duplicated--- */
.File-claim-title {
  color: #22435e;
  font-family: "Inter-Bold";
  font-size: 32px;
  width: auto;
}

.File-card-container {
  min-height: 701px;
  max-width: 1120px;
  padding: 0;
  margin: 0;
  border-style: none;
}

.File-claim-card-body {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 62px 0 0 0;
}

.File-claimbot-card-content {
  justify-content: center;
  display: flex;
  width: 100%;
}

.File-claim-type-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.File-claim-type-container p {
  text-align: center;
}

.File-claim-unsave-modal {
  width: 510px;
}

.File-claim-title-conatiner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.File-claim-instruction {
  color: #22435e;
  font-family: "Inter-Semibold";
  font-size: 18px;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1200px) {
  .File-card-content {
    min-width: 474px;
    padding: 0;
    width: 100%;
  }

  .File-claim-column-right-button {
    align-items: flex-end;
    padding-right: 26px;
  }
}
/* ---end old design duplicated--- */
