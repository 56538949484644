.AVMM-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 520px;
}

.AVMM-title {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 22px;
  margin-bottom: 32px;
  text-align: center;
}

.AVMM-input-titles {
  color: #22435e;
  font-family: 'Inter-Medium';
  font-size: 14px;
  margin-bottom: 8px;
}

.AVMM-input {
  border-radius: 5px;
  border: 1px solid #d9dee1;
}

.AVMM-select {
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  border-radius: 5px;
  border: 1px solid #d9dee1;
  height: 41px;
  padding: 8px;
  width: 207px;
}

.AVMM-input-container {
  display: flex;
  flex-direction: column;
}

.AVMM-input-container-title {
  margin: 0 40px 20px 40px;
}

.AVMM-inputs-row {
  display: flex;
  margin: 0 40px 36px 40px;
  width: 100%;
}

.AVMM-input-container-city {
  margin-right: 24px;
  max-width: 207px;
}

.AVMM-buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 46px;
}

.AVMM-save-btn {
  align-items: center;
  background-color: #22435e;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  width: 116px;
}

.AVMM-save-btn > .Loading-button-text {
  font-family: 'Inter-Regular';
}

.AVMM-save-btn:hover {
  background-color: #203e57;
}

.AVMM-close-btn {
  display: flex;
  justify-content: flex-end;
  margin: 18px 18px 0 0;
}

.AVMM-cancel-btn {
  background-color: #b0bbbf;
  border-radius: 5px;
  border: 1px solid #b0bbbf;
  color: #fff;
  height: 48px;
  margin-right: 16px;
  width: 116px;
}

.AVMM-cancel-btn:hover {
  background-color: #b0b0b0;
}

.AVMM-error-message {
  color: #cc0000;
  font-family: 'Inter-Regular';
  font-size: 13px;
  margin-top: 8px;
}
