.File-claimbot-attachment-selector-container {
  display: flex;
  flex-direction: column;
  margin: 9px 0 0 65px;
}

.File-claimbot-attachment-drag-zone-card {
  align-items: center;
  background: var(--base-pc-color-white);
  border-radius: 12px;
  border: 1px solid var(--pc-color-cotton-300);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  min-width: 454px;
  padding: 36px 93px;
}

.File-claimbot-attachment-drag-zone-card > img {
  height: 32px;
  margin-bottom: 16px;
  object-fit: contain;
  width: 32px;
}

.File-claimbot-attachment-drag-zone-card > span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.File-claimbot-attachment-drag-zone-card > span > label {
  color: var(--pc-color-blue-300);
  cursor: pointer;
  font-family: "Gotham-medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-decoration-line: underline;
}

.File-claimbot-attachment-drag-zone-card > span:last-of-type {
  font-size: 12px;
  color: var(--pc-color-onyx-200);
}

.File-claimbot-attachment-selector-uploaded-files-title {
  color: var(--base-pc-color-black);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
}
