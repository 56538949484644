.File-claimbot-text-input-single-line-container {
  display: flex;
  flex-direction: column;
  margin-left: 73px;
  width: 454px;
}

.File-claimbot-text-input-single-line-container > h3 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin: 0 0 8px 0;
}

.File-claimbot-text-input-single-line-container > input {
  background: var(--base-pc-color-white);
  border-radius: 8px;
  border: 1px solid var(--charcoal-300);
  color: var(--charcoal-300);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 9px;
  resize: none;
  width: 100%;
}
