.Action-button,
.Action-button-red,
.Action-button-white {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 1px solid var(--pc-border-radius-xl);
  display: flex;
  height: 40px;
  justify-content: center;
  margin-top: 24px;
  min-width: 140px;
  padding: 0;
}

.Action-button span,
.Action-button-red span,
.Action-button-white span,
.Action-button-text-disabled,
.Action-button-red-text-disabled,
.Action-button-white-text-disabled {
  border: none;
  color: var(--base-pc-color-white);
  display: block;
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  height: 100%;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 12px 16px;
  text-align: center;
  width: 100%;
}

.Action-button-red {
  background-color: var(--pc-color-hydrant-400);
  border: 1px solid var(--pc-color-hydrant-400) !important;
}

.Action-button-white span {
  color: var(--pc-color-onyx-500);
}

.Action-button-white {
  background-color: transparent;
}

.Action-button:disabled,
.Action-button-red:disabled {
  border: none;
}

.Action-button-text-disabled,
.Action-button-red-text-disabled,
.Action-button-white-text-disabled {
  color: var(--pc-color-onyx-200) !important;
}

.Action-button:disabled {
  background-color: var(--pc-color-cotton-200);
}

.Action-button-white:hover,
.Action-button-white:focus,
.Action-button-white:active {
  background-color: var(--pc-color-cotton-300) !important;
  border: 1px solid var(--pc-border-radius-xl) !important;
  color: var(--pc-color-onyx-500) !important;
}

.Action-button:hover,
.Action-button:active,
.Action-button:focus {
  background-color: var(--pc-color-blue-400) !important;
  border: none;
  color: var(--base-pc-color-white);
}

.Action-button-red:hover,
.Action-button-red:active,
.Action-button-red:focus {
  background-color: var(--pc-color-hydrant-500) !important;
  border: 1px solid var(--pc-color-hydrant-500) !important;
  color: var(--base-pc-color-white);
}

.Action-button-no-margin {
  margin: 0 !important;
  margin-top: 0;
}
