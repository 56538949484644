.General-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Stepper-container {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 40px;
  width: 565px;
}

.Circle-container {
  align-items: center;
  display: flex;
  height: 60px;
  width: 180px;
}

.Circle-container-center {
  width: 220px;
}

.Circle-status {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 40px;
}

.Circle-status > img {
  height: 40px;
  width: 40px;
}

.Circle-number {
  color: var(--base-pc-color-white);
  font-family: "Gotham-Medium";
  position: absolute;
}

.Circle-number[data-current=true] {
  color: var(--deep-blue-main);
}

.Circle-number[data-completed=true] {
  display: none;
}

.Line-status {
  background-color: var(--pc-color-cotton-500);
  height: 8px;
  width: 150px;
}

.Line-complete {
  background-color: #1ccfca;
  height: 4px;
  width: 150px;
}

.Line-Complete-Blue {
  background-color: rgba(1, 49, 61, 1);
  height: 8px;
  width: 150px;
}

.Step-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: flex-end;
  width: 50px;
}

.Status-container {
  display: flex;
  height: 20px;
  justify-content: space-between;
  width: 620px;
}

.Status-txt-container-blue {
  color: rgba(1, 49, 61, 1);
  font-size: 16px;
  font-weight: 350;
  line-height: 19.2px;
  font-family: 'Gotham-Medium';
  align-items: center;
  display: flex;
  margin-bottom: 14px;
  justify-content: center;
  width: 100px;
}

.Status-txt {
  font-family: 'Inter-Regular';
  font-size: 16px;
}

.Status-txt-open {
  margin-right: 13px;
}

.Status-txt-review {
  margin-left: 20px;
  width: 85px;
}

.Status-txt-done {
  margin-left: 35px;
}

.Container-txt-in-review {
  height: 50px;
  margin-right: 10px;
  width: 250px;
}
