.Pet-proflie-completed-container {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 88px;
  justify-content: center;
  width: 100%;
}

.Pet-proflie-completed-container > span {
  animation-delay: 0.3s !important;
  animation: zoomAnimation 0.4s ease-in;
  color: #22435e;
  font-family: "Inter-SemiBold";
  font-size: 20px;
  margin: 0 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes zoomAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.04);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
