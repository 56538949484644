.Impersonate-banner-main-container {
  align-items: center;
  background-color: var(--pc-color-yellow-300);
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 15px 20px;
}

.Impersonate-banner-main-container > h1 {
  font-family: "Gotham-medium";
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.Impersonate-banner-main-container > img {
  height: 24px;
  width: 24px;
}
