.DeductibleReimbursement-container {
  display: flex;
  margin-bottom: 24px;
  min-height: 60px;
  width: 71%;
}

.DeductibleReimbursement-container-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Deductible-legend-text {
  align-items: center;
  color: var(--deep-blue-main);
  font-family: 'Inter-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.Deductible-legend-text-icon {
  align-self: flex-start;
  margin-top: 4px;
  padding-right: 4px;
}

.Deductible-legend-text-icon + p {
  padding: 0;
  text-align: start;
}

.Deductible-legend-text > p {
  margin-bottom: 0px;
}

.Deductible-legend-text > a {
  color: #000;
  font-weight: 700;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .DeductibleReimbursement-container {
    width: 90%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .DeductibleReimbursement-container-div {
    width: 97%;
  }

  .Deductible-legend-text {
    padding: 0 16px;
  }
}
