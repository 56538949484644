.Search-input-wrapper {
  margin-top: 8px;
  position: relative;
  width: 100%;
}

.Search-input-container {
  align-items: center;
  background-color: #fff;
  border-color: #d9dee1;
  border-style: none none solid none;
  border-width: 1px;
  display: flex;
  width: 100%;
}

.Search-input-container > input {
  border-style: none;
  color: #3d484d;
  font-family: 'Inter-Medium';
  font-size: 17px;
  margin-left: 10px;
  outline-color: var(--pc-color-blue-300);
  pointer-events: all;
  position: relative;
  width: 100%;
  z-index: 1;
}

.Search-input-container
.Search-input-container > img {
  margin: 0 0 0 16px;
}

.Search-input-dropdown-container {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .08);
  display: flex;
  flex-direction: column;
  max-height: 294px;
  overflow-y: auto;
  padding-bottom: 4px;
  position: absolute;
  width: 100%;
}

.Search-input-dropdown {
  z-index: 0;
}

.Search-input-overlay {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

.Search-input-empty-message {
  margin: 10px 0 10px;
  text-align: center;
  z-index: 0;
}

.Search-input-list-item {
  background-color: transparent;
  border-color: transparent;
  border-style: solid none solid none;
  border-width: 1px;
  display: flex;
  margin: 8px 3px 8px 16px;
  padding: 9px 33px 9px 8px;
  text-align: left;
  width: calc(100% - 25px);
  z-index: 4;
}

.Search-input-list-item:hover {
  background-color: #b8ede840;
  border-color: #d9dee1;
  border-style: solid none solid none;
  border-width: 1px;
}
