.Insurance-account-row-container {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.Insurance-account-row-container > h4 {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
}

.Insurance-account-row-container > span {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Insurance-account-row-first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Insurance-account-row-first-row > img {
  max-height: 112px;
  max-width: 235px;
}

.Insurance-account-row-edit-button {
  align-items: flex-start;
  background-color: transparent;
  border-bottom: 1px solid var(--pc-color-blue-300);
  border-style: none none solid none;
  display: flex;
  gap: 8px;
  height: 24px;
  justify-content: flex-end;
  padding: 4px 0px;
}

.Insurance-account-row-edit-button > span {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}
