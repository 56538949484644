.Move-to-text {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-weight: 350;
  letter-spacing: -0.005em;
  line-height: 16px;
  padding-bottom: 8px;
}

.Move-action-dropdown {
  margin-top: 7px;
}
