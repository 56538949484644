.Other-policies-container {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  padding: 8px 48px 48px;
}

.Other-policies-container > h2 {
  color: var(--base-pc-color-black);
  font-family: "Gotham-Rounded-Bold";
  font-size: 28px;
  letter-spacing: -0.42px;
  line-height: 32px;
  margin-top: 24px;
}

.Other-policies-container > p {
  color: var(--base-pc-color-black);
  font-size: 16px;
  letter-spacing: 0.32px;
  margin-bottom: 24px;
}

.Other-policies-list {
  list-style-type: none;
  padding: 0;
}
