.Docs-folder-icon-row-container {
  display: flex;
}

.Docs-folder-icon-row {
  height: 30px;
  margin-right: 16px;
  pointer-events: none;
  width: 30px;
}

.Docs-folder-icon-label-row {
  align-items: center;
  color: #3d484d;
  cursor: pointer;
  display: flex;
  font-family: "Inter-Medium";
  font-size: 16px;
  overflow: hidden;
  width: 260px;
}

.Docs-folder-icon-label-row:hover {
  color: #3d484d;
  text-decoration: underline;
}

.Docs-folder-icon-label-row > span {
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Docs-file-row-flex {
  display: flex;
  flex-direction: row;
}

.Docs-file-icon-row-old {
  height: 32px;
  margin: 5px 12px 0 0;
  width: 27px;
}

.Docs-file-description-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 350px;
}

.Docs-file-description-col:hover {
  cursor: pointer;
  text-decoration: underline;
}

.Docs-file-description-col > a {
  color: #3d484d;
  font-family: "Inter-Medium";
  font-size: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 333px;
}

.Docs-file-description-col > span {
  color: #7e888c;
  font-family: "Inter-Medium";
  font-size: 10px;
}

.Docs-pet-assigned-image {
  border-color: #fff;
  border-radius: 100%;
  border-style: solid;
  border-width: 0.55px;
  height: 33px;
  width: 33px;
}

.Docs-pet-assigned-plus {
  height: 7px;
  margin: 1px !important;
  width: 7px;
}

.Docs-assigned-hidden {
  display: none;
}

.Docs-pet-assigned-name-initials {
  font-size: 11px !important;
  text-align: center;
  top: 7.08px !important;
  width: 100%;
}

.Docs-select-icon-checkbox-container {
  cursor: pointer;
}

.Docs-select-icon-checkbox-container > img {
  pointer-events: none;
}

@media (max-width: 1140px) {
  .Docs-folder-icon-row-container {
    max-width: 240px;
    width: 240px;
  }

  .Docs-assigned {
    margin-left: 30px;
    width: 100%;
  }

  .Docs-date-added {
    padding-left: 35px;
  }

  .Docs-folder-icon-label-row,
  .Docs-file-description-col {
    width: 76%;
  }

  .Docs-file-description-col > a {
    width: 220px;
  }

  .Docs-assigned-hidden,
  .Docs-dots-menu-hidden {
    display: none;
  }
}

/* re-design */
.Docs-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 311px;
}

.Docs-assigned {
  align-items: center;
  display: flex;
  padding-left: 13px;
  width: 193px;
}

.Docs-file-size {
  min-width: 170px;
  width: 170px;
}

.Docs-file-description-touchable-name {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.Docs-file-just-cont-cent {
  justify-content: center;
  margin-left: 4px;
  width: 266px;
}

.Docs-table-item-row {
  border-color: #eceef0;
  border-style: solid none none none;
  border-width: 1px;
  width: 100%;
}

.Docs-date-added {
  min-width: 193px;
  width: 193px;
}

.Docs-table-item-row:first-of-type {
  border-style: none;
}

.Docs-table-item-row-selected {
  background-color: rgba(184, 237, 232, 0.25);
  border-color: #eceef0;
  border-style: solid none none none;
  border-width: 1px;
  width: 100%;
}

.Docs-table-item-row-selected,
.Docs-table-item-row:hover {
  background-color: rgba(184, 237, 232, 0.25);
  background-color: var(--pc-color-blue-100);
}

.Docs-dots-menu {
  position: relative;
  width: 100%;
}

.Assign-pet-button-container {
  align-items: center;
  background-color: var(--pc-color-blue-100);
  border-color: var(--pc-color-blue-300);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-left: -13px;
  transition: all 0.3s ease;
  width: 77px;
}

.Assign-pet-button-container:hover {
  opacity: 0.6;
}

.Assign-pet-button-container > span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.28px;
  line-height: normal;
  margin: 0;
  pointer-events: none;
}

.Docs-dots-menu-hidden {
  display: none;
}

.Docs-file-icon-row {
  height: 32px;
  margin: 0 13px 0 0;
  width: 32px;
}
