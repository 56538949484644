.Pet-tag-screen-container {
  height: 100%;
  min-height: 100vh;
  padding: 32px 48px;
}

.Pet-tag-screen-header-container {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.Pet-tag-screen-page-title {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 32px;
  margin-bottom: 0;
  text-transform: capitalize;
  width: 100%;
}

.Pet-tag-screen-button-add-tag,
.Pet-tag-screen-button-edit-tag {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 4px 0px;
}

.Pet-tag-screen-button-add-tag span,
.Pet-tag-screen-button-edit-tag span {
  border-bottom: 1px solid var(--pc-color-blue-300);
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

.Pet-tag-screen-button-image {
  color: var(--pc-color-blue-300);
  height: 16px;
  margin: 0;
  width: 16px;
}

.Pet-tag-screen-card {
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  padding: 32px 48px;
  width: 100%;
}

.Pet-tag-screen-card-left-side {
  display: flex;
  gap: 48px;
}

.Pet-tag-screen-information {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Pet-tag-screen-information-item h4 {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Rounded';
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin: 0 0 8px 0;
}

.Pet-tag-screen-information-item span,
.Pet-tag-screen-information-item p {
  color: var(--pc-color-onyx-200);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.Pet-tag-emergency-contacts-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Pet-tag-screen-container {
    padding: 32px 24px;
  }

  .Pet-tag-screen-card-left-side {
    flex-direction: column;
    gap: 32px;
  }
}
