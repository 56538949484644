.My-pets-add-button-container {
  align-items: center;
  background-color: var(--pc-color-blue-300);
  border-radius: 75px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Gotham-Regular";
  font-weight: 350;
  height: 31px;
  margin: 0 4px 8px 0px;
  padding: 0 16px 0 16px;
  width: auto;
}

.My-pets-add-button-img-check {
  color: #fff !important;
  font-family: "Gotham-Regular";
  margin-right: 4px;
  width: 13px;
}

.My-pets-add-button-img-add {
  margin-right: 4px;
  width: 13px;
}

.My-pets-add-button-container span {
  color: #fff !important;
  font-family: "Gotham-Regular";
  font-size: 12px;
}

.My-pets-add-button-disabled {
  background-color: #05603a;
  pointer-events: none;
}

.My-pets-add-button-no-clicked{
  color: #fff !important;
}
