.First-figo-claim-modal-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
}

.First-figo-claim-modal-item > span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.28px;
  line-height: normal;
}

.First-figo-claim-modal-row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.First-figo-claim-modal-row > img {
  height: 24px;
  margin-right: 6px;
  width: 24px;
}

.First-figo-claim-modal-row > span {
  color: var(--base-pc-color-black);
  font-family: "Gotham-rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}
