.Powerup-title-container-optional {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Powerup-title-container-optional h3 {
  color: var(--deep-blue-main);
  font-family: "IvyEpic-Bold";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 219px;
}

.Powerup-title-container-optional h3 {
  font-size: 21px;
  max-width: 250px;
}

.Powerup-added {
  background-color: var(--blue-shade-dark);
}

.Powerup-switch {
  margin-left: 16px;
}

.Powerup-title {
  align-items: baseline;
  display: flex;
}

.Powerup-title h3 {
  margin-right: 16px;
}

.Powerup-title-container-optional > .Powerup-title {
  justify-content: space-between;
  width: 100%;
}

.Powerup-optional {
  font-family: "IvyPresto-Italic";
  font-size: 21px;
  font-weight: 400;
  margin-right: 8px;
}

/* Mobile */
@media (max-width: 568px) {
  .Powerup-title-container-optional {
    margin-bottom: 16px;
  }

  .Powerup-title[data-selected="true"] {
    flex-direction: column;
  }

  .Powerup-optional,
  .Powerup-title-container-optional h3 {
    font-size: 16px;
  }
}
