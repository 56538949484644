.All-policies-bank-disclaimer {
  color: #3d484d;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  width: 100%;
}

.Checkbox-button {
  align-items: center;
  background: transparent;
  border-radius: 8px;
  border: 0px;
  color: #fff;
  display: flex;
  height: 32px;
  justify-content: flex-start;
  margin-left: 10px;
  width: 100%;
}

.Reimbursement-disclaimer {
  margin: 0 26px 32px;
  width: 323px;
}

.Reimbursement-modal-card-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
  max-width: 417.41px;
  padding: 32px;
}

.Reimbursement-modal-title {
  color: #22435e;
  font-family: "Inter-SemiBold";
  font-size: 22px;
  line-height: 1.36;
  margin-bottom: 16px;
  text-align: center;
}

.Reimbursement-modal-bank-container {
  margin: 24px 32px 32px;
}

.Reimbursement-modal-label {
  color: #22435e;
}

.Reimbursement-modal-check-container {
  margin: 32px 64px;
}

.ReimbursementStep-modal-check-container {
  margin: 8px 64px 32px 64px;
}

.Reimbursement-tabs-container {
  display: flex;
  justify-content: center;
}

.Reimbursement-modal-tab-container {
  background-color: #fff;
  border-color: #2fb3b4;
  border-width: 1px;
  color: #2fb3b4;
  font-size: 14px;
  height: 30px;
  margin: 0;
  padding: 0;
  width: 140px;
}

.Reimbursement-bank-tab-container {
  border-radius: 4px 0 0 4px;
  border-style: solid none solid solid;
}

.Reimbursement-check-tab-container {
  border-radius: 0 4px 4px 0;
  border-style: solid solid solid none;
}

.Reimbursement-modal-active-tab {
  background-color: #2fb3b4;
  color: #fff;
}

.Reimbursement-modal-bank-row {
  display: flex;
}

.Reimbursement-modal-check-disclaimer-container {
  color: #475257;
  font-size: 14px;
  text-align: center;
  margin-top: 32px;
}

.Reimbursement-modal-check-disclaimer {
  margin: 0;
  padding: 0;
}

.Reimbursement-modal-buttons-container {
  display: flex;
  justify-content: center;
}

.Reimbursement-modal-check-addressOnFile-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Reimbursement-modal-check-title {
  font-family: "Inter-SemiBold";
  font-size: 16px;
  color: #22435e;
  margin-bottom: 24px;
}

.Reimbursement-modal-check-address-text {
  font-size: 14px;
  color: #202020;
  margin: 0;
}

.Reimbursement-method-input {
  border-radius: 5px;
  border: 1pt solid #d9dee1;
  color: #202020;
  font-family: 'Inter-Regular';
  height: 41px;
  margin-top: 8px;
  padding: 0 16px;
}

.Reimbursement-method-input::placeholder {
  color: #b0bbbf;
}

.Reimbursement-method-account-type {
  margin-right: 16px;
}

.Reimbursement-modal-label {
  display: flex;
  flex-direction: column;
  font-family: 'Inter-Medium';
  font-size: 14px;
}

.Reimbursement-method-account-routing-number {
  font-size: 13px;
  width: 100%;
}

.Reimbursement-modal-bank-disclaimer {
  color: #475257;
  font-size: 14px;
  text-align: center;
  margin: 16px 0 0;
}

.Edit-reimbursement-modal-button {
  border-radius: 8px;
  border-width: 0;
  height: 60px;
  width: 148px;
}

.Edit-reimbursement-modal-button-accept {
  background-color: #2fb3b4;
}

.Edit-reimbursement-modal-button-cancel {
  background-color: #eceef0;
  margin-right: 16px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}
