.Reimbursement-method-section-container {
  display: flex;
  flex-direction: column;
}

.Reimbursement-method-section-container > h1 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
  line-height: 44px;
  margin: 48px 0 4px 0;
}

.Reimbursement-method-section-container > p {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin: 0;
}

.Reimbursement-method-section-card-container {
  align-items: center;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  justify-content: space-between;
  margin: 16px 0 0 0;
  padding: 24px;
  position: relative;
  width: 1084px;
}

.Reimbursement-method-section-card-container {
  margin: 28px 0 16px 0;
}

.Reimbursement-method-card-left-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 14px;
  justify-content: center;
}

.Reimbursement-method-card-left-content > p {
  margin: 0;
}

.Reimbursement-method-add-button {
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--pc-color-blue-300);
  border-style: none none solid none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  margin-top: 24px;
  padding: 4px 0px;
  width: 263px;
}

.Reimbursement-method-add-button > img {
  height: 16px;
  width: 16px;
}

.Reimbursement-method-add-button > span {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Reimbursement-method-section-card-container {
    width: 720px;
  }

  .Reimbursement-method-section-card-container {
    margin: 24px 0 16px 0;
  }
}
