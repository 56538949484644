.Pet-cloud-account-container {
  background-color: var(--pc-color-cotton-300);
  display: flex;
  flex-direction: column;
  margin: 18px 48px;
}


.Pet-cloud-account-title {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded-Bold';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.54px;
  width: 100%;
}

.Pet-cloud-account-description {
  color: var(--base-pc-color-black);
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  width: 67%;
}

.Pet-cloud-account-info-container {
  align-items: flex-start;
  background-color: var(--base-pc-color-white);
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 32px 48px 48px;
  width: 1084px;
}

.Pet-cloud-account-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Pet-cloud-account-container {
    display: flex;
    flex-wrap: wrap;
    margin: 18px 24px 18px 32px;
  }

  .Pet-cloud-account-info-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 32px 48px 48px;
    width: 720px;
  }
}
