.Selector-pill-wrapper {
  background: #fff;
  border-radius: 8px;
  border: 1px solid var(--deep-blue-main);
  color: var(--deep-blue-main);
  cursor: pointer;
  font-family: 'IvyEpic';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 11.5px 18px;
  position: relative;
}

.Selector-pill-wrapper:hover {
  box-shadow: 0 0 2px 2px #b8ede8;
}
 
.Selector-pill-wrapper[data-checked=true] {
  background-color: var(--blue-shade-3);
  font-family: 'IvyEpic-Bold';
}

.Selector-pill-wrapper[data-disabled=true] {
  background-color: #d9dee1;
  color: var(--deep-blue-shade-2);
}

.Selector-pill-button {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Selector-pill-button-icon {
  height: 14px;
  margin-bottom: 3px;
  padding-right: 10px;
}