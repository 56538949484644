.Finish-button-a {
  background-color: var(--pc-color-blue-300);
  border-radius: 20px;
  border: 1px solid var(--pc-color-blue-300);
  color: var(--base-pc-color-white);
  font-family: 'Gotham-medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  padding: 12px 16px;
  text-align: center;
  width: 180px;
}

.Finish-button-a:focus {
  outline: none;
}

.Finish-button-a:hover {
  opacity: 0.8;
  outline: none;
}
