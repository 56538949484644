body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus {
  border-color: #b8ede8 !important;
  box-shadow: 0 0 2px 2px #b8ede8 !important;
}

.Gone {
  cursor: none !important;
  display: none !important;
}

.Hide {
  opacity: 0;
}

textarea::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eceef0;
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
  background: #2fb3b4;
  border-radius: 4px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background: #b8ede8;
}

.No-clicked {
  pointer-events: none;
}

.Bold { 
  font-family: 'Inter-Bold';
}

.Bold-gotham {
  font-family: 'Gotham-medium';
}

.Like-span {
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: none;
}

.Only-Mobile {
  display: none !important;
}

.Col {
  display: flex;
  flex-direction: column;
}

.Row {
  display: flex;
  flex-direction: row;
}

.Hidden {
  display: none;
}

.Margin-top-12 {
  margin-top: 12px;
}

.Margin-bottom-8 {
  margin-bottom: 8px;
}

@media (min-width: 1201px) {
  .No-desktop {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .No-tablet {
    display: none !important;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Only-Mobile {
    display: inherit !important;
  }
}
