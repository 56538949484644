.Found-lost-pet-form-input-container-with-error {
  display: flex;
  flex-direction: column;
  min-height: 68px;
  width: 100%;
}

.Found-lost-pet-form-input-container-with-error > span {
  color: #dd3030;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

.Found-lost-pet-form-input {
  background: #fcf2e8;
  border-radius: 8px;
  border: 1px solid #000;
  color: #000;
}

.Found-lost-pet-form-input-multiline {
  background: #fcf2e8;
  border-radius: 8px;
  border: 1px solid #000;
  color: #000;
  height: 81px;
  min-height: 81px;
  padding: 12px 0 0 16px;
  resize: none;
}

.Found-lost-pet-form-input-multiline::placeholder {
  line-height: normal;
}

.Found-lost-pet-form-input-footer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.Found-lost-pet-form-input-error {
  color: #dd3030;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

.Found-lost-pet-form-input-counter {
  font-family: "Arial";
  font-size: 12px;
  font-weight: 400;
  line-height: 13.8px;
}

.Found-lost-pet-form-input-invalid-field {
  border-color: #dd3030;
}

.Found-lost-pet-form-input-invalid-field::placeholder {
  color: #dd3030;
}
