.PowerUp-Switch-container {
  --switch-background: #20c2c9;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: auto;
}

.PowerUp-Switch-navy {
  --switch-background: var(--navy);
}

.PowerUp-Switch-container-active {
  background-color: var(--switch-background);
  transition: all 0.2s ease-out;
}

.PowerUp-Switch-ball {
  background-color: #fff;
  border-radius: 100%;
  bottom: 0px;
  right: 0;
  box-shadow: 0 1px 2px 0px #00000080;
  height: 20.11px !important;
  position: relative;
  width: 20.11px !important;
}

.PowerUp-Switch-button-container {
  align-items: center;
  background: #737a83;
  border-radius: 57.14px;
  border-style: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 24px !important;
  outline: none !important;
  width: 44px;
}

.PowerUp-Switch-container-inactive {
  background-color: #d9dee1;
  transition: all 0.2s ease-out;
}

.PowerUp-Switch-label {
  color: #3d484d;
  font-family: "Inter-Medium";
  font-size: 14px;
  margin-left: 13.15px;
}

.PowerUp-Switch-position-active {
  animation: moveToRight 0.1s alternate;
  left: 16px;
}

.PowerUp-Switch-position-inactive {
  animation: moveToLeft 0.1s alternate;
  right: 4px;
}

@keyframes moveToLeft {
  0% { right: -22px; }
  100% { right: 6px; }
}

@keyframes moveToRight {
  0% { left: -6px; }
  100% { left: 21px; }
}
