.Input-capsule-input {
  border-radius: 104px;
  border: 1px solid #aaa;
  height: 40px;
  width: 200px;
}

.Input-capsule-input::placeholder {
  color: #000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

.Input-capsule-input-container-with-error {
  display: flex;
  flex-direction: column;
}

.Input-capsule-input-container-with-error > span {
  color: #dd3030;
  font-size: 12px;
  font-weight: 400;
  margin-left: 12px;
}
