.Dot-menu-container {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eceef0;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.05);
  margin-top: 4px;
  padding: 8px;
  position: absolute;
  right: 0;
  transition: all .2s ease;
  width: 140px;
  z-index: 1;
}

.Dot-menu-container-redesign {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eceef0;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  left: 0;
  margin-top: 4px;
  padding: 8px;
  position: absolute;
  transition: all 0.2s ease;
  width: 140px;
  z-index: 1;
}


.Dot-menu-display-upwards {
  bottom: 50px;
}

.Dot-menu-container ul {
  margin: 0;
  padding: 0;
}

.Dot-menu-container-hidden {
  margin-top: -14px;
  opacity: 0;
  pointer-events: none;
}

.Dot-menu-dots-image {
  height: 5px;
  pointer-events: none;
  width: 23px;
}

.Dot-menu-item-container-disabled {
  align-items: center;
  border-radius: 7px;
  height: 36px;
  justify-content: flex-start;
  padding: 8px 5px 8px 8px;
  width: 100%;
}

.Dot-menu-item-container-disabled img {
  height: 20px;
  margin-right: 8px;
  opacity: 0.4;
  width: 20px;
}

.Dot-menu-item-container-disabled span {
  color: #b0bbbf;
  font-family: "Inter-Medium";
  font-size: 12px;
}

.Dot-menu-overlay-container {
  z-index: -1 !important;
}

/* re-design */
.Dot-menu-button {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 41px;
  justify-content: center;
  width: 41px;
}

.Dot-menu-button:hover,
.Dot-menu-button-active {
  background-color: var(--pc-color-blue-400);
  opacity: 0.1;
}

.Dot-menu-item-container {
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  justify-content: flex-start;
  padding: 10px 16px;
  width: 100%;
}

.Dot-menu-item-container:hover {
  background-color: var(--pc-color-blue-100);
}

.Dot-menu-item-container img {
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.Dot-menu-item-container span {
  color: var(--charcoal-500);
  font-family: "Gotham-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Dot-menu-folder-content {
  left: unset;
  right: 35px;
  width: 250px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Dot-menu-container-redesign {
    left: unset;
    right: 0px;
    width: 190px;
  }
}
