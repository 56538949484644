.Radio-input-container {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.Radio-input-label-container {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  color: var(--pc-color-onyx-500);
  cursor: pointer;
  display: block;
  font-family: 'Gotham-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  pointer-events: none;
  position: relative;
  user-select: none;
  width: 100%;
}

.Radio-input-with-label {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  opacity: 0;
  position: absolute;
  vertical-align: middle;
  width: 107px;
}

.Radio-input-with-label + .Radio-input-label-container:before {
  background-color: var(--base-pc-color-white);
  border-radius: 50%;
  border: 2px solid var(--pc-color-onyx-400);
  content: '';
  display: inline-block;
  height: 24px;
  margin-right: 8px;
  padding: 2px;
  text-align: center;
  vertical-align: middle;
  width: 24px;
}

.Radio-input-with-label:checked + .Radio-input-label-container:before {
  background-color: var(--pc-color-blue-300);
  border: 2px solid var(--pc-color-blue-300);
  box-shadow: inset 0px 0px 0px 3px var(--base-pc-color-white);
}

.Radio-input-with-label:focus + .Radio-input-label-container {
  outline: 1px solid var(--pc-color-blue-300);
}
