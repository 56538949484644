.Contact-information-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  max-width: 344px;
}

.Contact-information-left-text {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
}

.Contact-information-right-text {
  color: var(--pc-color-onyx-500);
  font-family: "Gotham-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right;
}

.Contact-information-link {
  border-bottom: 1px solid var(--pc-color-blue-300);
  color: var(--pc-color-blue-300);
  font-family: "Gotham-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.32px;
  line-height: 16px;
  text-align: center;
}
