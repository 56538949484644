.Direct-deposit-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Direct-deposit-content-container > h6 {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: -0.07px;
  line-height: 16px;
  margin-bottom: 16px;
}

.Direct-deposit-radio-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 32px;
}

.Direct-deposit-input-margin {
  margin-bottom: 32px;
}

.Direct-deposit-input-margin-last {
  margin-bottom: 8px;
}
